.time-grid-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;

  .time-grid-item {
	box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.16);
	border-radius: 6px;
	background-color: $color-white;
	padding: 11px 22px;
	color: $color-gray-dark;
	font-size: 18px;
	line-height: 1;
	transition: all .3s ease;
	width: calc(20% - 10px);
	margin-left: 10px;
	margin-bottom: 10px;

	&[disabled] {
	  opacity: .3;
	  cursor: not-allowed;
	}

	&.is-blocked {
	  background-color: $color-gray-dark;
	  color: $color-white;
	}

	&.is-appointment {
	  background-color: $color-orange;
	  color: $color-white;
	}

	@media (max-width: 767px) {
	  width: calc(25% - 10px);
	}

	@media (max-width: 575px) {
	  width: calc(33.33333% - 10px);
	  padding: 11px 5px;
	}
  }

  &:not(.edit) {
	.time-grid-item:hover:not([disabled]) {
	  background-color: $color-orange;
	  color: $color-white;
	}
  }

  &.edit {
	.time-grid-item:hover {
	  box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.23);
	}
  }
}
