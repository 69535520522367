.doctor-month-calendar {
  &-head {
    display: flex;

    &-item {
      color: $color-gray-dark;
      font-size: 18px;
      line-height: 1;
      width: 14.29%;
      text-align: center;
      padding: 20px 0;

      @media (max-width: 1439px) {
        font-size: 14px;
        padding: 15px 0;
      }
    }
  }

  &-body {
    display: flex;
    flex-wrap: wrap;
  }

  &-day {
    width: 14.36%;
    margin-left: -1px;
    margin-top: -1px;
    height: 100px;
    border: 1px solid #dedede;
    background-color: $color-white;
    cursor: pointer;
    position: relative;
    transition: all .3s ease;

    &:hover {
      background-color: #f1f1f1;
    }

    &.current {
      background-color: #ffebe1;
    }

    &.disabled {
      cursor: not-allowed;

      > * {
        opacity: 0.5;
      }
    }

    &-number {
      color: $color-gray-dark;
      font-size: 24px;
      line-height: 1;
      position: absolute;
      right: 15px;
      top: 15px;

      @media (max-width: 1649px) {
        font-size: 20px;
      }
    }

    &-type {
      position: absolute;
      left: 7px;
      top: 15px;

      .if {
        font-size: 20px;
        line-height: 1;
        display: block;
        color: $color-orange;

        @media (max-width: 1649px) {
          font-size: 14px;
        }
      }
    }

    &-legend {
      position: absolute;
      left: 10px;
      bottom: 10px;
      display: flex;
      align-items: center;

      @media (max-width: 1649px) {
        left: 5px;
        bottom: 5px;
      }

      .legend-icon {
        @media (max-width: 1649px) {
          font-size: 12px;
          padding: 2px 3px;
          margin-right: 2.5px;
        }
      }
    }
  }
}
