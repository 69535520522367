.clinic-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;

  .clinic-item {
    width: calc(33.33333% - 10px);
    border: 1px solid #ced4da;
    padding: 30px 30px 68px;
    background-color: #e9ecef;
    margin-bottom: 10px;
    margin-left: 10px;
    text-align: center;
    position: relative;
    border-radius: 5px;

    .name {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .phone {
      margin-bottom: 25px;
    }

    .btn {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
    }

    &.add-clinic {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      min-height: 186px;
      padding: 30px;
      cursor: pointer;
      opacity: .6;
      transition: all .3s ease;

      &:hover {
        opacity: 1;
      }

      .add-icon {
        position: relative;
        width: 100%;
        height: 30px;
        margin-bottom: 20px;

        &:after,
        &:before {
          content: "";
          display: block;
          background-color: #727272;
          position: absolute;
          top: 50%;
          left: 50%;
        }

        &:after {
          width: 8px;
          height: 30px;
          margin-left: -4px;
          margin-top: -15px;
        }

        &:before {
          width: 30px;
          height: 8px;
          margin-left: -15px;
          margin-top: -4px;
        }
      }
    }
  }
}
