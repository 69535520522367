.dialog-wrapper {
  position: fixed;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.67);
  width: 100%;
  min-height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  hr {
    margin: 30px 0;
  }

  @media (max-width: 767px) {
    padding: 10px;
  }
}

.mat-dialog-container {
  max-width: 400px;
  width: 100%;
  border-radius: 10px !important;
  background-color: #ffffff;
  padding: 60px 40px !important;
  text-align: center;

  .change-photo-dialog &,
  .time-grid-clinic-dialog & {
    width: 600px;
    max-width: 600px;
  }

  .specialist-add-dialog & {
    width: 780px;
    max-width: 100%;
  }

  .record-clinic-dialog & {
    width: 780px;
    max-width: 100%;
  }

  .doctor-appointment-dialog & {
    width: 100%;
    max-width: 490px;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .btn {
    margin-top: 30px;

    @media (max-width: 767px) {
      margin-top: 0;
    }
  }

  @media (max-width: 767px) {
    padding: 30px 15px !important;
  }
}

.dialog-icon {
  text-align: center;
  margin-bottom: 15px;

  .if {
    line-height: 1;
    font-size: 80px;

    @media (max-width: 767px) {
      font-size: 60px;
    }
  }
}

.dialog-title {
  color: $color-gray-dark;
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 50px;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
}

.dialog-buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-left: -40px;

  button {
    margin-left: 40px;

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
}

.mat-typography {
  .load-local-spinner {
    position: static;
    transform: none;
    margin: 40px auto 70px;
  }
}

.mat-dialog-appointment {
  padding-bottom: 20px !important;
}

@media (max-width: 767px) {
  .mat-dialog-content {
    padding: 0 15px 20px !important;
    margin: 0 -15px !important;
  }

  .mat-dialog-actions {
    margin-bottom: -15px !important;
  }
}
