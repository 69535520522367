.account-sidebar {
  width: 270px;
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 1px 1px 7px 1px rgba(58, 68, 86, 0.3);
  background-color: #ffffff;

  .account-avatar {
    padding: 40px 50px;
  }

  .show-mobile {
    display: none;
  }

  & + .main-container {
    width: calc(100% - 300px);
  }

  @media (max-width: 991px) {
    width: 100%;

    .show-mobile {
      display: block;
      margin: 0 auto 30px;
    }

    & + .main-container {
      width: 100%;
    }
  }
}

.account-sidebar-navigation {
  li {
    border-top: 1px solid #d8d8d8;

    &:last-child a {
      border-radius: 0 0 10px 10px;
    }
  }

  a {
    display: flex;
    align-items: center;
    padding: 12px 23px;
    color: $color-gray-dark;
    transition: all .3s ease;

    &.active {
      background-color: #f1f1f1;
    }

    .if {
      font-size: 20px;
      margin-right: 22px;
      color: $color-orange;
      line-height: 1;
      display: block;
      width: 21px;
      text-align: center;
    }

    .text {
      font-size: 14px;
      line-height: 1;
    }

    &:hover {
      text-decoration: none;
      color: $color-gray-dark;
      background-color: #f1f1f1;
    }
  }

  @media (max-width: 991px) {
    overflow: hidden;
    max-height: 0;
    transition: max-height .5s cubic-bezier(0, 1, 0, 1);

    &.show {
      max-height: 10000px;
      transition: max-height 1s ease-in-out;
    }
  }
}
