.specialist-card {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  background-color: $color-white;
  margin-bottom: 30px;
  padding: 20px;
  display: flex;
  transition: box-shadow .3s ease;

  &:last-child {
	margin-bottom: 0;
  }

  &:hover {
	box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.2);
  }

  .image {
	width: 100px;
	height: 100px;
	border: 1px solid $color-orange;
	border-radius: 50%;
	overflow: hidden;
	flex: 0 0 100px;

	img {
	  width: 100%;
	  height: 100%;
	  object-fit: cover;
	}
  }

  .left {
	margin-left: 25px;
	width: calc(50% - 75px);
	max-width: 400px;
  }

  .right {
	width: calc(50% - 50px);

	h5 {
	  margin-bottom: 12px;
	  line-height: 14px;
	}
  }

  .address,
  .time {
	margin-bottom: 10px;
	display: flex;

	.if {
	  font-size: 14px;
	  line-height: 1;
	  color: $color-orange;
	  margin-right: 10px;
	}
  }

  .address,
  .time,
  .price {
	color: $color-gray-dark;
	font-family: $gothamPro;
	font-size: 12px;
	line-height: 14px;
  }

  .price {
	margin-bottom: 22px;
  }

  .name {
	color: $color-gray-dark;
	font-family: $gothamPro;
	font-size: 20px;
	line-height: 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 10px;
  }

  .specialization {
	color: $color-gray-dark;
	font-family: $gothamPro;
	font-size: 16px;
	line-height: 1;
	margin-bottom: 10px;
	display: flex;
	align-items: center;

	.more-popup {
	  margin-left: 7px;
	}
  }

  .animals {
	color: $color-gray-dark;
	font-family: $gothamPro;
	font-size: 12px;
	line-height: 1;
	margin-bottom: 10px;
	display: flex;
	align-items: center;

	.more-popup {
	  margin-left: 7px;
	}
  }

  .experience {
	color: $color-gray-dark;
	font-family: $gothamPro;
	font-size: 12px;
	line-height: 1;
	margin-bottom: 10px;

	span {
	  font-weight: 500;
	}
  }

  .reputation {
	display: flex;
	align-items: center;

	.rating-stars {
	  margin-right: 15px;
	}
  }

  @media (max-width: 1440px) {
	.filters-result & {
	  flex-wrap: wrap;

	  .left,
	  .right {
		width: calc(100% - 125px);
	  }

	  .right {
		margin-left: 125px;
		margin-top: 30px;
	  }
	}
  }

  @media (max-width: 1200px) {
	flex-wrap: wrap;

	.left,
	.right {
	  width: calc(100% - 125px);
	}

	.right {
	  margin-left: 125px;
	  margin-top: 30px;
	}
  }

  @media (max-width: 574px) {
	&,
	.filters-result & {
	  display: block;

	  .image {
		margin: 0 auto 30px;
	  }

	  .left,
	  .right {
		width: 100%;
		margin-left: 0;
	  }
	}
  }
}
