.add-photos-multiply-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;

  >div {
    display: flex;
    flex-wrap: wrap;
  }

  .photo,
  .photo-add {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 1px rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    margin-bottom: 20px;
    margin-left: 20px;
    border: none;
  }

  .photo {
    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover;
    }

    .remove-photo {
      position: absolute;
      width: 31px;
      height: 31px;
      box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 1px rgba(0, 0, 0, 0.16);
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      border-radius: 50%;
      right: -10px;
      top: -10px;
      cursor: pointer;
      transition: all .3s ease;

      .if {
        font-size: 17px;
        line-height: 1;
      }

      &:hover {
        background-color: $color-orange;
        color: white;
      }
    }
  }

  .photo-add {
    cursor: pointer;
    transition: all .3s ease;
    padding: 0;

    .if {
      font-size: 40px;
      line-height: 1;
      color: $color-orange;
    }

    &:hover {
      box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.3);
    }
  }
}
