.account-email-verification {
  border-radius: 8px;
  background-color: #f1f1f1;
  padding: 14px 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  .text {
    font-size: 16px;
    line-height: 1;
    margin: 5px 0;
  }
}
