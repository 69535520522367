.create-appointment-price-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  .left,
  .right {
    color: $color-gray-dark;
    font-size: 16px;

    span {
      font-weight: 500;
    }
  }
}
