.btn {
  display: inline-block;
  transition: all .3s ease;
  border: none;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  padding: 13px 45px;
  font-size: 16px;
  line-height: 1;
  font-weight: 300;
  border-radius: 6px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  text-align: center;

  .if {
    font-size: 14px;
    margin-right: 20px;
    line-height: 1;
  }

  &.icon-right .if {
    margin-right: 0;
    margin-left: 20px;
  }

  &[disabled] {
    opacity: .4;
    cursor: not-allowed;
  }

  &:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  }

  &.btn-small {
    font-size: 14px;
    padding: 9px 35px;

    .if {
      font-size: 12px;
    }
  }

  &.btn-large {
    font-size: 18px;
    padding: 17px 46px;

    .if {
      font-size: 16px;
    }
  }

  &.btn-primary {
    background-image: linear-gradient(25deg, $color-coral, $color-orange);
    color: #ffffff;
  }

  &.btn-default {
    background-color: $color-gray-dark;
    color: #ffffff;
  }
}

.button-transparent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $color-orange;
  font-size: 14px;
  line-height: 1;

  .if {
    font-size: 14px;
    margin-right: 10px;
  }

  &.btn-large {
    font-size: 16px;
  }
}

.see-more {
  font-size: 12px;
  line-height: 1;
  transition: all .3s ease;
  display: block;
  color: $color-gray-dark;

  .if {
    font-size: 7px;
    line-height: 1;
    margin-left: 14px;
  }

  &:hover {
    color: $color-orange;
    text-decoration: none;
  }
}
