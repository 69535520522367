.form-group-array.with-checkboxes {
  .item {
    flex-wrap: wrap;
    position: relative;
    border-top: 1px solid #ced4da;
    padding-top: 30px;

    &:first-child {
      border: none;
      padding-top: 0;
    }
  }

  .ng-select {
    margin-bottom: 20px;
  }

  .clinic-actions-checkboxes {
    width: calc(100% - 50px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    label {
      padding: 0;
      background: none;
      line-height: 1;
      height: auto;
      white-space: normal;
      overflow: visible;
      width: 33.3333333%;
      margin-bottom: 20px;
      font-weight: 400;

      .custom-checkbox {
        margin-top: -3px;
      }
    }
  }

  .remove-field {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.form-group-array {
  .form-group {
    margin-bottom: 0;
  }
}
