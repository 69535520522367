.card-pet {
  border-radius: 10px;
  box-shadow: 1px 1px 7px 1px rgba(58, 68, 86, 0.3);
  background-color: #ffffff;
  position: relative;
  height: 100%;
  cursor: pointer;
  transition: all .5s ease;

  &.is-dead {
	opacity: .5;
  }

  .image {
	border-radius: 10px 10px 0 0;
	overflow: hidden;

	img {
	  width: 100%;
	}
  }

  .caption {
	padding: 20px 25px;

	.line {
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
	  margin-bottom: 18px;

	  &:last-child {
		margin-bottom: 0;
	  }
	}
  }

  .pet-name {
	font-size: 16px;
	font-weight: 500;
	line-height: 1;
  }

  .pet-age,
  .pet-status,
  .pet-status-value {
	font-size: 14px;
	line-height: 1;
  }

  .pet-status-value {
	opacity: .7;
  }

  &:hover {
	box-shadow: 0 0 30px 1px rgba(58, 68, 86, 0.3);
  }
}
