.dots-link {
  color: $color-orange;
  font-family: $gothamPro;
  font-size: 16px;
  line-height: 18px;
  display: inline-block;
  border-bottom: 1px dashed $color-orange;
  cursor: pointer;
  transition: all .3s ease;

  &.small {
	font-size: 12px;
	line-height: 14px;
  }

  &:hover {
	color: $color-coral;
	border-bottom-color: transparent;
  }
}
