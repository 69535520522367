.app-sidebar {
  width: 345px;
  height: 100%;
  box-shadow: 0 1px 15px 1px rgba(78, 69, 65, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(14deg, #ff4444, #ea6728 56%, #ea6728);
  z-index: 90;
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 108px;
  transition: width .3s ease;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;

  &.sidebar-collapsed {
    width: 80px;
  }

  .close-mobile {
    display: none;
  }

  @media (max-width: 991px) {
    z-index: 220;
    width: 280px;
    padding-top: 50px;
    overflow: auto;
    left: -280px;
    transition: all .3s ease;

    &.show {
      left: 0;
    }

    .close-mobile {
      display: flex;
      position: absolute;
      left: 25px;
      top: 15px;
      background-color: white;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      align-items: center;
      justify-content: center;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
      border: 1px solid white;

      .if {
        font-size: 15px;
        line-height: 1;
        color: $color-orange;
      }
    }
  }
}

.sidebar-navigation {
  width: 100%;

  li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  button {
    display: flex;
    align-items: center;
    padding: 20px 30px;
    position: relative;
    width: 100%;

    .sidebar-collapsed & {
      padding: 20px 0;
      justify-content: center;
    }

    &:before {
      content: '';
      box-shadow: inset 0 5px 5px 0 rgba(0, 0, 0, 0.15);
      background-color: rgba(0, 0, 0, 0.1);
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0;
      transition: all .5s ease;
    }

    .if {
      font-size: 20px;
      line-height: 1;
      margin-right: 20px;
      width: 20px;
      text-align: center;
      flex: 0 0 20px;
      color: white;

      .sidebar-collapsed & {
        margin: 0;
      }
    }

    .text {
      font-size: 18px;
      color: white;
      line-height: 1;
      font-weight: 300;
      transition: all .3s ease;
      overflow: hidden;
      text-align: left;

      .sidebar-collapsed & {
        display: none;
      }
    }

    &:hover {
      color: white;
      text-decoration: none;

      &:before {
        opacity: 1;
      }
    }

    &.active:before {
      opacity: 1;
    }
  }
}

.sidebar-socials {
  position: static;
  //padding-left: 37px;
  padding: 40px 0 40px 37px;
  align-self: flex-end;

  .sidebar-collapsed & {
    left: 25px;
    padding-left: 25px;
  }

  .title {
    font-size: 16px;
    color: #ffffff;
    line-height: 1;
    margin-bottom: 16px;

    .sidebar-collapsed & {
      display: none;
    }
  }

  .socials-list {
    display: flex;
    align-items: center;

    .sidebar-collapsed & {
      display: block;
    }

    a {
      width: 30px;
      height: 30px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
      background-color: white;
      border: 1px solid white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 13px;
      transition: all .5s ease;

      &:first-child {
        margin-left: 0;
      }

      .sidebar-collapsed & {
        margin-left: 0;
        margin-bottom: 13px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .if {
        font-size: 14px;
        color: $color-coral;
        line-height: 1;
        transition: all .5s ease;

        &.if-vkontakte {
          font-size: 10px;
        }
      }

      &:hover {
        text-decoration: none;
        background-color: transparent;
        box-shadow: none;

        .if {
          color: white;
        }
      }
    }
  }
}
