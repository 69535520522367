.change-photo-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 40px;

  @media (max-width: 767px) {
    justify-content: center;
    padding-bottom: 10px;
  }
}

.preview-photo {
  height: 142px;
  width: 142px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.16), 0 4px 15px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .new-photo {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
}

.upload-photo-wrapper {
  width: 100%;
  max-width: 330px;
  height: 110px;
  border-radius: 8px;
  box-shadow: inset 2px 2px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: #f1f1f1;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #404040;
  font-weight: 300;
  margin-left: 35px;
  cursor: pointer;
  position: relative;

  @media (max-width: 767px) {
    max-width: none;
    margin: 0;
  }

  >div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;

    &.file-info {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;

      .if {
        font-size: 20px;
        color: black;
      }
    }
  }
}

.cropper-wrapper {
  width: 100%;

  .cropper-cropping {
    border: none !important;
  }

  canvas {
    width: 100%;
    margin-top: 40px;
  }
}
