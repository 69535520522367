.time-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;

  @media (max-width: 1350px) {
    flex-wrap: wrap;
  }

  > * {
    margin-bottom: 30px;
  }

  .toggle-date {
    display: flex;

    button {
      height: 35px;
      border: solid 1px #dfdfdf;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      font-size: 14px;
      line-height: 1;
      color: #404040;
      transition: all .3s ease;

      .if {
        font-size: 18px;
        line-height: 1;
        color: #484848;
        transition: all .3s ease;
      }

      &.prev-date {
        border-radius: 6px 0 0 6px;
      }

      &.today {
        margin-left: -1px;

        &.disabled {
          opacity: .5;
          cursor: not-allowed;
        }
      }

      &.next-date {
        border-radius: 0 6px 6px 0;
        margin-left: -1px;
      }

      &:hover:not(.disabled) {
        background-color: $color-orange;
        color: white;
        border-color: $color-orange;

        .if {
          color: white;
        }
      }
    }
  }

  .text-date {
    font-size: 24px;
    text-align: center;
    color: #404040;
    line-height: 1;
  }

  .toggle-long-time {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    button {
      height: 35px;
      border: solid 1px #dfdfdf;
      background-color: #ffffff;
      font-size: 14px;
      color: #404040;
      line-height: 35px;
      text-align: center;
      padding: 0 16px;
      margin-left: -1px;
      //transition: all .3s ease;

      &:first-child {
        border-radius: 6px 0 0 6px;
        margin-left: 0;
      }

      &:last-child {
        border-radius: 0 6px 6px 0;
      }

      &.active,
      &:hover {
        background-image: linear-gradient(17deg, #fc4a4a, #ea6728);
        color: white;
        background-size: cover;
        background-position: -100%;
      }
    }
  }
}

.schedule-record {
  border-top: 2px solid $color-orange;
  padding-top: 20px;
  overflow-x: auto;
  width: 100%;

  .schedule-head {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #000000;

    .item {
      flex: 0 0 90px;
      width: 90px;
      padding: 0 0 10px 20px;
      border-bottom: 1px solid #d8d8d8;

      &:first-child {
        width: 300px;
        flex: 0 0 300px;
        padding: 15px 0 16px 10px;

        @media (max-width: 574px) {
          width: 230px;
          flex: 0 0 230px;
        }
      }
    }

    span {
      display: block;
      color: $color-orange;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
    }
  }

  .schedule-row {
    display: flex;
    align-items: center;

    .item {
      padding: 12px 0 12px 20px;
      flex: 0 0 90px;
      border-bottom: 1px solid #d8d8d8;

      &:first-child {
        padding-left: 0;
        flex: 0 0 300px;

        @media (max-width: 574px) {
          flex: 0 0 230px;
        }
      }

      &:last-child {
        padding-right: 10px;
      }
    }
  }

  .record-item {
    width: 70px;
    height: 50px;
    border-radius: 6px;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease;

    &:hover:not([disabled]) {
      background-color: $color-orange;
      color: white;
    }

    &[disabled] {
      cursor: not-allowed;
      opacity: .3;
    }
  }

  .specialist-block {
    width: 300px;

    @media (max-width: 574px) {
      width: 230px;
    }
  }
}

.record-info-time {
  margin-bottom: 20px;

  .date {
    font-weight: 500;
    font-size: 18px;
    color: #404040;
    text-align: center;
    margin-bottom: 10px;
  }
  .time {
    opacity: 0.5;
    font-size: 18px;
    color: #000000;
    text-align: center;
  }
}

.record-info-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > div {
    position: relative;
    padding: 20px 0;
    width: 100%;
    border-bottom: 1px solid #d8d8d8;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-right: 0;
      border-bottom: none;

      &:after {
        content: none;
      }
    }

    &:after {
      content: '';
      display: block;
      width: 1px;
      height: 60px;
      background-color: #d8d8d8;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -30px;
    }

    @media (max-width: 991px) {
      padding: 0;
      margin-left: 30px;
      margin-bottom: 30px;

      &:after {
        content: none;
      }
    }
  }

  @media (max-width: 991px) {
    flex-wrap: wrap;
    margin-left: -30px;
  }
}

.edit-appointment-info-list {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  > div {
    position: relative;
    padding: 0 30px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    @media (max-width: 991px) {
      padding: 0;
      margin-left: 30px;
      margin-bottom: 30px;
    }
  }

  @media (max-width: 991px) {
    margin-bottom: 0;
    margin-left: -30px;
    flex-wrap: wrap;
  }
}

.appointment-small-price-info {
  .item {
    color: $color-gray-dark;
    font-size: 14px;
    line-height: 1;
    margin-bottom: 8px;
    text-align: left;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      color: $color-orange;
    }
  }
}

.edit-appointment-controls {
  display: flex;
  align-items: center;

  .btn {
    margin-top: 0;
  }

  .mat-checkbox-layout {
    margin-bottom: 0;
  }

  >* {
    margin-left: 30px;

    &:first-child {
      margin-left: 0;
    }

    @media (max-width: 991px) {
      margin-bottom: 30px;

      &:first-child {
        margin-left: 30px;
      }
    }
  }

  @media (max-width: 991px) {
    flex-wrap: wrap;
    margin-left: -30px;

    & + hr {
      margin-top: 0;
    }
  }
}

.time-small-info {
  .mat-dialog-container & {
    text-align: left;
  }

  .date {
    font-size: 16px;
    line-height: 1;
    color: #404040
  }

  .time {
    font-size: 16px;
    color: #000000;
    opacity: .5;
  }
}

.schedule-clinic-specialist {
  margin-left: -70px;
  margin-right: -70px;
  width: calc(100% + 140px);
  overflow-x: auto;

  @media (max-width: 767px) {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }

  table {
    min-width: 1000px;
    width: calc(100% - 1px);
  }

  th {
    width: 13.32%;
    text-align: center;
    padding: 20px 0;

    &.today {
      background-color: $color-orange;

      .month-date,
      .week-day {
        color: white;
      }
    }

    .week-day {
      font-size: 16px;
      text-align: center;
      color: #404040;
      line-height: 1;
      margin-bottom: 10px;
      font-weight: 400;
    }

    .month-date {
      font-size: 14px;
      line-height: 1;
      color: #a6a6a6;
      display: block;
      font-weight: 400;
    }

    &:first-child {
      width: 70px;
      flex: 0 0 70px;
    }
  }

  td {
    width: 13.32%;
    border: solid 1px #d5d5d5;
    height: 40px;
    position: relative;

    &:first-child {
      width: 70px;
      flex: 0 0 70px;
    }

    .time {
      font-size: 14px;
      text-align: center;
      color: #404040;
      line-height: 1;
      vertical-align: middle;
    }
  }

  tr:nth-child(odd) td {
    background-color: #f3f3f3;
  }

  .appointment-item {
    position: absolute;
    width: calc(100% - 8px);
    left: 4px;
    top: 4px;
    border-radius: 2px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25), 0 0 2px 1px rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    padding: 22px 10px;
    z-index: 10;
    cursor: pointer;
    transition: all .3s ease;

    &:hover {
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.35), 0 0 2px 1px rgba(0, 0, 0, 0.08);
    }

    &:before {
      content: '';
      height: 12px;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 2px 2px 0 0;
    }

    &.appointment-type-01:before {
      background-color: #10d78c;
    }

    &.appointment-type-02:before {
      background-color: #28b4ea;
    }

    &.appointment-type-03:before {
      background-color: #284374;
    }

    .appointment-type {
      font-size: 14px;
      line-height: 1;
      color: #ea6728;
      margin-bottom: 10px;
    }

    .appointment-name {
      font-size: 14px;
      line-height: 16px;
      color: #404040;
      margin-bottom: 10px;
    }

    .appointment-time {
      font-size: 12px;
      line-height: 16px;
      color: #767676;
    }
  }
}

.schedule-legend {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 45px;

  .legend-item {
    width: 33.33333%;
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    .icon {
      border-radius: 25px;
      font-size: 14px;
      color: $color-white;
      line-height: 1;
      padding: 3px 6px;
      min-width: 20px;
      text-align: center;
      margin-right: 10px;
    }

    .name {
      font-size: 16px;
      line-height: 1;
      color: #404040;
    }

    .if {
      font-size: 20px;
      color: $color-orange;
      line-height: 1;
      margin-right: 10px;
      padding: 0 5px;
    }

    &.specialization-type-01 .icon {
      background-color: $color-specialization-type-01;
    }

    &.specialization-type-02 .icon {
      background-color: $color-specialization-type-02;
    }

    &.specialization-type-03 .icon {
      background-color: $color-specialization-type-03;
    }

    &.specialization-type-04 .icon {
      background-color: $color-specialization-type-04;
    }

    &.specialization-type-05 .icon {
      background-color: $color-specialization-type-05;
    }
  }
}

.legend-icon {
  border-radius: 25px;
  font-size: 14px;
  color: $color-white;
  line-height: 1;
  padding: 3px 6px;
  min-width: 20px;
  text-align: center;
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }

  &.specialization-type-01 {
    background-color: $color-specialization-type-01;
  }

  &.specialization-type-02 {
    background-color: $color-specialization-type-02;
  }

  &.specialization-type-03 {
    background-color: $color-specialization-type-03;
  }

  &.specialization-type-04 {
    background-color: $color-specialization-type-04;
  }

  &.specialization-type-05 {
    background-color: $color-specialization-type-05;
  }
}

.specialist-schedule-work-day {
  .appointment-item {
    margin-bottom: 30px;
  }

  .appointment-head-info {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .time {
      font-size: 20px;
      color: #404040;
      line-height: 1;
      margin-right: 20px;
    }

    .status {
      font-size: 16px;
      line-height: 1;
      font-weight: 400;

      .if {
        color: $color-orange;
        font-size: 18px;
      }
    }
  }

  .status-cancellation {
    .appointment-card {
      opacity: .3;
    }

    .status {
      color: $color-coral;
    }
  }

  .status-wait {
    .appointment-card {
      opacity: .3;
    }

    .status {
      color: $color-orange;
    }
  }

  .appointment-card {
    border-radius: 2px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25), 0 0 2px 1px rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    cursor: pointer;
    transition: all .3s ease;

    &:hover {
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.35), 0 0 2px 1px rgba(0, 0, 0, 0.08);
    }

    .appointment-type {
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      line-height: 1;
      padding: 6px 20px;
      border-radius: 2px 2px 0 0;
    }

    &.appointment-type-01 {
      .appointment-type {
        background-color: $color-specialization-type-01;
      }

      .appointment-container > *:before {
        background-color: $color-specialization-type-01;
      }
    }

    &.appointment-type-02 {
      .appointment-type {
        background-color: $color-specialization-type-02;
      }

      .appointment-container > *:before {
        background-color: $color-specialization-type-02;
      }
    }

    &.appointment-type-03 {
      .appointment-type {
        background-color: $color-specialization-type-03;
      }

      .appointment-container > *:before {
        background-color: $color-specialization-type-03;
      }
    }

    &.appointment-type-04 {
      .appointment-type {
        background-color: $color-specialization-type-04;
      }

      .appointment-container > *:before {
        background-color: $color-specialization-type-04;
      }
    }

    &.appointment-type-05 {
      .appointment-type {
        background-color: $color-specialization-type-05;
      }

      .appointment-container > *:before {
        background-color: $color-specialization-type-05;
      }
    }

    .appointment-container {
      display: flex;
      align-items: center;
      padding: 15px 20px;

      @media (max-width: 1649px) {
        padding: 0 10px;
        flex-wrap: wrap;
      }

      > * {
        padding: 0 35px;
        position: relative;

        @media (max-width: 1649px) {
          padding: 0 20px;
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          right: 0;
          width: 1px;
          height: 50px;
          top: 50%;
          margin-top: -25px;
        }

        &:first-child {
          padding-left: 0;

          @media (max-width: 1649px) {
            padding-left: 20px;
          }
        }

        &:last-child {
          padding-right: 0;

          &:before {
            content: none;
          }
        }
      }
    }
  }
}

.appointment-info-list {
  display: flex;
  align-items: center;
  padding: 15px 20px;

  &-item {
    padding: 0 35px;
    position: relative;
    display: flex;
    align-items: center;
    width: 33.33%;

    &:before {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      width: 1px;
      height: 50px;
      top: 50%;
      margin-top: -25px;
      background-color: #d8d8d8;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;

      &:before {
        content: none;
      }
    }
  }

  &-image {
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
    border-radius: 50%;
    overflow: hidden;
    border: none;
    margin-right: 24px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-content {
    text-align: left;
  }

  &-name {
    color: $color-gray-dark;
    font-size: 16px;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 7px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-post {
    opacity: 0.5;
    color: $color-black;
    font-size: 16px;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.status-block {
  @media (max-width: 1649px) {
    margin: 15px 0;
  }
  .status {
    color: $color-gray-dark;
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-bottom: 13px;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }

  .btn.btn-small {
    @media (max-width: 1649px) {
      padding: 9px 20px;
    }
  }
}
