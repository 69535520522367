.auth-background {
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 230px;
  padding-bottom: 50px;

  @media (max-width: 1500px) {
    padding: 100px 0 50px;
  }

  &:before {
    content: '';
    display: block;
    position: fixed;
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.79), rgba(234, 103, 40, 0.77));
    left: 0;
    top: 0;
    z-index: 20;
  }

  .load-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 50;

    img {
      transform-origin: 50% 50%;
      animation: loadingSpinner 1s linear infinite;
    }
  }
}

.auth-logo {
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 50;
  cursor: pointer;

  img {
    width: 100%;
  }

  @media (max-width: 1500px) {
    top: 30px;
    width: 150px;
  }
}

.auth-background-image {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  display: flex;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.auth-centered {
  position: relative;
  z-index: 50;
  width: 400px;

  @media (max-width: 678px) {
    width: 100%;
    padding: 0 15px;
  }
}

.auth-form-title {
  font-size: 30px;
  text-align: center;
  color: white;
  margin-bottom: 45px;
  font-weight: 300;
  line-height: 1;
}

.auth-form {
  label {
    display: block;
    position: relative;
    margin-bottom: 40px;

    > .if {
      font-size: 30px;
      color: white;
      line-height: 1;
      position: absolute;
      left: 35px;
      top: 15px;
      height: 30px;
      //transform: translateY(-50%);
      opacity: .4;
      width: 30px;
      text-align: center;
      transition: all .3s ease;
      display: flex;
      align-items: center;
      justify-content: center;

      &.if-light-envelope {
        font-size: 22px;
      }
    }

    .autocomplete-items {
      margin-top: -3px;
      border-radius: 10px;
      box-shadow: 0 1px 12px 2px rgba(58, 68, 86, 0.25);
      background-color: #ffffff;
      animation: showSubNav .5s ease 1 forwards;

      .autocomplele-item {
        background: none !important;
        padding: 10px 15px !important;

        &:last-child {
          border-bottom: none !important;
        }
      }
    }

    .show-password {
      position: absolute;
      top: 15px;
      right: 35px;

      .if {
        font-size: 30px;
        line-height: 1;
        color: $color-white;
        cursor: pointer;
        position: absolute;
        z-index: 1;
        opacity: 0;
        right: 0;
        transition: all .3s ease;

        &.if-eye {
          opacity: .4;
        }
      }
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="password"] {
    width: 100%;
    height: 60px;
    border-radius: 30px;
    border: none;
    box-shadow: 0 3px 15px 1px rgba(0, 0, 0, 0.3);
    background-color: rgba(255, 255, 255, 0.2);
    padding: 0 45px 0 85px;
    font-size: 18px;
    color: white;
    font-weight: 300;
    line-height: 18px;
    transition: all .3s ease;

    &.input-password {
      padding: 0 85px;
    }

    &::-webkit-credentials-auto-fill-button {
      background-color: white !important;
    }

    &:focus {
      background-color: rgba(255, 255, 255, 0.5);

      & + .if {
        opacity: 1;
      }

      & + .if + .show-password {
        .if-eye {
          opacity: 0;
        }

        .if-no-eye {
          opacity: 1;
        }
      }

      &[type="password"] + .if + .show-password {
        .if-eye {
          opacity: 1;
        }

        .if-no-eye {
          opacity: 0;
        }
      }
    }

    &:-webkit-autofill {
      background-color: white !important;

      & + .if {
        opacity: 1;
        color: $color-orange;
      }

      & + .if + .show-password {
        .if {
          color: $color-gray-dark;
        }

        .if-eye {
          opacity: 0;
        }

        .if-no-eye {
          opacity: 1;
        }
      }

      &[type="password"] + .if + .show-password {
        .if-eye {
          opacity: 1;
        }

        .if-no-eye {
          opacity: 0;
        }
      }
    }

    @include placeholder {
      color: white;
      opacity: .4;
    }

    @media (max-width: 767px) {
      padding: 0 30px 0 85px;

      &.input-password {
        padding: 0 85px;
      }
    }
  }

  .custom-auth-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    input {
      display: none;
    }

    .if {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      color: white;
      font-size: 13px;
      line-height: 1;
      opacity: 0;
      transition: all .3s ease;
    }

    .auth-checkbox {
      width: 24px;
      height: 24px;
      flex: 0 0 24px;
      border-radius: 4px;
      box-shadow: 0 3px 15px 1px rgba(0, 0, 0, 0.3);
      background-color: rgba(255, 255, 255, 0.2);
      margin-right: 20px;
      position: relative;
      transition: all .3s ease;
    }

    input:checked + .auth-checkbox {
      background-color: rgba(255, 255, 255, 0.5);

      .if {
        opacity: 1;
      }
    }

    .label {
      font-size: 18px;
      line-height: 1;
      color: #ffffff;
      font-weight: 300;

      a {
        opacity: 0.5;
        color: #ffffff;
        font-weight: 300;
        line-height: 1;
        display: inline;
        border-bottom: 1px solid white;
        transition: all .3s ease;

        &:hover {
          opacity: 1;
          color: white;
          text-decoration: none;
        }
      }
    }
  }

  .auth-error {
    margin-bottom: 20px;

    input[type="text"],
    input[type="email"],
    input[type="password"] {
      border: 2px solid red;

      &:-webkit-autofill + .if {
        color: red;
      }
    }

    > .if {
      color: red;
    }
  }

  .auth-error-message {
    display: block;

    span {
      display: block;
      font-size: 14px;
      line-height: 18px;
      color: #ffffff;
      margin-top: 13px;
      font-weight: 300;
      padding-left: 20px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .auth-button {
    width: 100%;
    height: 60px;
    border-radius: 30px;
    box-shadow: 0 3px 15px 1px rgba(0, 0, 0, 0.3);
    background-color: $color-orange;
    border: none;
    font-size: 18px;
    line-height: 1;
    text-align: center;
    color: white;
    font-weight: 300;
    cursor: pointer;
    transition: all .3s ease;

    &:not([disabled]):hover {
      background-color: white;
      color: $color-orange;
    }

    &[disabled] {
      opacity: .4;
      cursor: not-allowed;
    }
  }
}

.auth-socials-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px;

  button,
  a {
    width: 60px;
    height: 60px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s ease;

    .if {
      font-size: 28px;
      display: block;
      color: white;
      transition: all .3s ease;

      &.if-vkontakte {
        font-size: 20px;
      }
    }

    &:hover {
      background-color: white;
      text-decoration: none;

      .if {
        color: $color-orange;
      }
    }
  }
}

.auth-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;

  &.already-have {
    justify-content: center;
    flex-wrap: wrap;
  }

  .title {
    width: 100%;
    font-size: 16px;
    color: #ffffff;
    line-height: 1;
    margin-bottom: 10px;
    text-align: center;
    font-weight: 300;
  }

  a {
    opacity: 0.5;
    font-size: 16px;
    color: #ffffff;
    font-weight: 300;
    line-height: 1;
    display: inline-block;
    border-bottom: 1px solid white;
    transition: all .3s ease;

    &:hover {
      opacity: 1;
      color: white;
      text-decoration: none;
    }
  }
}

.auth-text-content {
  width: 100%;
  max-width: 1140px;
  padding: 60px 70px;
  border-radius: 10px;
  box-shadow: 0 2px 15px 2px rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.21);
  position: relative;
  z-index: 50;
  margin-bottom: 50px;
  color: #ffffff;
  overflow: hidden;

  @media (max-width: 767px) {
    padding: 30px 15px;
    width: calc(100% - 30px);
    margin-bottom: 0;
  }

  h1 {
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 30px;

    @media (max-width: 767px) {
      font-size: 24px;
    }
  }

  h2 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 34px;

    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  ol {
    list-style: none;
    counter-reset: authList;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 30px;
      font-size: 16px;
      line-height: 1.5;
      font-weight: 300;
      position: relative;
      counter-increment: authList;
      padding-left: 40px;

      ol {
        margin: 30px 0;
      }

      @media (max-width: 767px) {
        padding-left: 30px;
      }

      &:before {
        content: counter(authList) '.';
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}

.content-404 {
  position: relative;
  z-index: 50;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;

  .image-404 {
    width: 100%;
    max-width: 615px;
    margin: 0 auto 65px;

    img {
      width: 100%;
    }
  }

  .text {
    font-size: 36px;
    line-height: 1.17;
    font-weight: 300;
    color: white;
    margin-bottom: 50px;

    @media (max-width: 767px) {
      font-size: 24px;
    }
  }
}
