.cloud-navigation {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-bottom: 20px;

  .name {
	margin-left: 10px;
	color: $color-gray-dark;
	font-family: $gothamPro;
	font-size: 16px;
	line-height: 1;
	margin-bottom: 10px;
	margin-right: 10px;
  }

  .cloud {
	color: $color-white;
	font-family: $gothamPro;
	font-size: 12px;
	padding: 7px 18px;
	border-radius: 4px;
	background-color: $color-orange;
	transition: all .3s ease;
	text-decoration: none;
	margin-left: 10px;
	margin-bottom: 10px;

	&:hover {
	  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3), 0 1px 3px rgba(0, 0, 0, 0.2);
	}
  }
}
