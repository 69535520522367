.main-clinic-info-block {
  margin-bottom: 40px;
  cursor: pointer;

  .clinic-info-block-background {
	border-radius: 10px;
	box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.16);
	background-color: #ffffff;
	padding: 20px;
	text-align: center;
	transition: all .3s ease;
	position: relative;
	height: 100%;

	.image {
	  width: 80px;
	  height: 80px;
	  border-radius: 50%;
	  overflow: hidden;
	  margin: 0 auto 30px;

	  img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	  }
	}

	.name {
	  font-size: 16px;
	  font-weight: 500;
	  margin-bottom: 7px;
	}

	.address {
	  font-size: 14px;
	}

	&:hover {
	  box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.2);
	}
  }
}
