.clinic-schedule-item {
  display: flex;
  align-items: center;

  .time {
    display: flex;
    align-items: center;
    margin-right: 15px;

    > span {
      margin-right: 15px;
    }

    .mat-form-field {
      width: 65px;
    }
  }

  .mat-checkbox {
    margin-left: 35px;

    .mat-checkbox-layout {
      margin-bottom: 0;
    }
  }

  .name {
    width: 165px;
    font-size: 16px;
    font-weight: 500;
    color: $color-gray-dark;
  }

  .text-time {
    font-size: 16px;
    line-height: 38px;
    color: #404040;
  }
}
