body {
  font-family: $gothamPro;
  font-weight: 400;
  color: $color-gray-dark;
}

* {
  outline: none !important;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 0;
    margin: 0;
  }
}

a {
  color: $color-orange;
  transition: color .5s ease;
  cursor: pointer;

  &:hover {
    color: $color-coral;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-gray-dark;
  font-family: $gothamPro;
  font-weight: 400;
}

h1 {
  font-size: 30px;
  margin-bottom: 50px;
}

h2 {
  font-size: 26px;
  margin-bottom: 35px;
}

h3 {
  font-size: 24px;
  margin-bottom: 30px;
}

h4 {
  font-size: 20px;
  margin-bottom: 25px;
}

h5 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}

h6 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
}

button {
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}

hr {
  margin: 60px 0;
  border: none;
  height: 1px;
  background-color: #cbcbcb;

  & + .last-buttons {
    margin-top: -30px;
  }
}

.form-group .mat-icon {
  font-size: 12px;
  height: 12px;
}

.icon-gradient:before {
  background-image: linear-gradient(8deg, $color-coral, $color-orange);
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.content-background {
  background-color: #f1f1f1;
  padding-top: 80px;
  position: relative;
  min-height: 100vh;
  padding-left: 345px;
  transition: all .3s ease;

  &.no-sidebar {
    padding-left: 0;
  }

  &.sidebar-collapsed {
    padding-left: 80px;
  }

  @media (max-width: 991px) {
    padding-left: 0;
  }
}

.content-wrapper {
  padding: 50px 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 767px) {
    padding: 30px 15px;
  }
}

.main-title {
  font-size: 30px;
  line-height: 1;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }

  .title-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: -40px;

    @media (max-width: 991px) {
      width: 100%;
      margin-top: 15px;
    }

    button {
      margin-left: 40px;
    }
  }
}

.card-list {
  margin-bottom: -30px;
  width: 100%;

  @media (max-width: 767px) {
    width: calc(100% + 30px);
  }

  >div {
    margin-bottom: 30px;
  }
}

.card-add {
  border-radius: 10px;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .7s ease;
  min-height: 250px;

  .centered {
    text-align: center;
  }

  .if {
    display: block;
    font-size: 50px;
    line-height: 1;
    margin-bottom: 20px;
  }

  &:hover {
    box-shadow: 0 0 30px 1px rgba(58, 68, 86, 0.3);
  }
}

.main-container {
  border-radius: 10px;
  box-shadow: 1px 1px 7px 1px rgba(58, 68, 86, 0.3);
  background-color: #ffffff;
  padding: 60px 70px;
  width: 100%;
  position: relative;

  @media (max-width: 1439px) {
    padding: 30px;
  }

  &.no-padding-bottom {
    padding-bottom: 0;
    border-radius: 10px 10px 0 0;
  }

  h2 {
    font-size: 24px;
    font-weight: 300;
    line-height: 1;
    margin-top: 60px;
    margin-bottom: 35px;

    &:first-child {
      margin-top: 0;
    }
  }

  .last-buttons {
    margin-left: -40px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    button {
      margin-left: 40px;
      margin-top: 30px;
    }
  }

  &.has-filters {
    display: flex;
    justify-content: space-between;
    padding: 40px 30px;
  }

  @media (max-width: 767px) {
    padding: 30px 20px;
  }
}

.disabled-link {
  opacity: .4;
  cursor: not-allowed !important;
}

.router-wrapper {
  width: 100%;
}

.row {
  //width: 100%;
}
