.main-info-block-wrapper {
  margin-bottom: -40px;
}

.main-info-block {
  margin-bottom: 40px;

  .title {
    font-size: 14px;
    font-weight: 500;
    color: $color-orange;
    line-height: 1;
    margin-bottom: 15px;
  }

  .text {
    line-height: 1;
    font-size: 16px;
    margin-bottom: 11px;

    &.large-text {
      line-height: 1.5;
    }

    &.small-text {
      font-size: 12px;
    }
  }

  .date {
    font-size: 11px;
    color: #868686;
    line-height: 1;
    margin-bottom: 11px;
  }

  .orange-text {
    font-size: 16px;
    display: block;
    line-height: 1;
    color: $color-orange;
    margin-bottom: 15px;
  }

  .text-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;

    > div {
      margin-left: 20px;
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 1;
    }
  }

  > *:last-child {
    margin-bottom: 0;
  }

  > .text-list:last-child {
    margin-bottom: -10px;
  }

  .info-block-background {
    border-radius: 10px;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding: 20px;
    transition: all .3s ease;
    position: relative;
    height: 100%;

    .remove-field {
      position: absolute;
      line-height: 1;
      right: 10px;
      top: 10px;
      font-size: 14px;
    }

    > *:last-child {
      margin-bottom: 0;
    }

    > .text-list:last-child {
      margin-bottom: -10px;
    }

    &:hover {
      box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.2);
    }
  }
}
