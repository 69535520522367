.pet-small-info {
  display: flex;
  align-items: center;

  .mat-dialog-container & {
	text-align: left;
  }

  .image {
	@extend %round-small-image;
  }

  .content {
	.name {
	  font-size: 16px;
	  line-height: 1;
	  color: #404040;
	  margin-bottom: 7px;

	  &:last-child {
		margin-bottom: 0;
	  }
	}

	.post {
	  font-size: 16px;
	  line-height: 1;
	  color: #000000;
	  opacity: .5;
	}
  }
}
