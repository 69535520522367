@charset "UTF-8";
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-badge-small .mat-badge-content {
  font-size: 6px; }

.mat-badge-large .mat-badge-content {
  font-size: 24px; }

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px; }

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px; }

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px; }

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px; }

.mat-h5, .mat-typography h5 {
  font: 400 11.62px/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px; }

.mat-h6, .mat-typography h6 {
  font: 400 9.38px/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px; }

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif; }

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif; }
  .mat-body p, .mat-body-1 p, .mat-typography p {
    margin: 0 0 12px; }

.mat-small, .mat-caption {
  font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif; }

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 56px;
  letter-spacing: -0.05em; }

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 64px;
  letter-spacing: -0.02em; }

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 64px;
  letter-spacing: -0.005em; }

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 64px; }

.mat-bottom-sheet-container {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif; }

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500; }

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-card-title {
  font-size: 24px;
  font-weight: 500; }

.mat-card-header .mat-card-title {
  font-size: 20px; }

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px; }

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px; }

.mat-chip {
  font-size: 14px;
  font-weight: 500; }
  .mat-chip .mat-chip-trailing-icon.mat-icon,
  .mat-chip .mat-chip-remove.mat-icon {
    font-size: 18px; }

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-header-cell {
  font-size: 12px;
  font-weight: 500; }

.mat-cell, .mat-footer-cell {
  font-size: 14px; }

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-calendar-body {
  font-size: 13px; }

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500; }

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400; }

.mat-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif; }

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400; }

.mat-expansion-panel-content {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif; }

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-form-field-wrapper {
  padding-bottom: 1.34375em; }

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125; }

.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em; }
  .mat-form-field-prefix .mat-icon-button .mat-icon,
  .mat-form-field-suffix .mat-icon-button .mat-icon {
    height: 1.125em;
    line-height: 1.125; }

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent; }

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em; }

.mat-form-field-label {
  top: 1.34375em; }

.mat-form-field-underline {
  bottom: 1.34375em; }

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.66666667em;
  top: calc(100% - 1.79166667em); }

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em; }

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0; }

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.33335333%; }

.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em; }

.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em; }

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.54166667em;
  top: calc(100% - 1.66666667em); }

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75); }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75); }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75); } }

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0; }

.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em; }

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0; }

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em; }

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.33334333%; }

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px; }
  .mat-grid-tile-header .mat-line,
  .mat-grid-tile-footer .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-grid-tile-header .mat-line:nth-child(n+2),
    .mat-grid-tile-footer .mat-line:nth-child(n+2) {
      font-size: 12px; }

input.mat-input-element {
  margin-top: -0.0625em; }

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400; }

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px; }

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-select-trigger {
  height: 1.125em; }

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500; }

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-step-label {
  font-size: 14px;
  font-weight: 400; }

.mat-step-sub-label-error {
  font-weight: normal; }

.mat-step-label-error {
  font-size: 14px; }

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500; }

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500; }

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  margin: 0; }

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px; }

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px; }

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-list-base .mat-list-item {
  font-size: 16px; }
  .mat-list-base .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
      font-size: 14px; }

.mat-list-base .mat-list-option {
  font-size: 16px; }
  .mat-list-base .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
      font-size: 14px; }

.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500; }

.mat-list-base[dense] .mat-list-item {
  font-size: 12px; }
  .mat-list-base[dense] .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
      font-size: 12px; }

.mat-list-base[dense] .mat-list-option {
  font-size: 12px; }
  .mat-list-base[dense] .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
      font-size: 12px; }

.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500; }

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px; }

.mat-optgroup-label {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif; }

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px; }

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500; }

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px; }

.mat-ripple {
  overflow: hidden;
  position: relative; }

.mat-ripple.mat-ripple-unbounded {
  overflow: visible; }

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0); }
  @media (-ms-high-contrast: active) {
    .mat-ripple-element {
      display: none; } }

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none; }

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.cdk-overlay-container {
  position: fixed;
  z-index: 1000; }
  .cdk-overlay-container:empty {
    display: none; }

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000; }

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%; }

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0; }
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1; }
    @media screen and (-ms-high-contrast: active) {
      .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
        opacity: 0.6; } }

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32); }

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0; }

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px; }

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll; }

@keyframes cdk-text-field-autofill-start {
  /*!*/ }

@keyframes cdk-text-field-autofill-end {
  /*!*/ }

.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation-name: cdk-text-field-autofill-start; }

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation-name: cdk-text-field-autofill-end; }

textarea.cdk-textarea-autosize {
  resize: none; }

textarea.cdk-textarea-autosize-measuring {
  height: auto !important;
  overflow: hidden !important;
  padding: 2px 0 !important;
  box-sizing: content-box !important; }

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1); }

.mat-option {
  color: rgba(0, 0, 0, 0.87); }
  .mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
    background: rgba(0, 0, 0, 0.04); }
  .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
    background: rgba(0, 0, 0, 0.04); }
  .mat-option.mat-active {
    background: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.87); }
  .mat-option.mat-option-disabled {
    color: rgba(0, 0, 0, 0.38); }

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff5722; }

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff1744; }

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336; }

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54); }

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38); }

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54); }
  .mat-pseudo-checkbox::after {
    color: #fafafa; }

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0; }

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ff1744; }

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #ff5722; }

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336; }

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0; }

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12); }

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12); }

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12); }

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12); }

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12); }

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12); }

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); }

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87); }

.mat-theme-loaded-marker {
  display: none; }

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-autocomplete-panel:not([class*='mat-elevation-z']) {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
    background: white; }
    .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
      color: rgba(0, 0, 0, 0.87); }

.mat-badge-content {
  color: white;
  background: #ff5722; }
  @media (-ms-high-contrast: active) {
    .mat-badge-content {
      outline: solid 1px;
      border-radius: 0; } }

.mat-badge-accent .mat-badge-content {
  background: #ff1744;
  color: white; }

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336; }

.mat-badge {
  position: relative; }

.mat-badge-hidden .mat-badge-content {
  display: none; }

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38); }

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none; }

.mat-badge-content.mat-badge-active {
  transform: none; }

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px; }

.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px; }

.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px; }

.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px; }

[dir='rtl'] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px; }

.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px; }

[dir='rtl'] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px; }

.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px; }

[dir='rtl'] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px; }

.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px; }

[dir='rtl'] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px; }

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px; }

.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px; }

.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px; }

.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px; }

[dir='rtl'] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px; }

.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px; }

[dir='rtl'] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px; }

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px; }

[dir='rtl'] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px; }

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px; }

[dir='rtl'] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px; }

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px; }

.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px; }

.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px; }

.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px; }

[dir='rtl'] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px; }

.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px; }

[dir='rtl'] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px; }

.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px; }

[dir='rtl'] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px; }

.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px; }

[dir='rtl'] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px; }

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87); }

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent; }
  .mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
    color: #ff5722; }
  .mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
    color: #ff1744; }
  .mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
    color: #f44336; }
  .mat-button.mat-primary[disabled], .mat-button.mat-accent[disabled], .mat-button.mat-warn[disabled], .mat-button[disabled][disabled], .mat-icon-button.mat-primary[disabled], .mat-icon-button.mat-accent[disabled], .mat-icon-button.mat-warn[disabled], .mat-icon-button[disabled][disabled], .mat-stroked-button.mat-primary[disabled], .mat-stroked-button.mat-accent[disabled], .mat-stroked-button.mat-warn[disabled], .mat-stroked-button[disabled][disabled] {
    color: rgba(0, 0, 0, 0.26); }
  .mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
    background-color: #ff5722; }
  .mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
    background-color: #ff1744; }
  .mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
    background-color: #f44336; }
  .mat-button[disabled] .mat-button-focus-overlay, .mat-icon-button[disabled] .mat-button-focus-overlay, .mat-stroked-button[disabled] .mat-button-focus-overlay {
    background-color: transparent; }
  .mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
    opacity: 0.1;
    background-color: currentColor; }

.mat-button-focus-overlay {
  background: black; }

.mat-stroked-button:not([disabled]) {
  border-color: rgba(0, 0, 0, 0.12); }

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white; }
  .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    color: white; }
  .mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
    color: white; }
  .mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
    color: white; }
  .mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
    color: rgba(0, 0, 0, 0.26); }
  .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    background-color: #ff5722; }
  .mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
    background-color: #ff1744; }
  .mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
    background-color: #f44336; }
  .mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
    background-color: rgba(0, 0, 0, 0.12); }
  .mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1); }
  .mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1); }
  .mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1); }

.mat-stroked-button:not([class*='mat-elevation-z']), .mat-flat-button:not([class*='mat-elevation-z']) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-raised-button:not([class*='mat-elevation-z']) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.mat-raised-button:not([disabled]):active:not([class*='mat-elevation-z']) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

.mat-raised-button[disabled]:not([class*='mat-elevation-z']) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-fab:not([class*='mat-elevation-z']), .mat-mini-fab:not([class*='mat-elevation-z']) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.mat-fab:not([disabled]):active:not([class*='mat-elevation-z']), .mat-mini-fab:not([disabled]):active:not([class*='mat-elevation-z']) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }

.mat-fab[disabled]:not([class*='mat-elevation-z']), .mat-mini-fab[disabled]:not([class*='mat-elevation-z']) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none; }

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38); }
  .mat-button-toggle .mat-button-toggle-focus-overlay {
    background-color: rgba(0, 0, 0, 0.12); }

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white; }
  .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
    background-color: black; }

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12); }

[dir='rtl'] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12); }

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12); }

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54); }
  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    color: rgba(0, 0, 0, 0.87); }

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee; }
  .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
    background: white; }
  .mat-button-toggle-disabled.mat-button-toggle-checked {
    background-color: #bdbdbd; }

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12); }

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-card:not([class*='mat-elevation-z']) {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }
  .mat-card.mat-card-flat:not([class*='mat-elevation-z']) {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54); }

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54); }

.mat-checkbox-checkmark {
  fill: #fafafa; }

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important; }
  @media (-ms-high-contrast: black-on-white) {
    .mat-checkbox-checkmark-path {
      stroke: #000 !important; } }

.mat-checkbox-mixedmark {
  background-color: #fafafa; }

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #ff5722; }

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ff1744; }

.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336; }

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0; }

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0; }

.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54); }

@media (-ms-high-contrast: active) {
  .mat-checkbox-disabled {
    opacity: 0.5; } }

@media (-ms-high-contrast: active) {
  .mat-checkbox-background {
    background: none; } }

.mat-checkbox:not(.mat-checkbox-disabled).mat-primary .mat-checkbox-ripple .mat-ripple-element {
  background-color: #ff5722; }

.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: #ff1744; }

.mat-checkbox:not(.mat-checkbox-disabled).mat-warn .mat-checkbox-ripple .mat-ripple-element {
  background-color: #f44336; }

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87); }
  .mat-chip.mat-standard-chip .mat-chip-remove {
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.4; }
  .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12); }
  .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
    opacity: 0.54; }
  .mat-chip.mat-standard-chip.mat-chip-disabled {
    opacity: 0.4; }
  .mat-chip.mat-standard-chip::after {
    background: black; }

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #ff5722;
  color: white; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
    color: white;
    opacity: 0.4; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
    background: rgba(255, 255, 255, 0.1); }

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
    color: white;
    opacity: 0.4; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
    background: rgba(255, 255, 255, 0.1); }

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ff1744;
  color: white; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
    color: white;
    opacity: 0.4; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
    background: rgba(255, 255, 255, 0.1); }

.mat-table {
  background: white; }

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit; }

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12); }

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54); }

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87); }

.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54); }

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54); }

.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38); }

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12); }

.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54); }

.mat-calendar-body-cell-content {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent; }

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgba(0, 0, 0, 0.38); }

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(0, 0, 0, 0.04); }

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.18); }

.mat-calendar-body-selected {
  background-color: #ff5722;
  color: white; }

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 87, 34, 0.4); }

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white; }

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
    background-color: #ff1744;
    color: white; }
  .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
    background-color: rgba(255, 23, 68, 0.4); }
  .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px white; }
  .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
    background-color: #f44336;
    color: white; }
  .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
    background-color: rgba(244, 67, 54, 0.4); }
  .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px white; }

.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-datepicker-toggle-active {
  color: #ff5722; }
  .mat-datepicker-toggle-active.mat-accent {
    color: #ff1744; }
  .mat-datepicker-toggle-active.mat-warn {
    color: #f44336; }

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87); }

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12); }

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12); }

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12); }

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']).cdk-keyboard-focused, .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']).cdk-program-focused, .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
  background: rgba(0, 0, 0, 0.04); }

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true']) .mat-expansion-panel-header:hover {
    background: white; } }

.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87); }

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54); }

.mat-expansion-panel-header[aria-disabled='true'] {
  color: rgba(0, 0, 0, 0.26); }
  .mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-title,
  .mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-description {
    color: inherit; }

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6); }

.mat-hint {
  color: rgba(0, 0, 0, 0.6); }

.mat-form-field.mat-focused .mat-form-field-label {
  color: #ff5722; }
  .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
    color: #ff1744; }
  .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
    color: #f44336; }

.mat-focused .mat-form-field-required-marker {
  color: #ff1744; }

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87); }

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #ff5722; }
  .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
    background-color: #ff1744; }
  .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
    background-color: #f44336; }

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #ff5722; }

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ff1744; }

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336; }

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336; }
  .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
  .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
    color: #f44336; }

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336; }

.mat-error {
  color: #f44336; }

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54); }

.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54); }

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42); }

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x; }

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42); }

.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x; }

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04); }

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02); }

.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42); }

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38); }

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent; }

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12); }

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87); }

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #ff5722; }

.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ff1744; }

.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336; }

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336; }

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38); }

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06); }

.mat-icon.mat-primary {
  color: #ff5722; }

.mat-icon.mat-accent {
  color: #ff1744; }

.mat-icon.mat-warn {
  color: #f44336; }

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54); }

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38); }

.mat-input-element {
  caret-color: #ff5722; }
  .mat-input-element::placeholder {
    color: rgba(0, 0, 0, 0.42); }
  .mat-input-element::-moz-placeholder {
    color: rgba(0, 0, 0, 0.42); }
  .mat-input-element::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.42); }
  .mat-input-element:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.42); }

.mat-accent .mat-input-element {
  caret-color: #ff1744; }

.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336; }

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336; }

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87); }

.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87); }

.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54); }

.mat-list-item-disabled {
  background-color: #eeeeee; }

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04); }

.mat-menu-panel {
  background: white; }
  .mat-menu-panel:not([class*='mat-elevation-z']) {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87); }
  .mat-menu-item[disabled], .mat-menu-item[disabled]::after {
    color: rgba(0, 0, 0, 0.38); }

.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54); }

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04); }

.mat-paginator {
  background: white; }

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54); }

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54); }

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54); }

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-progress-bar-background {
  fill: #ffccbc; }

.mat-progress-bar-buffer {
  background-color: #ffccbc; }

.mat-progress-bar-fill::after {
  background-color: #ff5722; }

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #ffcdd2; }

.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #ffcdd2; }

.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ff1744; }

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2; }

.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2; }

.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336; }

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #ff5722; }

.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #ff1744; }

.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336; }

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54); }

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff5722; }

.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #ff5722; }

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff1744; }

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ff1744; }

.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336; }

.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336; }

.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38); }

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38); }

.mat-radio-button .mat-ripple-element {
  background-color: black; }

.mat-select-value {
  color: rgba(0, 0, 0, 0.87); }

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42); }

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38); }

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54); }

.mat-select-panel {
  background: white; }
  .mat-select-panel:not([class*='mat-elevation-z']) {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
    background: rgba(0, 0, 0, 0.12); }

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #ff5722; }

.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ff1744; }

.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336; }

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336; }

.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38); }

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87); }

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-drawer.mat-drawer-push {
    background-color: white; }
  .mat-drawer:not(.mat-drawer-side) {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12); }

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12); }
  .mat-drawer-side.mat-drawer-end {
    border-left: solid 1px rgba(0, 0, 0, 0.12);
    border-right: none; }

[dir='rtl'] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none; }
  [dir='rtl'] .mat-drawer-side.mat-drawer-end {
    border-left: none;
    border-right: solid 1px rgba(0, 0, 0, 0.12); }

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6); }

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff1744; }

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 23, 68, 0.54); }

.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ff1744; }

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff5722; }

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 87, 34, 0.54); }

.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #ff5722; }

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336; }

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54); }

.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336; }

.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black; }

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa; }

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38); }

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26); }

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #ff5722; }

.mat-primary .mat-slider-thumb-label-text {
  color: white; }

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #ff1744; }

.mat-accent .mat-slider-thumb-label-text {
  color: white; }

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: #f44336; }

.mat-warn .mat-slider-thumb-label-text {
  color: white; }

.mat-slider-focus-ring {
  background-color: rgba(255, 23, 68, 0.2); }

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38); }

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26); }

.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26); }

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12); }

.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87); }

.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26); }

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent; }

.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26); }

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7); }

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent); }

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent); }

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04); }

@media (hover: none) {
  .mat-step-header:hover {
    background: none; } }

.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54); }

.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white; }

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #ff5722;
  color: white; }

.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336; }

.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87); }

.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336; }

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white; }

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12); }

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12); }

.mat-sort-header-arrow {
  color: #757575; }

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none; }

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87); }
  .mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
    color: rgba(0, 0, 0, 0.38); }

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87); }

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-tab-group[class*='mat-background-'] .mat-tab-header,
.mat-tab-nav-bar[class*='mat-background-'] {
  border-bottom: none;
  border-top: none; }

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 188, 0.3); }

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #ff5722; }

.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white; }

.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3); }

.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ff1744; }

.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white; }

.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3); }

.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336; }

.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white; }

.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 188, 0.3); }

.mat-tab-group.mat-background-primary .mat-tab-header, .mat-tab-group.mat-background-primary .mat-tab-links, .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .mat-tab-nav-bar.mat-background-primary .mat-tab-links {
  background-color: #ff5722; }

.mat-tab-group.mat-background-primary .mat-tab-label, .mat-tab-group.mat-background-primary .mat-tab-link, .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white; }
  .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white; }

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-primary .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12); }

.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3); }

.mat-tab-group.mat-background-accent .mat-tab-header, .mat-tab-group.mat-background-accent .mat-tab-links, .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .mat-tab-nav-bar.mat-background-accent .mat-tab-links {
  background-color: #ff1744; }

.mat-tab-group.mat-background-accent .mat-tab-label, .mat-tab-group.mat-background-accent .mat-tab-link, .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white; }
  .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white; }

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-accent .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12); }

.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3); }

.mat-tab-group.mat-background-warn .mat-tab-header, .mat-tab-group.mat-background-warn .mat-tab-links, .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .mat-tab-nav-bar.mat-background-warn .mat-tab-links {
  background-color: #f44336; }

.mat-tab-group.mat-background-warn .mat-tab-label, .mat-tab-group.mat-background-warn .mat-tab-link, .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white; }
  .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white; }

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-warn .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12); }

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87); }
  .mat-toolbar.mat-primary {
    background: #ff5722;
    color: white; }
  .mat-toolbar.mat-accent {
    background: #ff1744;
    color: white; }
  .mat-toolbar.mat-warn {
    background: #f44336;
    color: white; }
  .mat-toolbar .mat-form-field-underline,
  .mat-toolbar .mat-form-field-ripple,
  .mat-toolbar .mat-focused .mat-form-field-ripple {
    background-color: currentColor; }
  .mat-toolbar .mat-form-field-label,
  .mat-toolbar .mat-focused .mat-form-field-label,
  .mat-toolbar .mat-select-value,
  .mat-toolbar .mat-select-arrow,
  .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
    color: inherit; }
  .mat-toolbar .mat-input-element {
    caret-color: currentColor; }

.mat-tooltip {
  background: rgba(97, 97, 97, 0.9); }

.mat-tree {
  background: white; }

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87); }

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.mat-simple-snackbar-action {
  color: #ff1744; }

/*
	Abstracts
*/
/*
Fonts mast be next file path - FontName/FontName-Weight.woff
*/
.if, [class^="i-icon-"], [class*=" i-icon-"], .dashboard-card .head .info .info-tooltip:before, .more-popup .hidden-more-popup:before, .header-navigation .sub-navigation:before, .header-user-info:before {
  font-family: "svgfont";
  font-style: normal;
  font-weight: normal;
  text-rendering: auto;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.user-small-info .image, .specialist-small-info .image, .pet-small-info .image, .clinic-small-info .image {
  border: solid 1px #ea6728;
  background-color: #d8d8d8;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 25px; }
  .user-small-info .image img, .specialist-small-info .image img, .pet-small-info .image img, .clinic-small-info .image img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

/*
	Base
*/
/*!
 * Bootstrap Reboot v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

a:hover {
  color: #0056b3;
  text-decoration: underline; }

/*
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}*/
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

/*# sourceMappingURL=bootstrap-reboot.css.map */
@font-face {
  font-family: "GothamPro";
  src: url("../assets/fonts/GothamPro/GothamPro-Light.woff?v=2") format("woff"), url("../assets/fonts/GothamPro/GothamPro-Light.woff2?v=2") format("woff2");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "GothamPro";
  src: url("../assets/fonts/GothamPro/GothamPro-Regular.woff?v=2") format("woff"), url("../assets/fonts/GothamPro/GothamPro-Regular.woff2?v=2") format("woff2");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "GothamPro";
  src: url("../assets/fonts/GothamPro/GothamPro-Italic.woff?v=2") format("woff"), url("../assets/fonts/GothamPro/GothamPro-Italic.woff2?v=2") format("woff2");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "GothamPro";
  src: url("../assets/fonts/GothamPro/GothamPro-Medium.woff?v=2") format("woff"), url("../assets/fonts/GothamPro/GothamPro-Medium.woff2?v=2") format("woff2");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "GothamPro";
  src: url("../assets/fonts/GothamPro/GothamPro-Bold.woff?v=2") format("woff"), url("../assets/fonts/GothamPro/GothamPro-Bold.woff2?v=2") format("woff2");
  font-weight: 700;
  font-style: normal; }

/*
	Components
*/
/*!
 * Bootstrap Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1360px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%; }

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%; }

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%; }

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%; }

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333333%; }

.offset-2 {
  margin-left: 16.66666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333333%; }

.offset-5 {
  margin-left: 41.66666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333333%; }

.offset-8 {
  margin-left: 66.66666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333333%; }

.offset-11 {
  margin-left: 91.66666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333333%; }
  .offset-sm-2 {
    margin-left: 16.66666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333333%; }
  .offset-sm-5 {
    margin-left: 41.66666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333333%; }
  .offset-sm-8 {
    margin-left: 66.66666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333333%; }
  .offset-sm-11 {
    margin-left: 91.66666667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333333%; }
  .offset-md-2 {
    margin-left: 16.66666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333333%; }
  .offset-md-5 {
    margin-left: 41.66666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333333%; }
  .offset-md-8 {
    margin-left: 66.66666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333333%; }
  .offset-md-11 {
    margin-left: 91.66666667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333333%; }
  .offset-lg-2 {
    margin-left: 16.66666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333333%; }
  .offset-lg-5 {
    margin-left: 41.66666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333333%; }
  .offset-lg-8 {
    margin-left: 66.66666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333333%; }
  .offset-lg-11 {
    margin-left: 91.66666667%; } }

@media (min-width: 1360px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333333%; }
  .offset-xl-2 {
    margin-left: 16.66666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333333%; }
  .offset-xl-5 {
    margin-left: 41.66666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333333%; }
  .offset-xl-8 {
    margin-left: 66.66666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333333%; }
  .offset-xl-11 {
    margin-left: 91.66666667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1360px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1360px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1360px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

@font-face {
  font-family: "svgfont";
  src: url("/assets/fonts/svgfont.eot");
  src: url("/assets/fonts/svgfont.eot?#iefix") format("eot"), url("/assets/fonts/svgfont.woff") format("woff"), url("/assets/fonts/svgfont.woff2") format("woff2"), url("/assets/fonts/svgfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

.if, [class^="i-icon-"], [class*=" i-icon-"], .dashboard-card .head .info .info-tooltip:before, .more-popup .hidden-more-popup:before, .header-navigation .sub-navigation:before, .header-user-info:before {
  font-family: "svgfont";
  font-style: normal;
  font-weight: normal;
  text-rendering: auto;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.if, [class^="i-icon-"], [class*=" i-icon-"] {
  display: inline-block; }

.if-add-round:before {
  content: ""; }

.if-address:before {
  content: ""; }

.if-alarm:before {
  content: ""; }

.if-angle-down:before {
  content: ""; }

.if-angle-left:before {
  content: ""; }

.if-angle-right:before {
  content: ""; }

.if-angry-dog:before {
  content: ""; }

.if-burger-02:before {
  content: ""; }

.if-burger:before {
  content: ""; }

.if-calendar:before {
  content: ""; }

.if-call-in:before {
  content: ""; }

.if-call-out:before {
  content: ""; }

.if-change-password:before {
  content: ""; }

.if-check:before {
  content: ""; }

.if-clinic:before {
  content: ""; }

.if-clock:before {
  content: ""; }

.if-close:before {
  content: ""; }

.if-dashboard:before {
  content: ""; }

.if-edit-02:before {
  content: ""; }

.if-edit:before {
  content: ""; }

.if-empty-box:before {
  content: ""; }

.if-exit:before {
  content: ""; }

.if-eye:before {
  content: ""; }

.if-facebook:before {
  content: ""; }

.if-garbage:before {
  content: ""; }

.if-google:before {
  content: ""; }

.if-happy-cat:before {
  content: ""; }

.if-hearth:before {
  content: ""; }

.if-info:before {
  content: ""; }

.if-instagram:before {
  content: ""; }

.if-internet:before {
  content: ""; }

.if-light-clinic:before {
  content: ""; }

.if-light-envelope:before {
  content: ""; }

.if-light-locked:before {
  content: ""; }

.if-light-phone:before {
  content: ""; }

.if-light-point:before {
  content: ""; }

.if-light-user:before {
  content: ""; }

.if-list:before {
  content: ""; }

.if-long-arrow-right:before {
  content: ""; }

.if-man-detail:before {
  content: ""; }

.if-more-button-dots:before {
  content: ""; }

.if-more:before {
  content: ""; }

.if-no-eye:before {
  content: ""; }

.if-notepad:before {
  content: ""; }

.if-paws:before {
  content: ""; }

.if-photo-camera:before {
  content: ""; }

.if-point:before {
  content: ""; }

.if-popover-arrow:before {
  content: ""; }

.if-price:before {
  content: ""; }

.if-return:before {
  content: ""; }

.if-security:before {
  content: ""; }

.if-settings:before {
  content: ""; }

.if-star-filled:before {
  content: ""; }

.if-star:before {
  content: ""; }

.if-support:before {
  content: ""; }

.if-user-man:before {
  content: ""; }

.if-user-woman:before {
  content: ""; }

.if-vkontakte:before {
  content: ""; }

.if-woman-detail:before {
  content: ""; }

.btn {
  display: inline-block;
  transition: all .3s ease;
  border: none;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  padding: 13px 45px;
  font-size: 16px;
  line-height: 1;
  font-weight: 300;
  border-radius: 6px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  text-align: center; }
  .btn .if {
    font-size: 14px;
    margin-right: 20px;
    line-height: 1; }
  .btn.icon-right .if {
    margin-right: 0;
    margin-left: 20px; }
  .btn[disabled] {
    opacity: .4;
    cursor: not-allowed; }
  .btn:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2); }
  .btn.btn-small {
    font-size: 14px;
    padding: 9px 35px; }
    .btn.btn-small .if {
      font-size: 12px; }
  .btn.btn-large {
    font-size: 18px;
    padding: 17px 46px; }
    .btn.btn-large .if {
      font-size: 16px; }
  .btn.btn-primary {
    background-image: linear-gradient(25deg, #fe4543, #ea6728);
    color: #ffffff; }
  .btn.btn-default {
    background-color: #404040;
    color: #ffffff; }

.button-transparent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ea6728;
  font-size: 14px;
  line-height: 1; }
  .button-transparent .if {
    font-size: 14px;
    margin-right: 10px; }
  .button-transparent.btn-large {
    font-size: 16px; }

.see-more {
  font-size: 12px;
  line-height: 1;
  transition: all .3s ease;
  display: block;
  color: #404040; }
  .see-more .if {
    font-size: 7px;
    line-height: 1;
    margin-left: 14px; }
  .see-more:hover {
    color: #ea6728;
    text-decoration: none; }

.dialog-wrapper {
  position: fixed;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.67);
  width: 100%;
  min-height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px; }
  .dialog-wrapper hr {
    margin: 30px 0; }
  @media (max-width: 767px) {
    .dialog-wrapper {
      padding: 10px; } }

.mat-dialog-container {
  max-width: 400px;
  width: 100%;
  border-radius: 10px !important;
  background-color: #ffffff;
  padding: 60px 40px !important;
  text-align: center; }
  .change-photo-dialog .mat-dialog-container,
  .time-grid-clinic-dialog .mat-dialog-container {
    width: 600px;
    max-width: 600px; }
  .specialist-add-dialog .mat-dialog-container {
    width: 780px;
    max-width: 100%; }
  .record-clinic-dialog .mat-dialog-container {
    width: 780px;
    max-width: 100%; }
  .doctor-appointment-dialog .mat-dialog-container {
    width: 100%;
    max-width: 490px; }
  .mat-dialog-container p {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    margin-bottom: 20px; }
    .mat-dialog-container p:last-child {
      margin-bottom: 0; }
  .mat-dialog-container .btn {
    margin-top: 30px; }
    @media (max-width: 767px) {
      .mat-dialog-container .btn {
        margin-top: 0; } }
  @media (max-width: 767px) {
    .mat-dialog-container {
      padding: 30px 15px !important; } }

.dialog-icon {
  text-align: center;
  margin-bottom: 15px; }
  .dialog-icon .if {
    line-height: 1;
    font-size: 80px; }
    @media (max-width: 767px) {
      .dialog-icon .if {
        font-size: 60px; } }

.dialog-title {
  color: #404040;
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 50px; }
  @media (max-width: 767px) {
    .dialog-title {
      margin-bottom: 20px; } }

.dialog-buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-left: -40px; }
  .dialog-buttons button {
    margin-left: 40px; }
    @media (max-width: 767px) {
      .dialog-buttons button {
        margin-bottom: 20px; } }

.mat-typography .load-local-spinner {
  position: static;
  transform: none;
  margin: 40px auto 70px; }

.mat-dialog-appointment {
  padding-bottom: 20px !important; }

@media (max-width: 767px) {
  .mat-dialog-content {
    padding: 0 15px 20px !important;
    margin: 0 -15px !important; }
  .mat-dialog-actions {
    margin-bottom: -15px !important; } }

.custom-tabs-nav {
  position: relative;
  z-index: 10; }
  .custom-tabs-nav ul {
    display: flex; }
  .custom-tabs-nav button {
    padding: 12px 25px;
    line-height: 1;
    font-size: 16px;
    color: #404040;
    transition: all .3s ease; }
    .custom-tabs-nav button.active {
      background-color: #ffffff; }
  .custom-tabs-nav + .router-wrapper .main-container,
  .custom-tabs-nav + .main-container {
    border-radius: 0 10px 10px 10px; }

.main-info-block-wrapper {
  margin-bottom: -40px; }

.main-info-block {
  margin-bottom: 40px; }
  .main-info-block .title {
    font-size: 14px;
    font-weight: 500;
    color: #ea6728;
    line-height: 1;
    margin-bottom: 15px; }
  .main-info-block .text {
    line-height: 1;
    font-size: 16px;
    margin-bottom: 11px; }
    .main-info-block .text.large-text {
      line-height: 1.5; }
    .main-info-block .text.small-text {
      font-size: 12px; }
  .main-info-block .date {
    font-size: 11px;
    color: #868686;
    line-height: 1;
    margin-bottom: 11px; }
  .main-info-block .orange-text {
    font-size: 16px;
    display: block;
    line-height: 1;
    color: #ea6728;
    margin-bottom: 15px; }
  .main-info-block .text-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px; }
    .main-info-block .text-list > div {
      margin-left: 20px;
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 1; }
  .main-info-block > *:last-child {
    margin-bottom: 0; }
  .main-info-block > .text-list:last-child {
    margin-bottom: -10px; }
  .main-info-block .info-block-background {
    border-radius: 10px;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding: 20px;
    transition: all .3s ease;
    position: relative;
    height: 100%; }
    .main-info-block .info-block-background .remove-field {
      position: absolute;
      line-height: 1;
      right: 10px;
      top: 10px;
      font-size: 14px; }
    .main-info-block .info-block-background > *:last-child {
      margin-bottom: 0; }
    .main-info-block .info-block-background > .text-list:last-child {
      margin-bottom: -10px; }
    .main-info-block .info-block-background:hover {
      box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.2); }

.main-clinic-info-block {
  margin-bottom: 40px;
  cursor: pointer; }
  .main-clinic-info-block .clinic-info-block-background {
    border-radius: 10px;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding: 20px;
    text-align: center;
    transition: all .3s ease;
    position: relative;
    height: 100%; }
    .main-clinic-info-block .clinic-info-block-background .image {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto 30px; }
      .main-clinic-info-block .clinic-info-block-background .image img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .main-clinic-info-block .clinic-info-block-background .name {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 7px; }
    .main-clinic-info-block .clinic-info-block-background .address {
      font-size: 14px; }
    .main-clinic-info-block .clinic-info-block-background:hover {
      box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.2); }

.schedule-info .item {
  display: flex;
  margin-bottom: 25px; }
  .schedule-info .item .left,
  .schedule-info .item .right {
    font-size: 16px;
    line-height: 1;
    font-weight: 300; }
  .schedule-info .item .left {
    width: 165px;
    font-weight: 500; }

.dashboard-card {
  border-radius: 10px;
  box-shadow: 1px 1px 7px 1px rgba(58, 68, 86, 0.3);
  background-color: #ffffff;
  min-height: 250px;
  position: relative;
  height: 100%; }
  .dashboard-card .head {
    height: 54px;
    border-radius: 10px 10px 0 0;
    background-image: linear-gradient(38deg, #fc4a4a, #ea6728);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 22px;
    width: 100%;
    position: relative;
    z-index: 10; }
    .dashboard-card .head .text {
      font-size: 16px;
      color: #ffffff;
      line-height: 18px; }
    .dashboard-card .head .info {
      width: 20px;
      flex: 0 0 20px;
      margin-left: 20px;
      line-height: 1; }
      .dashboard-card .head .info .if {
        font-size: 20px;
        color: white;
        cursor: pointer; }
      .dashboard-card .head .info .info-tooltip {
        position: absolute;
        right: -5px;
        top: 45px;
        border-radius: 10px;
        box-shadow: 0 0 11px 1px rgba(58, 68, 86, 0.3);
        background-color: #ffffff;
        padding: 20px;
        display: none;
        animation: showSubNav .5s ease 1 forwards;
        width: calc(100% - 30px);
        font-size: 14px;
        line-height: 18px; }
        .dashboard-card .head .info .info-tooltip:before {
          content: "";
          position: absolute;
          right: 23px;
          top: -7px;
          font-size: 7px;
          color: white; }
        .dashboard-card .head .info .info-tooltip:after {
          content: '';
          position: absolute;
          left: 0;
          top: -7px;
          width: 100%;
          height: 7px; }
      .dashboard-card .head .info:hover .info-tooltip {
        display: block; }
  .dashboard-card .full-center {
    position: relative;
    height: calc(100% - 54px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 5;
    padding: 20px; }
  .dashboard-card .content {
    padding: 25px 20px; }
    .dashboard-card .content > *:last-child {
      margin-bottom: 0; }

.dashboard-large-info {
  text-align: center; }
  .dashboard-large-info .icon {
    line-height: 1;
    margin-bottom: 15px; }
    .dashboard-large-info .icon .if {
      font-size: 60px;
      line-height: 65px; }
  .dashboard-large-info .value {
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 1; }
  .dashboard-large-info .label {
    font-size: 14px;
    line-height: 1;
    font-weight: 300; }

.dashboard-line-info .line {
  display: flex;
  align-items: center;
  margin-bottom: 25px; }
  .dashboard-line-info .line:last-child {
    margin-bottom: 0; }

.dashboard-line-info .value {
  width: 50px;
  flex: 0 0 50px;
  overflow: hidden;
  margin-right: 10px;
  font-size: 18px;
  line-height: 1; }

.dashboard-line-info .icon {
  width: 32px;
  flex: 0 0 32px;
  text-align: center;
  margin-right: 15px;
  line-height: 1; }
  .dashboard-line-info .icon .if {
    font-size: 32px;
    line-height: 1; }

.dashboard-line-info .label {
  width: 100%;
  font-size: 14px;
  line-height: 1;
  font-weight: 300; }

.dashboard-line-info.large .value {
  width: 60px;
  flex: 0 0 60px; }

.dashboard-line-info.large .icon {
  width: 50px;
  flex: 0 0 50px; }
  .dashboard-line-info.large .icon .if {
    font-size: 50px;
    line-height: 1; }

.dashboard-chart-views .line {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px; }
  .dashboard-chart-views .line .label {
    font-size: 14px;
    line-height: 1;
    font-weight: 300;
    margin-top: -4px; }
  .dashboard-chart-views .line .value {
    font-size: 18px;
    line-height: 1;
    margin-left: 8px; }

.dashboard-chart-views .chart img {
  width: 100%; }

.dashboard-controls-top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }
  .dashboard-controls-top .buttons-list {
    width: 45%; }
    @media (max-width: 767px) {
      .dashboard-controls-top .buttons-list {
        width: 100%; } }

.dashboard-specialist-profiles {
  height: 200px;
  overflow: auto;
  padding-right: 10px;
  margin-bottom: 25px; }
  .dashboard-specialist-profiles::-webkit-scrollbar {
    width: 6px; }
  .dashboard-specialist-profiles::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: #e5e5e5; }
  .dashboard-specialist-profiles::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-image: linear-gradient(to top, #ff4444, #ea6728 45%, #ea6728); }
  .dashboard-specialist-profiles::-webkit-scrollbar-thumb:hover {
    background-image: linear-gradient(to top, #ff4444, #ea6728 45%, #ea6728); }
  .dashboard-specialist-profiles .line {
    padding: 10px 20px;
    border-bottom: 1px solid #d8d8d8;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 574px) {
      .dashboard-specialist-profiles .line {
        justify-content: center;
        flex-wrap: wrap; } }
  .dashboard-specialist-profiles .profile {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 574px) {
      .dashboard-specialist-profiles .profile {
        justify-content: center;
        margin-bottom: 15px; } }
    .dashboard-specialist-profiles .profile .image {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 15px;
      flex: 0 0 40px; }
      @media (max-width: 574px) {
        .dashboard-specialist-profiles .profile .image {
          margin-right: 0;
          margin-bottom: 15px; } }
      .dashboard-specialist-profiles .profile .image img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .dashboard-specialist-profiles .profile .name {
      font-size: 14px;
      line-height: 16px; }
      @media (max-width: 574px) {
        .dashboard-specialist-profiles .profile .name {
          text-align: center;
          width: 100%; } }
  .dashboard-specialist-profiles .specialization {
    font-size: 14px;
    color: #9d9d9d;
    line-height: 1; }
    @media (max-width: 574px) {
      .dashboard-specialist-profiles .specialization {
        width: 100%;
        text-align: center;
        margin-bottom: 15px; } }
  .dashboard-specialist-profiles .value {
    font-size: 18px;
    color: #000000;
    line-height: 1; }
    @media (max-width: 574px) {
      .dashboard-specialist-profiles .value {
        width: 100%;
        text-align: center; } }

.dashboard-total-right {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  .dashboard-total-right .label {
    font-size: 14px;
    line-height: 1;
    font-weight: 300; }
  .dashboard-total-right .value {
    font-size: 18px;
    line-height: 1;
    color: #000000;
    margin-left: 10px; }

.dashboard-see-profiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .dashboard-see-profiles .item {
    width: 45%; }
    @media (max-width: 574px) {
      .dashboard-see-profiles .item {
        width: 100%;
        margin-bottom: 30px; }
        .dashboard-see-profiles .item:last-child {
          margin-bottom: 0; } }
  .dashboard-see-profiles .total-count {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 10px; }
    .dashboard-see-profiles .total-count .if {
      font-size: 45px;
      line-height: 1; }
    .dashboard-see-profiles .total-count .value {
      font-size: 18px;
      margin-left: 70px;
      line-height: 1;
      position: relative;
      color: black; }
      .dashboard-see-profiles .total-count .value:before {
        content: '';
        display: block;
        width: 40px;
        height: 1px;
        background-color: #d8d8d8;
        position: absolute;
        top: 50%;
        left: -55px; }
  .dashboard-see-profiles .line {
    padding: 10px 0;
    border-bottom: 1px solid #d8d8d8;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .dashboard-see-profiles .line .label {
      font-size: 12px;
      line-height: 1; }
    .dashboard-see-profiles .line .value {
      font-size: 16px;
      color: black;
      line-height: 1; }

.dashboard-large-chart .chart-controls {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px; }
  .dashboard-large-chart .chart-controls .left,
  .dashboard-large-chart .chart-controls .right {
    width: 45%; }
    @media (max-width: 767px) {
      .dashboard-large-chart .chart-controls .left,
      .dashboard-large-chart .chart-controls .right {
        width: 100%; } }
  .dashboard-large-chart .chart-controls .right {
    display: flex;
    align-items: center; }
    .dashboard-large-chart .chart-controls .right .item {
      display: flex;
      align-items: center;
      margin-left: 40px; }
      .dashboard-large-chart .chart-controls .right .item:first-child {
        margin-left: 0; }
    .dashboard-large-chart .chart-controls .right .if {
      font-size: 30px;
      line-height: 1; }
      .dashboard-large-chart .chart-controls .right .if.if-woman-detail {
        color: #e74cd3; }
      .dashboard-large-chart .chart-controls .right .if.if-man-detail {
        color: #0096ff; }
    .dashboard-large-chart .chart-controls .right .value {
      font-size: 18px;
      margin-left: 70px;
      line-height: 1;
      position: relative;
      color: black; }
      .dashboard-large-chart .chart-controls .right .value:before {
        content: '';
        display: block;
        width: 40px;
        height: 1px;
        background-color: #d8d8d8;
        position: absolute;
        top: 50%;
        left: -55px; }
      @media (max-width: 1200px) and (min-width: 992px) {
        .dashboard-large-chart .chart-controls .right .value {
          margin-left: 40px; }
          .dashboard-large-chart .chart-controls .right .value:before {
            width: 20px;
            left: -30px; } }
      @media (max-width: 767px) {
        .dashboard-large-chart .chart-controls .right .value {
          margin-left: 40px; }
          .dashboard-large-chart .chart-controls .right .value:before {
            width: 20px;
            left: -30px; } }

.dashboard-large-chart .chart img {
  width: 100%; }

.dashboard-rating .total {
  text-align: center;
  margin-bottom: 50px; }

.dashboard-rating .label {
  font-size: 14px;
  line-height: 16px; }

.dashboard-rating .value {
  font-size: 18px;
  line-height: 1;
  color: black; }

.dashboard-rating .rating-cols-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 50px; }
  .dashboard-rating .rating-cols-wrapper .rating-col {
    width: 30%;
    text-align: center; }
    @media (max-width: 767px) {
      .dashboard-rating .rating-cols-wrapper .rating-col {
        width: 100%;
        margin-bottom: 30px; }
        .dashboard-rating .rating-cols-wrapper .rating-col:last-child {
          margin-bottom: 0; } }
    .dashboard-rating .rating-cols-wrapper .rating-col .stars {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px; }
      .dashboard-rating .rating-cols-wrapper .rating-col .stars .if {
        margin: 0 3px;
        line-height: 1;
        font-size: 15px; }

.dashboard-rating .rating-name {
  margin-bottom: 25px;
  font-size: 16px;
  line-height: 1; }

.dashboard-rating .rating-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px; }
  .dashboard-rating .rating-line .text {
    font-size: 16px;
    line-height: 1;
    width: 45px;
    flex: 0 0 45px; }
  .dashboard-rating .rating-line .value {
    width: 60px;
    flex: 0 0 60px; }
  .dashboard-rating .rating-line .line {
    margin: 0 10px;
    height: 16px;
    border-radius: 8px;
    background-color: #e6e6e6;
    width: 100%;
    position: relative; }
    .dashboard-rating .rating-line .line .fill {
      position: absolute;
      width: 0;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 8px;
      background-image: linear-gradient(to right, #fc4a4a, #ea6728);
      transition: all .3s ease; }

.buttons-list {
  display: flex;
  width: 100%;
  margin-bottom: 20px; }
  .buttons-list .button {
    border: solid 1px #dfdfdf;
    border-left: 0;
    background-color: #ffffff;
    font-size: 16px;
    line-height: 1;
    padding: 12px 5px;
    width: 100%;
    transition: all .3s ease;
    font-weight: 300; }
    .buttons-list .button:first-child {
      border-left: solid 1px #dfdfdf;
      border-radius: 8px 0 0 8px; }
    .buttons-list .button:last-child {
      border-radius: 0 8px 8px 0; }
    .buttons-list .button:hover {
      background-color: #dfdfdf; }
    .buttons-list .button.active {
      background-image: linear-gradient(18deg, #fe4543, #ea6728);
      color: #ffffff;
      border: none; }
  .buttons-list.buttons-small .button {
    padding: 7px 5px;
    font-size: 14px; }
    .buttons-list.buttons-small .button:first-child {
      border-radius: 6px 0 0 6px; }
    .buttons-list.buttons-small .button:last-child {
      border-radius: 0 6px 6px 0; }

.load-content-spinner {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 50;
  padding-top: 80px;
  padding-left: 300px; }
  @media (max-width: 991px) {
    .load-content-spinner {
      padding-top: 60px;
      padding-left: 0; } }
  .load-content-spinner img {
    transform-origin: 50% 50%;
    animation: loadingSpinner 1s linear infinite;
    margin-top: 80px;
    margin-left: 300px; }
    @media (max-width: 991px) {
      .load-content-spinner img {
        margin-top: 60px;
        margin-left: 0; } }

.load-local-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 50; }
  .load-local-spinner.spinner-static {
    position: static;
    transform: none;
    margin: 30px;
    text-align: center; }
  .load-local-spinner img {
    transform-origin: 50% 50%;
    animation: loadingSpinner 1s linear infinite; }

.filters-sidebar {
  width: 190px;
  position: relative; }
  .filters-sidebar h4 {
    line-height: 1;
    margin-bottom: 20px; }
  .filters-sidebar label {
    color: #404040;
    font-family: "Gotham Pro", sans-serif;
    font-size: 14px;
    line-height: 16px; }
  .filters-sidebar .filters-wrapper {
    margin-bottom: 20px; }
  .filters-sidebar:after {
    content: '';
    display: block;
    width: 1px;
    height: calc(100% + 30px);
    background-color: #dfdfdf;
    position: absolute;
    right: 0;
    top: -15px; }
    @media (max-width: 1100px) {
      .filters-sidebar:after {
        content: none; } }
  @media (max-width: 1100px) {
    .filters-sidebar {
      width: 100%;
      margin-bottom: 40px;
      display: none; } }

.filters-result {
  width: calc(100% - 220px); }
  @media (max-width: 1100px) {
    .filters-result {
      width: 100%; } }

.filters-result-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
  align-items: flex-end; }
  .filters-result-head h4 {
    font-weight: 500;
    line-height: 1;
    margin-bottom: 0; }
  .filters-result-head .left {
    display: flex;
    align-items: flex-end; }
    @media (max-width: 1200px) {
      .filters-result-head .left {
        display: block; } }
  .filters-result-head .info-results {
    margin-left: 10px;
    line-height: 1;
    margin-bottom: -1px; }
    @media (max-width: 1200px) {
      .filters-result-head .info-results {
        margin-left: 0;
        margin-top: 10px; } }
    .filters-result-head .info-results span {
      font-weight: 500; }
  .filters-result-head .right {
    color: #404040;
    font-family: "Gotham Pro", sans-serif;
    font-size: 16px;
    line-height: 1; }
  @media (max-width: 1400px) {
    .filters-result-head {
      flex-wrap: wrap; }
      .filters-result-head .left,
      .filters-result-head .right {
        width: 100%; }
      .filters-result-head .left {
        margin-bottom: 30px; } }

.add-photos-multiply-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px; }
  .add-photos-multiply-wrapper > div {
    display: flex;
    flex-wrap: wrap; }
  .add-photos-multiply-wrapper .photo,
  .add-photos-multiply-wrapper .photo-add {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 1px rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    margin-bottom: 20px;
    margin-left: 20px;
    border: none; }
  .add-photos-multiply-wrapper .photo {
    position: relative; }
    .add-photos-multiply-wrapper .photo img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover; }
    .add-photos-multiply-wrapper .photo .remove-photo {
      position: absolute;
      width: 31px;
      height: 31px;
      box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 1px rgba(0, 0, 0, 0.16);
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      border-radius: 50%;
      right: -10px;
      top: -10px;
      cursor: pointer;
      transition: all .3s ease; }
      .add-photos-multiply-wrapper .photo .remove-photo .if {
        font-size: 17px;
        line-height: 1; }
      .add-photos-multiply-wrapper .photo .remove-photo:hover {
        background-color: #ea6728;
        color: white; }
  .add-photos-multiply-wrapper .photo-add {
    cursor: pointer;
    transition: all .3s ease;
    padding: 0; }
    .add-photos-multiply-wrapper .photo-add .if {
      font-size: 40px;
      line-height: 1;
      color: #ea6728; }
    .add-photos-multiply-wrapper .photo-add:hover {
      box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.3); }

.cloud-navigation {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-bottom: 20px; }
  .cloud-navigation .name {
    margin-left: 10px;
    color: #404040;
    font-family: "Gotham Pro", sans-serif;
    font-size: 16px;
    line-height: 1;
    margin-bottom: 10px;
    margin-right: 10px; }
  .cloud-navigation .cloud {
    color: #ffffff;
    font-family: "Gotham Pro", sans-serif;
    font-size: 12px;
    padding: 7px 18px;
    border-radius: 4px;
    background-color: #ea6728;
    transition: all .3s ease;
    text-decoration: none;
    margin-left: 10px;
    margin-bottom: 10px; }
    .cloud-navigation .cloud:hover {
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3), 0 1px 3px rgba(0, 0, 0, 0.2); }

.doctor-month-calendar-head {
  display: flex; }
  .doctor-month-calendar-head-item {
    color: #404040;
    font-size: 18px;
    line-height: 1;
    width: 14.29%;
    text-align: center;
    padding: 20px 0; }
    @media (max-width: 1439px) {
      .doctor-month-calendar-head-item {
        font-size: 14px;
        padding: 15px 0; } }

.doctor-month-calendar-body {
  display: flex;
  flex-wrap: wrap; }

.doctor-month-calendar-day {
  width: 14.36%;
  margin-left: -1px;
  margin-top: -1px;
  height: 100px;
  border: 1px solid #dedede;
  background-color: #ffffff;
  cursor: pointer;
  position: relative;
  transition: all .3s ease; }
  .doctor-month-calendar-day:hover {
    background-color: #f1f1f1; }
  .doctor-month-calendar-day.current {
    background-color: #ffebe1; }
  .doctor-month-calendar-day.disabled {
    cursor: not-allowed; }
    .doctor-month-calendar-day.disabled > * {
      opacity: 0.5; }
  .doctor-month-calendar-day-number {
    color: #404040;
    font-size: 24px;
    line-height: 1;
    position: absolute;
    right: 15px;
    top: 15px; }
    @media (max-width: 1649px) {
      .doctor-month-calendar-day-number {
        font-size: 20px; } }
  .doctor-month-calendar-day-type {
    position: absolute;
    left: 7px;
    top: 15px; }
    .doctor-month-calendar-day-type .if {
      font-size: 20px;
      line-height: 1;
      display: block;
      color: #ea6728; }
      @media (max-width: 1649px) {
        .doctor-month-calendar-day-type .if {
          font-size: 14px; } }
  .doctor-month-calendar-day-legend {
    position: absolute;
    left: 10px;
    bottom: 10px;
    display: flex;
    align-items: center; }
    @media (max-width: 1649px) {
      .doctor-month-calendar-day-legend {
        left: 5px;
        bottom: 5px; } }
    @media (max-width: 1649px) {
      .doctor-month-calendar-day-legend .legend-icon {
        font-size: 12px;
        padding: 2px 3px;
        margin-right: 2.5px; } }

/*
  Pets
*/
.card-pet {
  border-radius: 10px;
  box-shadow: 1px 1px 7px 1px rgba(58, 68, 86, 0.3);
  background-color: #ffffff;
  position: relative;
  height: 100%;
  cursor: pointer;
  transition: all .5s ease; }
  .card-pet.is-dead {
    opacity: .5; }
  .card-pet .image {
    border-radius: 10px 10px 0 0;
    overflow: hidden; }
    .card-pet .image img {
      width: 100%; }
  .card-pet .caption {
    padding: 20px 25px; }
    .card-pet .caption .line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 18px; }
      .card-pet .caption .line:last-child {
        margin-bottom: 0; }
  .card-pet .pet-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 1; }
  .card-pet .pet-age,
  .card-pet .pet-status,
  .card-pet .pet-status-value {
    font-size: 14px;
    line-height: 1; }
  .card-pet .pet-status-value {
    opacity: .7; }
  .card-pet:hover {
    box-shadow: 0 0 30px 1px rgba(58, 68, 86, 0.3); }

.pet-photo-slider-wrapper {
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.18), 0 0 3px 1px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  margin: 0 auto 60px;
  position: relative; }
  .pet-photo-slider-wrapper .item {
    display: flex;
    position: relative; }
    .pet-photo-slider-wrapper .item img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .pet-photo-slider-wrapper .nav-next,
  .pet-photo-slider-wrapper .nav-prev {
    width: 30px;
    height: 30px;
    box-shadow: 0 3px 7px 1px rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    margin-top: -15px;
    cursor: pointer;
    transition: all .3s ease; }
    .pet-photo-slider-wrapper .nav-next .if,
    .pet-photo-slider-wrapper .nav-prev .if {
      font-size: 16px;
      line-height: 1; }
    .pet-photo-slider-wrapper .nav-next:hover,
    .pet-photo-slider-wrapper .nav-prev:hover {
      background-color: #ea6728;
      color: white; }
  .pet-photo-slider-wrapper .nav-next {
    right: 30px; }
    @media (max-width: 575px) {
      .pet-photo-slider-wrapper .nav-next {
        right: 10px; } }
  .pet-photo-slider-wrapper .nav-prev {
    left: 30px; }
    @media (max-width: 575px) {
      .pet-photo-slider-wrapper .nav-prev {
        left: 10px; } }
  .pet-photo-slider-wrapper .dots {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 25px; }
    @media (max-width: 575px) {
      .pet-photo-slider-wrapper .dots {
        bottom: 10px; } }
    .pet-photo-slider-wrapper .dots .dot {
      width: 8px;
      height: 8px;
      box-shadow: 0 3px 7px 1px rgba(0, 0, 0, 0.25);
      background-color: #ffffff;
      border-radius: 50%;
      margin-left: 8px;
      transition: all .3s ease;
      cursor: pointer; }
      .pet-photo-slider-wrapper .dots .dot:first-child {
        margin-left: 0; }
      .pet-photo-slider-wrapper .dots .dot.active, .pet-photo-slider-wrapper .dots .dot:hover {
        color: #ea6728; }

.pet-photos-multiply-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px; }
  .pet-photos-multiply-wrapper .pet-photo,
  .pet-photos-multiply-wrapper .pet-photo-add {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 1px rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    margin-bottom: 20px;
    margin-left: 20px;
    border: none; }
  .pet-photos-multiply-wrapper .pet-photo {
    position: relative; }
    .pet-photos-multiply-wrapper .pet-photo img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover; }
    .pet-photos-multiply-wrapper .pet-photo .remove-pet-photo {
      position: absolute;
      width: 31px;
      height: 31px;
      box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 1px rgba(0, 0, 0, 0.16);
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      border-radius: 50%;
      right: -10px;
      top: -10px;
      cursor: pointer;
      transition: all .3s ease; }
      .pet-photos-multiply-wrapper .pet-photo .remove-pet-photo .if {
        font-size: 17px;
        line-height: 1; }
      .pet-photos-multiply-wrapper .pet-photo .remove-pet-photo:hover {
        background-color: #ea6728;
        color: white; }
  .pet-photos-multiply-wrapper .pet-photo-add {
    cursor: pointer;
    transition: all .3s ease;
    padding: 0; }
    .pet-photos-multiply-wrapper .pet-photo-add .if {
      font-size: 40px;
      line-height: 1;
      color: #ea6728; }
    .pet-photos-multiply-wrapper .pet-photo-add:hover {
      box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.3); }

/*
  Account
*/
.owner-pets-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .owner-pets-list .owner-pet-item {
    width: calc(33.33333% - 10px);
    border: 1px solid #ced4da;
    padding: 30px;
    background-color: #e9ecef;
    margin-bottom: 20px; }
  .owner-pets-list .owner-pet-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 30px;
    border: 2px solid #ced4da; }
    .owner-pets-list .owner-pet-image img {
      width: 100%; }
  .owner-pets-list .owner-pet-name {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 20px; }

.account-sidebar {
  width: 270px;
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 1px 1px 7px 1px rgba(58, 68, 86, 0.3);
  background-color: #ffffff; }
  .account-sidebar .account-avatar {
    padding: 40px 50px; }
  .account-sidebar .show-mobile {
    display: none; }
  .account-sidebar + .main-container {
    width: calc(100% - 300px); }
  @media (max-width: 991px) {
    .account-sidebar {
      width: 100%; }
      .account-sidebar .show-mobile {
        display: block;
        margin: 0 auto 30px; }
      .account-sidebar + .main-container {
        width: 100%; } }

.account-sidebar-navigation li {
  border-top: 1px solid #d8d8d8; }
  .account-sidebar-navigation li:last-child a {
    border-radius: 0 0 10px 10px; }

.account-sidebar-navigation a {
  display: flex;
  align-items: center;
  padding: 12px 23px;
  color: #404040;
  transition: all .3s ease; }
  .account-sidebar-navigation a.active {
    background-color: #f1f1f1; }
  .account-sidebar-navigation a .if {
    font-size: 20px;
    margin-right: 22px;
    color: #ea6728;
    line-height: 1;
    display: block;
    width: 21px;
    text-align: center; }
  .account-sidebar-navigation a .text {
    font-size: 14px;
    line-height: 1; }
  .account-sidebar-navigation a:hover {
    text-decoration: none;
    color: #404040;
    background-color: #f1f1f1; }

@media (max-width: 991px) {
  .account-sidebar-navigation {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1); }
    .account-sidebar-navigation.show {
      max-height: 10000px;
      transition: max-height 1s ease-in-out; } }

.account-avatar .image {
  width: 170px;
  height: 170px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 1px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 20px;
  cursor: pointer; }
  .account-avatar .image img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.account-avatar.account-clinic .image {
  width: 200px;
  height: 200px; }
  @media (max-width: 767px) {
    .account-avatar.account-clinic .image {
      width: 170px;
      height: 170px; } }

.change-photo-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 40px; }
  @media (max-width: 767px) {
    .change-photo-wrapper {
      justify-content: center;
      padding-bottom: 10px; } }

.preview-photo {
  height: 142px;
  width: 142px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.16), 0 4px 15px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  overflow: hidden;
  position: relative; }
  .preview-photo img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .preview-photo .new-photo {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  @media (max-width: 767px) {
    .preview-photo {
      margin-bottom: 20px; } }

.upload-photo-wrapper {
  width: 100%;
  max-width: 330px;
  height: 110px;
  border-radius: 8px;
  box-shadow: inset 2px 2px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: #f1f1f1;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #404040;
  font-weight: 300;
  margin-left: 35px;
  cursor: pointer;
  position: relative; }
  @media (max-width: 767px) {
    .upload-photo-wrapper {
      max-width: none;
      margin: 0; } }
  .upload-photo-wrapper > div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%; }
    .upload-photo-wrapper > div.file-info {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px; }
      .upload-photo-wrapper > div.file-info .if {
        font-size: 20px;
        color: black; }

.cropper-wrapper {
  width: 100%; }
  .cropper-wrapper .cropper-cropping {
    border: none !important; }
  .cropper-wrapper canvas {
    width: 100%;
    margin-top: 40px; }

.account-email-verification {
  border-radius: 8px;
  background-color: #f1f1f1;
  padding: 14px 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px; }
  .account-email-verification .text {
    font-size: 16px;
    line-height: 1;
    margin: 5px 0; }

/*
  Clinic
*/
.card-clinic {
  border-radius: 10px;
  box-shadow: 1px 1px 7px 1px rgba(58, 68, 86, 0.3);
  background-color: #ffffff;
  position: relative;
  height: 100%;
  cursor: pointer;
  transition: all .5s ease; }
  .card-clinic .image {
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    height: 400px; }
    .card-clinic .image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .card-clinic .caption {
    padding: 20px 25px; }
  .card-clinic .clinic-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 1; }
  .card-clinic:hover {
    box-shadow: 0 0 30px 1px rgba(58, 68, 86, 0.3); }

.clinic-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px; }
  .clinic-list .clinic-item {
    width: calc(33.33333% - 10px);
    border: 1px solid #ced4da;
    padding: 30px 30px 68px;
    background-color: #e9ecef;
    margin-bottom: 10px;
    margin-left: 10px;
    text-align: center;
    position: relative;
    border-radius: 5px; }
    .clinic-list .clinic-item .name {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px; }
    .clinic-list .clinic-item .phone {
      margin-bottom: 25px; }
    .clinic-list .clinic-item .btn {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%); }
    .clinic-list .clinic-item.add-clinic {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      min-height: 186px;
      padding: 30px;
      cursor: pointer;
      opacity: .6;
      transition: all .3s ease; }
      .clinic-list .clinic-item.add-clinic:hover {
        opacity: 1; }
      .clinic-list .clinic-item.add-clinic .add-icon {
        position: relative;
        width: 100%;
        height: 30px;
        margin-bottom: 20px; }
        .clinic-list .clinic-item.add-clinic .add-icon:after, .clinic-list .clinic-item.add-clinic .add-icon:before {
          content: "";
          display: block;
          background-color: #727272;
          position: absolute;
          top: 50%;
          left: 50%; }
        .clinic-list .clinic-item.add-clinic .add-icon:after {
          width: 8px;
          height: 30px;
          margin-left: -4px;
          margin-top: -15px; }
        .clinic-list .clinic-item.add-clinic .add-icon:before {
          width: 30px;
          height: 8px;
          margin-left: -15px;
          margin-top: -4px; }

.form-group-array.with-checkboxes .item {
  flex-wrap: wrap;
  position: relative;
  border-top: 1px solid #ced4da;
  padding-top: 30px; }
  .form-group-array.with-checkboxes .item:first-child {
    border: none;
    padding-top: 0; }

.form-group-array.with-checkboxes .ng-select {
  margin-bottom: 20px; }

.form-group-array.with-checkboxes .clinic-actions-checkboxes {
  width: calc(100% - 50px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .form-group-array.with-checkboxes .clinic-actions-checkboxes label {
    padding: 0;
    background: none;
    line-height: 1;
    height: auto;
    white-space: normal;
    overflow: visible;
    width: 33.3333333%;
    margin-bottom: 20px;
    font-weight: 400; }
    .form-group-array.with-checkboxes .clinic-actions-checkboxes label .custom-checkbox {
      margin-top: -3px; }

.form-group-array.with-checkboxes .remove-field {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%); }

.form-group-array .form-group {
  margin-bottom: 0; }

.clinic-schedule-item {
  display: flex;
  align-items: center; }
  .clinic-schedule-item .time {
    display: flex;
    align-items: center;
    margin-right: 15px; }
    .clinic-schedule-item .time > span {
      margin-right: 15px; }
    .clinic-schedule-item .time .mat-form-field {
      width: 65px; }
  .clinic-schedule-item .mat-checkbox {
    margin-left: 35px; }
    .clinic-schedule-item .mat-checkbox .mat-checkbox-layout {
      margin-bottom: 0; }
  .clinic-schedule-item .name {
    width: 165px;
    font-size: 16px;
    font-weight: 500;
    color: #404040; }
  .clinic-schedule-item .text-time {
    font-size: 16px;
    line-height: 38px;
    color: #404040; }

.specialist-in-clinic-list > [class*="col-"] {
  margin-bottom: 30px; }

.card-specialist-in-clinic {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  background-color: #ffffff;
  padding: 30px 30px 90px;
  text-align: center;
  transition: all .7s ease;
  height: 100%;
  position: relative; }
  .card-specialist-in-clinic:hover {
    box-shadow: 0 0 30px 1px rgba(58, 68, 86, 0.3); }
  .card-specialist-in-clinic .image {
    border: solid 1px #ea6728;
    background-color: #d8d8d8;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 30px; }
    .card-specialist-in-clinic .image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .card-specialist-in-clinic .name {
    color: #404040;
    font-family: "Gotham Pro", sans-serif;
    font-size: 20px;
    line-height: 1;
    margin-bottom: 10px; }
  .card-specialist-in-clinic .post {
    color: #404040;
    font-size: 16px;
    line-height: 1; }
  .card-specialist-in-clinic .btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px; }
  .card-specialist-in-clinic .status {
    color: #ea6728;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    margin-top: 25px; }
    .card-specialist-in-clinic .status-dismiss {
      color: #fe4543; }
  .card-specialist-in-clinic .more-popup {
    display: inline-block; }

.price-direction-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .price-direction-title .if {
    font-size: 20px;
    line-height: 1;
    color: #ea6728;
    margin-left: 15px;
    transition: all .3s ease; }
    .price-direction-title .if:hover {
      color: #fe4543; }

/*
  Pets
*/
.card-pet {
  border-radius: 10px;
  box-shadow: 1px 1px 7px 1px rgba(58, 68, 86, 0.3);
  background-color: #ffffff;
  position: relative;
  height: 100%;
  cursor: pointer;
  transition: all .5s ease; }
  .card-pet.is-dead {
    opacity: .5; }
  .card-pet .image {
    border-radius: 10px 10px 0 0;
    overflow: hidden; }
    .card-pet .image img {
      width: 100%; }
  .card-pet .caption {
    padding: 20px 25px; }
    .card-pet .caption .line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 18px; }
      .card-pet .caption .line:last-child {
        margin-bottom: 0; }
  .card-pet .pet-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 1; }
  .card-pet .pet-age,
  .card-pet .pet-status,
  .card-pet .pet-status-value {
    font-size: 14px;
    line-height: 1; }
  .card-pet .pet-status-value {
    opacity: .7; }
  .card-pet:hover {
    box-shadow: 0 0 30px 1px rgba(58, 68, 86, 0.3); }

.pet-photo-slider-wrapper {
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.18), 0 0 3px 1px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  margin: 0 auto 60px;
  position: relative; }
  .pet-photo-slider-wrapper .item {
    display: flex;
    position: relative; }
    .pet-photo-slider-wrapper .item img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .pet-photo-slider-wrapper .nav-next,
  .pet-photo-slider-wrapper .nav-prev {
    width: 30px;
    height: 30px;
    box-shadow: 0 3px 7px 1px rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    margin-top: -15px;
    cursor: pointer;
    transition: all .3s ease; }
    .pet-photo-slider-wrapper .nav-next .if,
    .pet-photo-slider-wrapper .nav-prev .if {
      font-size: 16px;
      line-height: 1; }
    .pet-photo-slider-wrapper .nav-next:hover,
    .pet-photo-slider-wrapper .nav-prev:hover {
      background-color: #ea6728;
      color: white; }
  .pet-photo-slider-wrapper .nav-next {
    right: 30px; }
    @media (max-width: 575px) {
      .pet-photo-slider-wrapper .nav-next {
        right: 10px; } }
  .pet-photo-slider-wrapper .nav-prev {
    left: 30px; }
    @media (max-width: 575px) {
      .pet-photo-slider-wrapper .nav-prev {
        left: 10px; } }
  .pet-photo-slider-wrapper .dots {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 25px; }
    @media (max-width: 575px) {
      .pet-photo-slider-wrapper .dots {
        bottom: 10px; } }
    .pet-photo-slider-wrapper .dots .dot {
      width: 8px;
      height: 8px;
      box-shadow: 0 3px 7px 1px rgba(0, 0, 0, 0.25);
      background-color: #ffffff;
      border-radius: 50%;
      margin-left: 8px;
      transition: all .3s ease;
      cursor: pointer; }
      .pet-photo-slider-wrapper .dots .dot:first-child {
        margin-left: 0; }
      .pet-photo-slider-wrapper .dots .dot.active, .pet-photo-slider-wrapper .dots .dot:hover {
        color: #ea6728; }

.pet-photos-multiply-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px; }
  .pet-photos-multiply-wrapper .pet-photo,
  .pet-photos-multiply-wrapper .pet-photo-add {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 1px rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    margin-bottom: 20px;
    margin-left: 20px;
    border: none; }
  .pet-photos-multiply-wrapper .pet-photo {
    position: relative; }
    .pet-photos-multiply-wrapper .pet-photo img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover; }
    .pet-photos-multiply-wrapper .pet-photo .remove-pet-photo {
      position: absolute;
      width: 31px;
      height: 31px;
      box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 1px rgba(0, 0, 0, 0.16);
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      border-radius: 50%;
      right: -10px;
      top: -10px;
      cursor: pointer;
      transition: all .3s ease; }
      .pet-photos-multiply-wrapper .pet-photo .remove-pet-photo .if {
        font-size: 17px;
        line-height: 1; }
      .pet-photos-multiply-wrapper .pet-photo .remove-pet-photo:hover {
        background-color: #ea6728;
        color: white; }
  .pet-photos-multiply-wrapper .pet-photo-add {
    cursor: pointer;
    transition: all .3s ease;
    padding: 0; }
    .pet-photos-multiply-wrapper .pet-photo-add .if {
      font-size: 40px;
      line-height: 1;
      color: #ea6728; }
    .pet-photos-multiply-wrapper .pet-photo-add:hover {
      box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.3); }

/*
  Schedules
*/
.time-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px; }
  @media (max-width: 1350px) {
    .time-header {
      flex-wrap: wrap; } }
  .time-header > * {
    margin-bottom: 30px; }
  .time-header .toggle-date {
    display: flex; }
    .time-header .toggle-date button {
      height: 35px;
      border: solid 1px #dfdfdf;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      font-size: 14px;
      line-height: 1;
      color: #404040;
      transition: all .3s ease; }
      .time-header .toggle-date button .if {
        font-size: 18px;
        line-height: 1;
        color: #484848;
        transition: all .3s ease; }
      .time-header .toggle-date button.prev-date {
        border-radius: 6px 0 0 6px; }
      .time-header .toggle-date button.today {
        margin-left: -1px; }
        .time-header .toggle-date button.today.disabled {
          opacity: .5;
          cursor: not-allowed; }
      .time-header .toggle-date button.next-date {
        border-radius: 0 6px 6px 0;
        margin-left: -1px; }
      .time-header .toggle-date button:hover:not(.disabled) {
        background-color: #ea6728;
        color: white;
        border-color: #ea6728; }
        .time-header .toggle-date button:hover:not(.disabled) .if {
          color: white; }
  .time-header .text-date {
    font-size: 24px;
    text-align: center;
    color: #404040;
    line-height: 1; }
  .time-header .toggle-long-time {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
    .time-header .toggle-long-time button {
      height: 35px;
      border: solid 1px #dfdfdf;
      background-color: #ffffff;
      font-size: 14px;
      color: #404040;
      line-height: 35px;
      text-align: center;
      padding: 0 16px;
      margin-left: -1px; }
      .time-header .toggle-long-time button:first-child {
        border-radius: 6px 0 0 6px;
        margin-left: 0; }
      .time-header .toggle-long-time button:last-child {
        border-radius: 0 6px 6px 0; }
      .time-header .toggle-long-time button.active, .time-header .toggle-long-time button:hover {
        background-image: linear-gradient(17deg, #fc4a4a, #ea6728);
        color: white;
        background-size: cover;
        background-position: -100%; }

.schedule-record {
  border-top: 2px solid #ea6728;
  padding-top: 20px;
  overflow-x: auto;
  width: 100%; }
  .schedule-record .schedule-head {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #000000; }
    .schedule-record .schedule-head .item {
      flex: 0 0 90px;
      width: 90px;
      padding: 0 0 10px 20px;
      border-bottom: 1px solid #d8d8d8; }
      .schedule-record .schedule-head .item:first-child {
        width: 300px;
        flex: 0 0 300px;
        padding: 15px 0 16px 10px; }
        @media (max-width: 574px) {
          .schedule-record .schedule-head .item:first-child {
            width: 230px;
            flex: 0 0 230px; } }
    .schedule-record .schedule-head span {
      display: block;
      color: #ea6728;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%; }
  .schedule-record .schedule-row {
    display: flex;
    align-items: center; }
    .schedule-record .schedule-row .item {
      padding: 12px 0 12px 20px;
      flex: 0 0 90px;
      border-bottom: 1px solid #d8d8d8; }
      .schedule-record .schedule-row .item:first-child {
        padding-left: 0;
        flex: 0 0 300px; }
        @media (max-width: 574px) {
          .schedule-record .schedule-row .item:first-child {
            flex: 0 0 230px; } }
      .schedule-record .schedule-row .item:last-child {
        padding-right: 10px; }
  .schedule-record .record-item {
    width: 70px;
    height: 50px;
    border-radius: 6px;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease; }
    .schedule-record .record-item:hover:not([disabled]) {
      background-color: #ea6728;
      color: white; }
    .schedule-record .record-item[disabled] {
      cursor: not-allowed;
      opacity: .3; }
  .schedule-record .specialist-block {
    width: 300px; }
    @media (max-width: 574px) {
      .schedule-record .specialist-block {
        width: 230px; } }

.record-info-time {
  margin-bottom: 20px; }
  .record-info-time .date {
    font-weight: 500;
    font-size: 18px;
    color: #404040;
    text-align: center;
    margin-bottom: 10px; }
  .record-info-time .time {
    opacity: 0.5;
    font-size: 18px;
    color: #000000;
    text-align: center; }

.record-info-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .record-info-list > div {
    position: relative;
    padding: 20px 0;
    width: 100%;
    border-bottom: 1px solid #d8d8d8; }
    .record-info-list > div:first-child {
      padding-top: 0; }
    .record-info-list > div:last-child {
      padding-right: 0;
      border-bottom: none; }
      .record-info-list > div:last-child:after {
        content: none; }
    .record-info-list > div:after {
      content: '';
      display: block;
      width: 1px;
      height: 60px;
      background-color: #d8d8d8;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -30px; }
    @media (max-width: 991px) {
      .record-info-list > div {
        padding: 0;
        margin-left: 30px;
        margin-bottom: 30px; }
        .record-info-list > div:after {
          content: none; } }
  @media (max-width: 991px) {
    .record-info-list {
      flex-wrap: wrap;
      margin-left: -30px; } }

.edit-appointment-info-list {
  display: flex;
  align-items: center;
  margin-bottom: 30px; }
  .edit-appointment-info-list > div {
    position: relative;
    padding: 0 30px; }
    .edit-appointment-info-list > div:first-child {
      padding-left: 0; }
    .edit-appointment-info-list > div:last-child {
      padding-right: 0; }
    @media (max-width: 991px) {
      .edit-appointment-info-list > div {
        padding: 0;
        margin-left: 30px;
        margin-bottom: 30px; } }
  @media (max-width: 991px) {
    .edit-appointment-info-list {
      margin-bottom: 0;
      margin-left: -30px;
      flex-wrap: wrap; } }

.appointment-small-price-info .item {
  color: #404040;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 8px;
  text-align: left; }
  .appointment-small-price-info .item:last-child {
    margin-bottom: 0; }
  .appointment-small-price-info .item span {
    color: #ea6728; }

.edit-appointment-controls {
  display: flex;
  align-items: center; }
  .edit-appointment-controls .btn {
    margin-top: 0; }
  .edit-appointment-controls .mat-checkbox-layout {
    margin-bottom: 0; }
  .edit-appointment-controls > * {
    margin-left: 30px; }
    .edit-appointment-controls > *:first-child {
      margin-left: 0; }
    @media (max-width: 991px) {
      .edit-appointment-controls > * {
        margin-bottom: 30px; }
        .edit-appointment-controls > *:first-child {
          margin-left: 30px; } }
  @media (max-width: 991px) {
    .edit-appointment-controls {
      flex-wrap: wrap;
      margin-left: -30px; }
      .edit-appointment-controls + hr {
        margin-top: 0; } }

.mat-dialog-container .time-small-info {
  text-align: left; }

.time-small-info .date {
  font-size: 16px;
  line-height: 1;
  color: #404040; }

.time-small-info .time {
  font-size: 16px;
  color: #000000;
  opacity: .5; }

.schedule-clinic-specialist {
  margin-left: -70px;
  margin-right: -70px;
  width: calc(100% + 140px);
  overflow-x: auto; }
  @media (max-width: 767px) {
    .schedule-clinic-specialist {
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-right: -20px; } }
  .schedule-clinic-specialist table {
    min-width: 1000px;
    width: calc(100% - 1px); }
  .schedule-clinic-specialist th {
    width: 13.32%;
    text-align: center;
    padding: 20px 0; }
    .schedule-clinic-specialist th.today {
      background-color: #ea6728; }
      .schedule-clinic-specialist th.today .month-date,
      .schedule-clinic-specialist th.today .week-day {
        color: white; }
    .schedule-clinic-specialist th .week-day {
      font-size: 16px;
      text-align: center;
      color: #404040;
      line-height: 1;
      margin-bottom: 10px;
      font-weight: 400; }
    .schedule-clinic-specialist th .month-date {
      font-size: 14px;
      line-height: 1;
      color: #a6a6a6;
      display: block;
      font-weight: 400; }
    .schedule-clinic-specialist th:first-child {
      width: 70px;
      flex: 0 0 70px; }
  .schedule-clinic-specialist td {
    width: 13.32%;
    border: solid 1px #d5d5d5;
    height: 40px;
    position: relative; }
    .schedule-clinic-specialist td:first-child {
      width: 70px;
      flex: 0 0 70px; }
    .schedule-clinic-specialist td .time {
      font-size: 14px;
      text-align: center;
      color: #404040;
      line-height: 1;
      vertical-align: middle; }
  .schedule-clinic-specialist tr:nth-child(odd) td {
    background-color: #f3f3f3; }
  .schedule-clinic-specialist .appointment-item {
    position: absolute;
    width: calc(100% - 8px);
    left: 4px;
    top: 4px;
    border-radius: 2px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25), 0 0 2px 1px rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    padding: 22px 10px;
    z-index: 10;
    cursor: pointer;
    transition: all .3s ease; }
    .schedule-clinic-specialist .appointment-item:hover {
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.35), 0 0 2px 1px rgba(0, 0, 0, 0.08); }
    .schedule-clinic-specialist .appointment-item:before {
      content: '';
      height: 12px;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 2px 2px 0 0; }
    .schedule-clinic-specialist .appointment-item.appointment-type-01:before {
      background-color: #10d78c; }
    .schedule-clinic-specialist .appointment-item.appointment-type-02:before {
      background-color: #28b4ea; }
    .schedule-clinic-specialist .appointment-item.appointment-type-03:before {
      background-color: #284374; }
    .schedule-clinic-specialist .appointment-item .appointment-type {
      font-size: 14px;
      line-height: 1;
      color: #ea6728;
      margin-bottom: 10px; }
    .schedule-clinic-specialist .appointment-item .appointment-name {
      font-size: 14px;
      line-height: 16px;
      color: #404040;
      margin-bottom: 10px; }
    .schedule-clinic-specialist .appointment-item .appointment-time {
      font-size: 12px;
      line-height: 16px;
      color: #767676; }

.schedule-legend {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 45px; }
  .schedule-legend .legend-item {
    width: 33.33333%;
    display: flex;
    align-items: center;
    margin-bottom: 14px; }
    .schedule-legend .legend-item .icon {
      border-radius: 25px;
      font-size: 14px;
      color: #ffffff;
      line-height: 1;
      padding: 3px 6px;
      min-width: 20px;
      text-align: center;
      margin-right: 10px; }
    .schedule-legend .legend-item .name {
      font-size: 16px;
      line-height: 1;
      color: #404040; }
    .schedule-legend .legend-item .if {
      font-size: 20px;
      color: #ea6728;
      line-height: 1;
      margin-right: 10px;
      padding: 0 5px; }
    .schedule-legend .legend-item.specialization-type-01 .icon {
      background-color: #616161; }
    .schedule-legend .legend-item.specialization-type-02 .icon {
      background-color: #10d78c; }
    .schedule-legend .legend-item.specialization-type-03 .icon {
      background-color: #284374; }
    .schedule-legend .legend-item.specialization-type-04 .icon {
      background-color: #28b4ea; }
    .schedule-legend .legend-item.specialization-type-05 .icon {
      background-color: #ea28d5; }

.legend-icon {
  border-radius: 25px;
  font-size: 14px;
  color: #ffffff;
  line-height: 1;
  padding: 3px 6px;
  min-width: 20px;
  text-align: center;
  margin-right: 5px; }
  .legend-icon:last-child {
    margin-right: 0; }
  .legend-icon.specialization-type-01 {
    background-color: #616161; }
  .legend-icon.specialization-type-02 {
    background-color: #10d78c; }
  .legend-icon.specialization-type-03 {
    background-color: #284374; }
  .legend-icon.specialization-type-04 {
    background-color: #28b4ea; }
  .legend-icon.specialization-type-05 {
    background-color: #ea28d5; }

.specialist-schedule-work-day .appointment-item {
  margin-bottom: 30px; }

.specialist-schedule-work-day .appointment-head-info {
  display: flex;
  align-items: center;
  margin-bottom: 15px; }
  .specialist-schedule-work-day .appointment-head-info .time {
    font-size: 20px;
    color: #404040;
    line-height: 1;
    margin-right: 20px; }
  .specialist-schedule-work-day .appointment-head-info .status {
    font-size: 16px;
    line-height: 1;
    font-weight: 400; }
    .specialist-schedule-work-day .appointment-head-info .status .if {
      color: #ea6728;
      font-size: 18px; }

.specialist-schedule-work-day .status-cancellation .appointment-card {
  opacity: .3; }

.specialist-schedule-work-day .status-cancellation .status {
  color: #fe4543; }

.specialist-schedule-work-day .status-wait .appointment-card {
  opacity: .3; }

.specialist-schedule-work-day .status-wait .status {
  color: #ea6728; }

.specialist-schedule-work-day .appointment-card {
  border-radius: 2px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25), 0 0 2px 1px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  cursor: pointer;
  transition: all .3s ease; }
  .specialist-schedule-work-day .appointment-card:hover {
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.35), 0 0 2px 1px rgba(0, 0, 0, 0.08); }
  .specialist-schedule-work-day .appointment-card .appointment-type {
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    line-height: 1;
    padding: 6px 20px;
    border-radius: 2px 2px 0 0; }
  .specialist-schedule-work-day .appointment-card.appointment-type-01 .appointment-type {
    background-color: #616161; }
  .specialist-schedule-work-day .appointment-card.appointment-type-01 .appointment-container > *:before {
    background-color: #616161; }
  .specialist-schedule-work-day .appointment-card.appointment-type-02 .appointment-type {
    background-color: #10d78c; }
  .specialist-schedule-work-day .appointment-card.appointment-type-02 .appointment-container > *:before {
    background-color: #10d78c; }
  .specialist-schedule-work-day .appointment-card.appointment-type-03 .appointment-type {
    background-color: #284374; }
  .specialist-schedule-work-day .appointment-card.appointment-type-03 .appointment-container > *:before {
    background-color: #284374; }
  .specialist-schedule-work-day .appointment-card.appointment-type-04 .appointment-type {
    background-color: #28b4ea; }
  .specialist-schedule-work-day .appointment-card.appointment-type-04 .appointment-container > *:before {
    background-color: #28b4ea; }
  .specialist-schedule-work-day .appointment-card.appointment-type-05 .appointment-type {
    background-color: #ea28d5; }
  .specialist-schedule-work-day .appointment-card.appointment-type-05 .appointment-container > *:before {
    background-color: #ea28d5; }
  .specialist-schedule-work-day .appointment-card .appointment-container {
    display: flex;
    align-items: center;
    padding: 15px 20px; }
    @media (max-width: 1649px) {
      .specialist-schedule-work-day .appointment-card .appointment-container {
        padding: 0 10px;
        flex-wrap: wrap; } }
    .specialist-schedule-work-day .appointment-card .appointment-container > * {
      padding: 0 35px;
      position: relative; }
      @media (max-width: 1649px) {
        .specialist-schedule-work-day .appointment-card .appointment-container > * {
          padding: 0 20px; } }
      .specialist-schedule-work-day .appointment-card .appointment-container > *:before {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        width: 1px;
        height: 50px;
        top: 50%;
        margin-top: -25px; }
      .specialist-schedule-work-day .appointment-card .appointment-container > *:first-child {
        padding-left: 0; }
        @media (max-width: 1649px) {
          .specialist-schedule-work-day .appointment-card .appointment-container > *:first-child {
            padding-left: 20px; } }
      .specialist-schedule-work-day .appointment-card .appointment-container > *:last-child {
        padding-right: 0; }
        .specialist-schedule-work-day .appointment-card .appointment-container > *:last-child:before {
          content: none; }

.appointment-info-list {
  display: flex;
  align-items: center;
  padding: 15px 20px; }
  .appointment-info-list-item {
    padding: 0 35px;
    position: relative;
    display: flex;
    align-items: center;
    width: 33.33%; }
    .appointment-info-list-item:before {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      width: 1px;
      height: 50px;
      top: 50%;
      margin-top: -25px;
      background-color: #d8d8d8; }
    .appointment-info-list-item:first-child {
      padding-left: 0; }
    .appointment-info-list-item:last-child {
      padding-right: 0; }
      .appointment-info-list-item:last-child:before {
        content: none; }
  .appointment-info-list-image {
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
    border-radius: 50%;
    overflow: hidden;
    border: none;
    margin-right: 24px; }
    .appointment-info-list-image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .appointment-info-list-content {
    text-align: left; }
  .appointment-info-list-name {
    color: #404040;
    font-size: 16px;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 7px; }
    .appointment-info-list-name:last-child {
      margin-bottom: 0; }
  .appointment-info-list-post {
    opacity: 0.5;
    color: #000000;
    font-size: 16px;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

@media (max-width: 1649px) {
  .status-block {
    margin: 15px 0; } }

.status-block .status {
  color: #404040;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 13px; }
  .status-block .status:last-child {
    margin-bottom: 0; }
  .status-block .status span {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 10px; }

@media (max-width: 1649px) {
  .status-block .btn.btn-small {
    padding: 9px 20px; } }

.user-small-info {
  display: flex;
  align-items: center; }
  .mat-dialog-container .user-small-info {
    text-align: left; }
  .user-small-info .content .name {
    font-size: 16px;
    line-height: 1;
    color: #404040;
    margin-bottom: 7px; }
    .user-small-info .content .name:last-child {
      margin-bottom: 0; }
  .user-small-info .content .contact {
    font-size: 16px;
    line-height: 1;
    color: #000000;
    opacity: .5; }

.specialist-small-info {
  display: flex;
  align-items: center; }
  .specialist-small-info.centered {
    justify-content: center; }
  .mat-dialog-container .specialist-small-info {
    text-align: left; }
  .specialist-small-info .image {
    flex: 0 0 50px; }
  .specialist-small-info .content .name {
    font-size: 16px;
    line-height: 1;
    color: #404040;
    margin-bottom: 7px; }
    .specialist-small-info .content .name:last-child {
      margin-bottom: 0; }
  .specialist-small-info .content .post {
    font-size: 16px;
    line-height: 1;
    color: #000000;
    opacity: .5; }

.pet-small-info {
  display: flex;
  align-items: center; }
  .mat-dialog-container .pet-small-info {
    text-align: left; }
  .pet-small-info .content .name {
    font-size: 16px;
    line-height: 1;
    color: #404040;
    margin-bottom: 7px; }
    .pet-small-info .content .name:last-child {
      margin-bottom: 0; }
  .pet-small-info .content .post {
    font-size: 16px;
    line-height: 1;
    color: #000000;
    opacity: .5; }

.clinic-small-info {
  display: flex;
  align-items: center; }
  @media (max-width: 1649px) {
    .clinic-small-info {
      margin: 15px 0; } }
  .mat-dialog-container .clinic-small-info {
    text-align: left; }
  .clinic-small-info .image {
    flex: 0 0 50px; }
    @media (max-width: 1649px) {
      .clinic-small-info .image {
        margin-right: 10px; } }
  .clinic-small-info .content .name {
    font-size: 16px;
    line-height: 1;
    color: #404040;
    margin-bottom: 7px; }
    @media (max-width: 1649px) {
      .clinic-small-info .content .name {
        font-size: 14px; } }
    .clinic-small-info .content .name:last-child {
      margin-bottom: 0; }
  .clinic-small-info .content .post {
    font-size: 16px;
    line-height: 1;
    color: #000000;
    opacity: .5; }
    @media (max-width: 1649px) {
      .clinic-small-info .content .post {
        font-size: 14px; } }

/*
  Appointments
*/
.time-grid-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px; }
  .time-grid-list .time-grid-item {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    background-color: #ffffff;
    padding: 11px 22px;
    color: #404040;
    font-size: 18px;
    line-height: 1;
    transition: all .3s ease;
    width: calc(20% - 10px);
    margin-left: 10px;
    margin-bottom: 10px; }
    .time-grid-list .time-grid-item[disabled] {
      opacity: .3;
      cursor: not-allowed; }
    .time-grid-list .time-grid-item.is-blocked {
      background-color: #404040;
      color: #ffffff; }
    .time-grid-list .time-grid-item.is-appointment {
      background-color: #ea6728;
      color: #ffffff; }
    @media (max-width: 767px) {
      .time-grid-list .time-grid-item {
        width: calc(25% - 10px); } }
    @media (max-width: 575px) {
      .time-grid-list .time-grid-item {
        width: calc(33.33333% - 10px);
        padding: 11px 5px; } }
  .time-grid-list:not(.edit) .time-grid-item:hover:not([disabled]) {
    background-color: #ea6728;
    color: #ffffff; }
  .time-grid-list.edit .time-grid-item:hover {
    box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.23); }

/*
  Dialogs
*/
.create-appointment-price-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px; }
  .create-appointment-price-row .left,
  .create-appointment-price-row .right {
    color: #404040;
    font-size: 16px; }
    .create-appointment-price-row .left span,
    .create-appointment-price-row .right span {
      font-weight: 500; }

/*
  Content
*/
.dots-link {
  color: #ea6728;
  font-family: "Gotham Pro", sans-serif;
  font-size: 16px;
  line-height: 18px;
  display: inline-block;
  border-bottom: 1px dashed #ea6728;
  cursor: pointer;
  transition: all .3s ease; }
  .dots-link.small {
    font-size: 12px;
    line-height: 14px; }
  .dots-link:hover {
    color: #fe4543;
    border-bottom-color: transparent; }

.more-popup {
  position: relative; }
  .more-popup:hover {
    z-index: 100; }
  .more-popup .dots-link:hover + .hidden-more-popup {
    display: block; }
  .more-popup .hidden-more-popup {
    position: absolute;
    left: 50%;
    bottom: calc(100% + 10px);
    margin-left: -100px;
    width: 200px;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    background-color: #ffffff;
    padding: 20px;
    display: none;
    animation: fadeIn .3s ease 1 forwards; }
    .more-popup .hidden-more-popup:before {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      bottom: -8px;
      margin-left: -15px;
      font-size: 8px;
      color: #ffffff;
      transform: rotate(180deg);
      text-shadow: 0 -2px 2px rgba(0, 0, 0, 0.16); }
    .more-popup .hidden-more-popup div {
      color: #404040;
      font-family: "Gotham Pro", sans-serif;
      font-size: 16px;
      margin-bottom: 10px; }
      .more-popup .hidden-more-popup div:last-child {
        margin-bottom: 0; }

.specialist-card {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  background-color: #ffffff;
  margin-bottom: 30px;
  padding: 20px;
  display: flex;
  transition: box-shadow .3s ease; }
  .specialist-card:last-child {
    margin-bottom: 0; }
  .specialist-card:hover {
    box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.2); }
  .specialist-card .image {
    width: 100px;
    height: 100px;
    border: 1px solid #ea6728;
    border-radius: 50%;
    overflow: hidden;
    flex: 0 0 100px; }
    .specialist-card .image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .specialist-card .left {
    margin-left: 25px;
    width: calc(50% - 75px);
    max-width: 400px; }
  .specialist-card .right {
    width: calc(50% - 50px); }
    .specialist-card .right h5 {
      margin-bottom: 12px;
      line-height: 14px; }
  .specialist-card .address,
  .specialist-card .time {
    margin-bottom: 10px;
    display: flex; }
    .specialist-card .address .if,
    .specialist-card .time .if {
      font-size: 14px;
      line-height: 1;
      color: #ea6728;
      margin-right: 10px; }
  .specialist-card .address,
  .specialist-card .time,
  .specialist-card .price {
    color: #404040;
    font-family: "Gotham Pro", sans-serif;
    font-size: 12px;
    line-height: 14px; }
  .specialist-card .price {
    margin-bottom: 22px; }
  .specialist-card .name {
    color: #404040;
    font-family: "Gotham Pro", sans-serif;
    font-size: 20px;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px; }
  .specialist-card .specialization {
    color: #404040;
    font-family: "Gotham Pro", sans-serif;
    font-size: 16px;
    line-height: 1;
    margin-bottom: 10px;
    display: flex;
    align-items: center; }
    .specialist-card .specialization .more-popup {
      margin-left: 7px; }
  .specialist-card .animals {
    color: #404040;
    font-family: "Gotham Pro", sans-serif;
    font-size: 12px;
    line-height: 1;
    margin-bottom: 10px;
    display: flex;
    align-items: center; }
    .specialist-card .animals .more-popup {
      margin-left: 7px; }
  .specialist-card .experience {
    color: #404040;
    font-family: "Gotham Pro", sans-serif;
    font-size: 12px;
    line-height: 1;
    margin-bottom: 10px; }
    .specialist-card .experience span {
      font-weight: 500; }
  .specialist-card .reputation {
    display: flex;
    align-items: center; }
    .specialist-card .reputation .rating-stars {
      margin-right: 15px; }
  @media (max-width: 1440px) {
    .filters-result .specialist-card {
      flex-wrap: wrap; }
      .filters-result .specialist-card .left,
      .filters-result .specialist-card .right {
        width: calc(100% - 125px); }
      .filters-result .specialist-card .right {
        margin-left: 125px;
        margin-top: 30px; } }
  @media (max-width: 1200px) {
    .specialist-card {
      flex-wrap: wrap; }
      .specialist-card .left,
      .specialist-card .right {
        width: calc(100% - 125px); }
      .specialist-card .right {
        margin-left: 125px;
        margin-top: 30px; } }
  @media (max-width: 574px) {
    .specialist-card,
    .filters-result .specialist-card {
      display: block; }
      .specialist-card .image,
      .filters-result .specialist-card .image {
        margin: 0 auto 30px; }
      .specialist-card .left,
      .specialist-card .right,
      .filters-result .specialist-card .left,
      .filters-result .specialist-card .right {
        width: 100%;
        margin-left: 0; } }

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 40px; }
  @media (max-width: 574px) {
    .pagination-wrapper {
      flex-wrap: wrap; }
      .pagination-wrapper .count-on-page {
        order: 1;
        width: 100%;
        margin-bottom: 10px; } }

.pagination-navigation {
  display: flex;
  justify-content: space-between;
  margin-left: -15px; }
  .pagination-navigation button {
    color: #404040;
    font-family: "Gotham Pro", sans-serif;
    font-size: 16px;
    line-height: 1;
    width: 30px;
    height: 30px;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    background-color: #ffffff;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease; }
    .pagination-navigation button:hover, .pagination-navigation button.active {
      background-color: #ea6728;
      color: #ffffff; }
  @media (max-width: 574px) {
    .pagination-navigation {
      order: 2; } }

.rating-stars {
  display: flex;
  align-items: center;
  margin-left: -7px; }
  .rating-stars .if {
    line-height: 1;
    font-size: 16px;
    margin-left: 7px; }

/*
	Vendors
*/
/*
	Layout
*/
.app-header {
  height: 80px;
  box-shadow: 0 5px 10px 1px rgba(58, 68, 86, 0.25);
  background-color: #ffffff;
  width: 100%;
  position: fixed;
  z-index: 100;
  display: flex;
  align-items: center; }
  @media (max-width: 991px) {
    .app-header {
      justify-content: space-between;
      height: 60px;
      padding: 0 20px; } }

.header-under-sidebar {
  width: 345px;
  padding-left: 35px;
  padding-right: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all .3s ease; }
  .sidebar-collapsed .header-under-sidebar {
    width: 80px;
    padding: 0;
    justify-content: center; }
  @media (max-width: 991px) {
    .header-under-sidebar {
      width: auto;
      padding: 0; } }

.header-logo {
  width: 119px;
  overflow: hidden;
  transition: all .3s ease;
  cursor: pointer; }
  .sidebar-collapsed .header-logo {
    width: 0; }

.header-burger {
  cursor: pointer; }
  .header-burger .if {
    font-size: 26px;
    line-height: 1;
    color: #fe4543;
    display: block; }
  @media (max-width: 991px) {
    .header-burger {
      display: none; } }

.header-bars {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(100% - 345px);
  padding: 0 35px 0 30px;
  transition: all .3s ease; }
  .sidebar-collapsed .header-bars {
    width: calc(100% - 80px); }
  @media (max-width: 991px) {
    .header-bars {
      width: auto;
      padding: 0; } }

.header-navigation .close-mobile {
  display: none; }

.header-navigation a {
  font-size: 18px;
  line-height: 1;
  color: #404040;
  transition: all .3s ease;
  display: block;
  cursor: pointer; }
  .header-navigation a:hover {
    text-decoration: none;
    color: #ea6728; }

.header-navigation .if-angle-down {
  font-size: 6px;
  margin-left: 10px;
  cursor: pointer; }

.header-navigation .parent-navigation {
  display: flex; }
  .header-navigation .parent-navigation > li {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 40px; }
    .header-navigation .parent-navigation > li:first-child {
      margin-left: 0; }
    .header-navigation .parent-navigation > li:hover .sub-navigation {
      display: block; }

.header-navigation .sub-navigation {
  position: absolute;
  top: calc(100% + 13px);
  left: 0;
  border-radius: 10px;
  box-shadow: 0 0 11px 1px rgba(58, 68, 86, 0.3);
  background-color: #ffffff;
  padding: 25px 30px;
  display: none;
  animation: showSubNav .5s ease 1 forwards; }
  .header-navigation .sub-navigation:before {
    content: "";
    position: absolute;
    left: 36px;
    top: -7px;
    font-size: 7px;
    color: white; }
  .header-navigation .sub-navigation:after {
    content: '';
    position: absolute;
    left: 0;
    top: -13px;
    width: 100%;
    height: 13px; }
  .header-navigation .sub-navigation a {
    font-size: 16px; }
  .header-navigation .sub-navigation > li {
    margin-bottom: 20px; }
    .header-navigation .sub-navigation > li:last-child {
      margin-bottom: 0; }

@media (max-width: 991px) {
  .header-navigation {
    display: block;
    position: fixed;
    right: -280px;
    top: 0;
    z-index: 220;
    box-shadow: 0 1px 15px 1px rgba(78, 69, 65, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(3deg, #ff4444, #ea6728 56%, #ea6728);
    width: 280px;
    height: 100%;
    padding: 50px 0;
    transition: all .3s ease;
    overflow: auto; }
    .header-navigation.show {
      right: 0; }
    .header-navigation .close-mobile {
      display: flex;
      position: absolute;
      right: 10px;
      top: 15px;
      background-color: white;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      align-items: center;
      justify-content: center;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
      border: 1px solid white; }
      .header-navigation .close-mobile .if {
        font-size: 15px;
        line-height: 1;
        color: #ea6728; }
    .header-navigation .parent-navigation {
      display: block; }
      .header-navigation .parent-navigation > li {
        margin-left: 0;
        flex-wrap: wrap;
        justify-content: space-between; }
      .header-navigation .parent-navigation a,
      .header-navigation .parent-navigation .if {
        color: white; }
      .header-navigation .parent-navigation a {
        padding: 15px 20px;
        width: calc(100% - 43px); }
      .header-navigation .parent-navigation .if {
        margin: 0;
        padding: 16px;
        transition: all .3s ease; }
        .header-navigation .parent-navigation .if.active {
          transform: rotate(180deg); }
          .header-navigation .parent-navigation .if.active + .sub-navigation {
            max-height: 10000px;
            transition: max-height 1s ease-in-out; }
    .header-navigation .sub-navigation {
      overflow: hidden;
      max-height: 0;
      display: block;
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      background: none;
      border-radius: 0;
      box-shadow: none;
      padding: 0;
      transition: max-height 0.5s cubic-bezier(0, 1, 0, 1); }
      .header-navigation .sub-navigation:before {
        content: '';
        box-shadow: inset 0 5px 5px 0 rgba(0, 0, 0, 0.15);
        background-color: #d55c22;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 1;
        transition: all .5s ease; }
      .header-navigation .sub-navigation li {
        margin-bottom: 10px; }
        .header-navigation .sub-navigation li:first-child {
          margin-top: 15px; }
        .header-navigation .sub-navigation li:last-child {
          margin-bottom: 15px; }
      .header-navigation .sub-navigation a {
        display: block;
        width: 100%;
        padding: 5px 0 5px 40px; } }

.header-controls {
  display: flex;
  align-items: center; }
  .header-controls > div {
    margin-left: 60px; }
    .header-controls > div:first-child {
      margin-left: 0; }
  @media (max-width: 991px) {
    .header-controls {
      display: none;
      position: fixed;
      top: 60px;
      left: 0;
      width: 100%;
      background-color: white;
      justify-content: space-between;
      padding: 10px 30px;
      box-shadow: 0 5px 10px 1px rgba(58, 68, 86, 0.25), inset 0 1px 4px 1px rgba(58, 68, 86, 0.25);
      animation: showSubNav .5s ease 1 forwards;
      z-index: 180; }
      .header-controls.show {
        display: flex; } }

.header-chat,
.header-notify {
  cursor: pointer; }
  .header-chat .if,
  .header-notify .if {
    display: block;
    font-size: 20px;
    line-height: 1; }

.header-notify {
  position: relative; }
  .header-notify .notify {
    width: 3px;
    height: 3px;
    background-color: #42e200;
    position: absolute;
    left: 50%;
    margin-left: -1.5px;
    bottom: -5px; }

.header-user {
  position: relative; }
  .header-user .small-avatar {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    cursor: pointer; }
    .header-user .small-avatar img {
      width: 100%; }
  .header-user:hover .header-user-info {
    display: block; }

.header-user-info {
  position: absolute;
  width: 242px;
  border-radius: 10px;
  box-shadow: 0 1px 12px 2px rgba(58, 68, 86, 0.25);
  background-color: #ffffff;
  right: -20px;
  top: calc(100% + 7px);
  display: none;
  animation: showSubNav .5s ease 1 forwards; }
  .header-user-info .load-local-spinner {
    position: static;
    width: 30px;
    margin: 20px auto;
    transform: none; }
    .header-user-info .load-local-spinner img {
      width: 100%; }
  .header-user-info:before {
    content: "";
    position: absolute;
    right: 25px;
    top: -7px;
    font-size: 7px;
    color: #ea6728; }
  .header-user-info:after {
    content: '';
    position: absolute;
    left: 0;
    top: -7px;
    width: 100%;
    height: 7px; }
  .header-user-info .head {
    padding: 11px 13px;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    background-image: linear-gradient(24deg, #fc4a4a, #ea6728); }
    .header-user-info .head .info {
      width: calc(100% - 68px); }
  .header-user-info .large-avatar {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 18px;
    flex: 0 0 50px;
    position: relative; }
    .header-user-info .large-avatar img {
      width: 100%; }
  .header-user-info .user-name,
  .header-user-info .user-email {
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .header-user-info .user-name {
    font-size: 14px;
    font-weight: 500; }
  .header-user-info .user-email {
    font-size: 12px; }

.header-user-navigation {
  padding: 25px 20px; }
  .header-user-navigation li {
    margin-bottom: 25px; }
    .header-user-navigation li:last-child {
      margin-bottom: 0; }
  .header-user-navigation button {
    font-size: 14px;
    font-weight: 300;
    color: #404040;
    display: flex;
    align-items: center;
    transition: all .3s ease; }
    .header-user-navigation button:hover {
      text-decoration: none;
      color: #ea6728; }
  .header-user-navigation .if {
    font-size: 15px;
    display: block;
    line-height: 1;
    margin-right: 15px;
    width: 15px;
    text-align: center;
    flex: 0 0 15px; }

.header-controls-mobile {
  display: none;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 991px) {
    .header-controls-mobile {
      display: flex; }
      .header-controls-mobile button {
        background: none;
        border: none;
        padding: 0;
        margin: 0 20px;
        line-height: 1; } }
    @media (max-width: 991px) and (max-width: 400px) {
      .header-controls-mobile button {
        margin: 0 10px; } }
  @media (max-width: 991px) {
        .header-controls-mobile button .if {
          font-size: 20px;
          line-height: 1;
          display: block; } }

.header-overlay-mobile {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 190;
  animation: fadeIn .5s ease 1 forwards; }
  .header-overlay-mobile.show {
    display: block; }

.app-sidebar {
  width: 345px;
  height: 100%;
  box-shadow: 0 1px 15px 1px rgba(78, 69, 65, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(14deg, #ff4444, #ea6728 56%, #ea6728);
  z-index: 90;
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 108px;
  transition: width .3s ease;
  overflow: auto;
  display: flex;
  flex-wrap: wrap; }
  .app-sidebar.sidebar-collapsed {
    width: 80px; }
  .app-sidebar .close-mobile {
    display: none; }
  @media (max-width: 991px) {
    .app-sidebar {
      z-index: 220;
      width: 280px;
      padding-top: 50px;
      overflow: auto;
      left: -280px;
      transition: all .3s ease; }
      .app-sidebar.show {
        left: 0; }
      .app-sidebar .close-mobile {
        display: flex;
        position: absolute;
        left: 25px;
        top: 15px;
        background-color: white;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
        border: 1px solid white; }
        .app-sidebar .close-mobile .if {
          font-size: 15px;
          line-height: 1;
          color: #ea6728; } }

.sidebar-navigation {
  width: 100%; }
  .sidebar-navigation li {
    margin-bottom: 10px; }
    .sidebar-navigation li:last-child {
      margin-bottom: 0; }
  .sidebar-navigation button {
    display: flex;
    align-items: center;
    padding: 20px 30px;
    position: relative;
    width: 100%; }
    .sidebar-collapsed .sidebar-navigation button {
      padding: 20px 0;
      justify-content: center; }
    .sidebar-navigation button:before {
      content: '';
      box-shadow: inset 0 5px 5px 0 rgba(0, 0, 0, 0.15);
      background-color: rgba(0, 0, 0, 0.1);
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0;
      transition: all .5s ease; }
    .sidebar-navigation button .if {
      font-size: 20px;
      line-height: 1;
      margin-right: 20px;
      width: 20px;
      text-align: center;
      flex: 0 0 20px;
      color: white; }
      .sidebar-collapsed .sidebar-navigation button .if {
        margin: 0; }
    .sidebar-navigation button .text {
      font-size: 18px;
      color: white;
      line-height: 1;
      font-weight: 300;
      transition: all .3s ease;
      overflow: hidden;
      text-align: left; }
      .sidebar-collapsed .sidebar-navigation button .text {
        display: none; }
    .sidebar-navigation button:hover {
      color: white;
      text-decoration: none; }
      .sidebar-navigation button:hover:before {
        opacity: 1; }
    .sidebar-navigation button.active:before {
      opacity: 1; }

.sidebar-socials {
  position: static;
  padding: 40px 0 40px 37px;
  align-self: flex-end; }
  .sidebar-collapsed .sidebar-socials {
    left: 25px;
    padding-left: 25px; }
  .sidebar-socials .title {
    font-size: 16px;
    color: #ffffff;
    line-height: 1;
    margin-bottom: 16px; }
    .sidebar-collapsed .sidebar-socials .title {
      display: none; }
  .sidebar-socials .socials-list {
    display: flex;
    align-items: center; }
    .sidebar-collapsed .sidebar-socials .socials-list {
      display: block; }
    .sidebar-socials .socials-list a {
      width: 30px;
      height: 30px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
      background-color: white;
      border: 1px solid white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 13px;
      transition: all .5s ease; }
      .sidebar-socials .socials-list a:first-child {
        margin-left: 0; }
      .sidebar-collapsed .sidebar-socials .socials-list a {
        margin-left: 0;
        margin-bottom: 13px; }
        .sidebar-collapsed .sidebar-socials .socials-list a:last-child {
          margin-bottom: 0; }
      .sidebar-socials .socials-list a .if {
        font-size: 14px;
        color: #fe4543;
        line-height: 1;
        transition: all .5s ease; }
        .sidebar-socials .socials-list a .if.if-vkontakte {
          font-size: 10px; }
      .sidebar-socials .socials-list a:hover {
        text-decoration: none;
        background-color: transparent;
        box-shadow: none; }
        .sidebar-socials .socials-list a:hover .if {
          color: white; }

.auth-background {
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 230px;
  padding-bottom: 50px; }
  @media (max-width: 1500px) {
    .auth-background {
      padding: 100px 0 50px; } }
  .auth-background:before {
    content: '';
    display: block;
    position: fixed;
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.79), rgba(234, 103, 40, 0.77));
    left: 0;
    top: 0;
    z-index: 20; }
  .auth-background .load-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 50; }
    .auth-background .load-spinner img {
      transform-origin: 50% 50%;
      animation: loadingSpinner 1s linear infinite; }

.auth-logo {
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 50;
  cursor: pointer; }
  .auth-logo img {
    width: 100%; }
  @media (max-width: 1500px) {
    .auth-logo {
      top: 30px;
      width: 150px; } }

.auth-background-image {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  display: flex; }
  .auth-background-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.auth-centered {
  position: relative;
  z-index: 50;
  width: 400px; }
  @media (max-width: 678px) {
    .auth-centered {
      width: 100%;
      padding: 0 15px; } }

.auth-form-title {
  font-size: 30px;
  text-align: center;
  color: white;
  margin-bottom: 45px;
  font-weight: 300;
  line-height: 1; }

.auth-form label {
  display: block;
  position: relative;
  margin-bottom: 40px; }
  .auth-form label > .if {
    font-size: 30px;
    color: white;
    line-height: 1;
    position: absolute;
    left: 35px;
    top: 15px;
    height: 30px;
    opacity: .4;
    width: 30px;
    text-align: center;
    transition: all .3s ease;
    display: flex;
    align-items: center;
    justify-content: center; }
    .auth-form label > .if.if-light-envelope {
      font-size: 22px; }
  .auth-form label .autocomplete-items {
    margin-top: -3px;
    border-radius: 10px;
    box-shadow: 0 1px 12px 2px rgba(58, 68, 86, 0.25);
    background-color: #ffffff;
    animation: showSubNav .5s ease 1 forwards; }
    .auth-form label .autocomplete-items .autocomplele-item {
      background: none !important;
      padding: 10px 15px !important; }
      .auth-form label .autocomplete-items .autocomplele-item:last-child {
        border-bottom: none !important; }
  .auth-form label .show-password {
    position: absolute;
    top: 15px;
    right: 35px; }
    .auth-form label .show-password .if {
      font-size: 30px;
      line-height: 1;
      color: #ffffff;
      cursor: pointer;
      position: absolute;
      z-index: 1;
      opacity: 0;
      right: 0;
      transition: all .3s ease; }
      .auth-form label .show-password .if.if-eye {
        opacity: .4; }

.auth-form input[type="text"],
.auth-form input[type="email"],
.auth-form input[type="password"] {
  width: 100%;
  height: 60px;
  border-radius: 30px;
  border: none;
  box-shadow: 0 3px 15px 1px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.2);
  padding: 0 45px 0 85px;
  font-size: 18px;
  color: white;
  font-weight: 300;
  line-height: 18px;
  transition: all .3s ease; }
  .auth-form input[type="text"].input-password,
  .auth-form input[type="email"].input-password,
  .auth-form input[type="password"].input-password {
    padding: 0 85px; }
  .auth-form input[type="text"]::-webkit-credentials-auto-fill-button,
  .auth-form input[type="email"]::-webkit-credentials-auto-fill-button,
  .auth-form input[type="password"]::-webkit-credentials-auto-fill-button {
    background-color: white !important; }
  .auth-form input[type="text"]:focus,
  .auth-form input[type="email"]:focus,
  .auth-form input[type="password"]:focus {
    background-color: rgba(255, 255, 255, 0.5); }
    .auth-form input[type="text"]:focus + .if,
    .auth-form input[type="email"]:focus + .if,
    .auth-form input[type="password"]:focus + .if {
      opacity: 1; }
    .auth-form input[type="text"]:focus + .if + .show-password .if-eye,
    .auth-form input[type="email"]:focus + .if + .show-password .if-eye,
    .auth-form input[type="password"]:focus + .if + .show-password .if-eye {
      opacity: 0; }
    .auth-form input[type="text"]:focus + .if + .show-password .if-no-eye,
    .auth-form input[type="email"]:focus + .if + .show-password .if-no-eye,
    .auth-form input[type="password"]:focus + .if + .show-password .if-no-eye {
      opacity: 1; }
    .auth-form input[type="text"]:focus[type="password"] + .if + .show-password .if-eye,
    .auth-form input[type="email"]:focus[type="password"] + .if + .show-password .if-eye,
    .auth-form input[type="password"]:focus[type="password"] + .if + .show-password .if-eye {
      opacity: 1; }
    .auth-form input[type="text"]:focus[type="password"] + .if + .show-password .if-no-eye,
    .auth-form input[type="email"]:focus[type="password"] + .if + .show-password .if-no-eye,
    .auth-form input[type="password"]:focus[type="password"] + .if + .show-password .if-no-eye {
      opacity: 0; }
  .auth-form input[type="text"]:-webkit-autofill,
  .auth-form input[type="email"]:-webkit-autofill,
  .auth-form input[type="password"]:-webkit-autofill {
    background-color: white !important; }
    .auth-form input[type="text"]:-webkit-autofill + .if,
    .auth-form input[type="email"]:-webkit-autofill + .if,
    .auth-form input[type="password"]:-webkit-autofill + .if {
      opacity: 1;
      color: #ea6728; }
    .auth-form input[type="text"]:-webkit-autofill + .if + .show-password .if,
    .auth-form input[type="email"]:-webkit-autofill + .if + .show-password .if,
    .auth-form input[type="password"]:-webkit-autofill + .if + .show-password .if {
      color: #404040; }
    .auth-form input[type="text"]:-webkit-autofill + .if + .show-password .if-eye,
    .auth-form input[type="email"]:-webkit-autofill + .if + .show-password .if-eye,
    .auth-form input[type="password"]:-webkit-autofill + .if + .show-password .if-eye {
      opacity: 0; }
    .auth-form input[type="text"]:-webkit-autofill + .if + .show-password .if-no-eye,
    .auth-form input[type="email"]:-webkit-autofill + .if + .show-password .if-no-eye,
    .auth-form input[type="password"]:-webkit-autofill + .if + .show-password .if-no-eye {
      opacity: 1; }
    .auth-form input[type="text"]:-webkit-autofill[type="password"] + .if + .show-password .if-eye,
    .auth-form input[type="email"]:-webkit-autofill[type="password"] + .if + .show-password .if-eye,
    .auth-form input[type="password"]:-webkit-autofill[type="password"] + .if + .show-password .if-eye {
      opacity: 1; }
    .auth-form input[type="text"]:-webkit-autofill[type="password"] + .if + .show-password .if-no-eye,
    .auth-form input[type="email"]:-webkit-autofill[type="password"] + .if + .show-password .if-no-eye,
    .auth-form input[type="password"]:-webkit-autofill[type="password"] + .if + .show-password .if-no-eye {
      opacity: 0; }
  .auth-form input[type="text"]::-webkit-input-placeholder, .auth-form input[type="email"]::-webkit-input-placeholder, .auth-form input[type="password"]::-webkit-input-placeholder {
    color: white;
    opacity: .4; }
  .auth-form input[type="text"]:-moz-placeholder, .auth-form input[type="email"]:-moz-placeholder, .auth-form input[type="password"]:-moz-placeholder {
    color: white;
    opacity: .4; }
  .auth-form input[type="text"]::-moz-placeholder, .auth-form input[type="email"]::-moz-placeholder, .auth-form input[type="password"]::-moz-placeholder {
    color: white;
    opacity: .4; }
  .auth-form input[type="text"]:-ms-input-placeholder, .auth-form input[type="email"]:-ms-input-placeholder, .auth-form input[type="password"]:-ms-input-placeholder {
    color: white;
    opacity: .4; }
  @media (max-width: 767px) {
    .auth-form input[type="text"],
    .auth-form input[type="email"],
    .auth-form input[type="password"] {
      padding: 0 30px 0 85px; }
      .auth-form input[type="text"].input-password,
      .auth-form input[type="email"].input-password,
      .auth-form input[type="password"].input-password {
        padding: 0 85px; } }

.auth-form .custom-auth-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }
  .auth-form .custom-auth-checkbox input {
    display: none; }
  .auth-form .custom-auth-checkbox .if {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    color: white;
    font-size: 13px;
    line-height: 1;
    opacity: 0;
    transition: all .3s ease; }
  .auth-form .custom-auth-checkbox .auth-checkbox {
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
    border-radius: 4px;
    box-shadow: 0 3px 15px 1px rgba(0, 0, 0, 0.3);
    background-color: rgba(255, 255, 255, 0.2);
    margin-right: 20px;
    position: relative;
    transition: all .3s ease; }
  .auth-form .custom-auth-checkbox input:checked + .auth-checkbox {
    background-color: rgba(255, 255, 255, 0.5); }
    .auth-form .custom-auth-checkbox input:checked + .auth-checkbox .if {
      opacity: 1; }
  .auth-form .custom-auth-checkbox .label {
    font-size: 18px;
    line-height: 1;
    color: #ffffff;
    font-weight: 300; }
    .auth-form .custom-auth-checkbox .label a {
      opacity: 0.5;
      color: #ffffff;
      font-weight: 300;
      line-height: 1;
      display: inline;
      border-bottom: 1px solid white;
      transition: all .3s ease; }
      .auth-form .custom-auth-checkbox .label a:hover {
        opacity: 1;
        color: white;
        text-decoration: none; }

.auth-form .auth-error {
  margin-bottom: 20px; }
  .auth-form .auth-error input[type="text"],
  .auth-form .auth-error input[type="email"],
  .auth-form .auth-error input[type="password"] {
    border: 2px solid red; }
    .auth-form .auth-error input[type="text"]:-webkit-autofill + .if,
    .auth-form .auth-error input[type="email"]:-webkit-autofill + .if,
    .auth-form .auth-error input[type="password"]:-webkit-autofill + .if {
      color: red; }
  .auth-form .auth-error > .if {
    color: red; }

.auth-form .auth-error-message {
  display: block; }
  .auth-form .auth-error-message span {
    display: block;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    margin-top: 13px;
    font-weight: 300;
    padding-left: 20px;
    margin-bottom: 10px; }
    .auth-form .auth-error-message span:last-child {
      margin-bottom: 0; }

.auth-form .auth-button {
  width: 100%;
  height: 60px;
  border-radius: 30px;
  box-shadow: 0 3px 15px 1px rgba(0, 0, 0, 0.3);
  background-color: #ea6728;
  border: none;
  font-size: 18px;
  line-height: 1;
  text-align: center;
  color: white;
  font-weight: 300;
  cursor: pointer;
  transition: all .3s ease; }
  .auth-form .auth-button:not([disabled]):hover {
    background-color: white;
    color: #ea6728; }
  .auth-form .auth-button[disabled] {
    opacity: .4;
    cursor: not-allowed; }

.auth-socials-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px; }
  .auth-socials-buttons button,
  .auth-socials-buttons a {
    width: 60px;
    height: 60px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s ease; }
    .auth-socials-buttons button .if,
    .auth-socials-buttons a .if {
      font-size: 28px;
      display: block;
      color: white;
      transition: all .3s ease; }
      .auth-socials-buttons button .if.if-vkontakte,
      .auth-socials-buttons a .if.if-vkontakte {
        font-size: 20px; }
    .auth-socials-buttons button:hover,
    .auth-socials-buttons a:hover {
      background-color: white;
      text-decoration: none; }
      .auth-socials-buttons button:hover .if,
      .auth-socials-buttons a:hover .if {
        color: #ea6728; }

.auth-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px; }
  .auth-links.already-have {
    justify-content: center;
    flex-wrap: wrap; }
  .auth-links .title {
    width: 100%;
    font-size: 16px;
    color: #ffffff;
    line-height: 1;
    margin-bottom: 10px;
    text-align: center;
    font-weight: 300; }
  .auth-links a {
    opacity: 0.5;
    font-size: 16px;
    color: #ffffff;
    font-weight: 300;
    line-height: 1;
    display: inline-block;
    border-bottom: 1px solid white;
    transition: all .3s ease; }
    .auth-links a:hover {
      opacity: 1;
      color: white;
      text-decoration: none; }

.auth-text-content {
  width: 100%;
  max-width: 1140px;
  padding: 60px 70px;
  border-radius: 10px;
  box-shadow: 0 2px 15px 2px rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.21);
  position: relative;
  z-index: 50;
  margin-bottom: 50px;
  color: #ffffff;
  overflow: hidden; }
  @media (max-width: 767px) {
    .auth-text-content {
      padding: 30px 15px;
      width: calc(100% - 30px);
      margin-bottom: 0; } }
  .auth-text-content h1 {
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 30px; }
    @media (max-width: 767px) {
      .auth-text-content h1 {
        font-size: 24px; } }
  .auth-text-content h2 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 34px; }
    @media (max-width: 767px) {
      .auth-text-content h2 {
        font-size: 18px; } }
  .auth-text-content ol {
    list-style: none;
    counter-reset: authList;
    margin: 0;
    padding: 0; }
    .auth-text-content ol li {
      margin-bottom: 30px;
      font-size: 16px;
      line-height: 1.5;
      font-weight: 300;
      position: relative;
      counter-increment: authList;
      padding-left: 40px; }
      .auth-text-content ol li ol {
        margin: 30px 0; }
      @media (max-width: 767px) {
        .auth-text-content ol li {
          padding-left: 30px; } }
      .auth-text-content ol li:before {
        content: counter(authList) ".";
        position: absolute;
        left: 0;
        top: 0; }

.content-404 {
  position: relative;
  z-index: 50;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px; }
  .content-404 .image-404 {
    width: 100%;
    max-width: 615px;
    margin: 0 auto 65px; }
    .content-404 .image-404 img {
      width: 100%; }
  .content-404 .text {
    font-size: 36px;
    line-height: 1.17;
    font-weight: 300;
    color: white;
    margin-bottom: 50px; }
    @media (max-width: 767px) {
      .content-404 .text {
        font-size: 24px; } }

label {
  display: block; }

.form-group .mat-form-field {
  width: 100%; }

.mat-form-field {
  width: 100%; }

textarea.mat-input-element.mat-autosize {
  padding: 0; }

.checkbox-list > div {
  margin-bottom: 20px; }

.checkbox-list .mat-checkbox-layout .mat-checkbox-label {
  white-space: normal;
  line-height: 1; }

.mat-form-field .show-password {
  position: absolute;
  top: 8px;
  right: 5px; }
  .mat-form-field .show-password .if {
    font-size: 20px;
    line-height: 1;
    color: #404040;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    opacity: 0;
    right: 0;
    transition: all .3s ease; }
    .mat-form-field .show-password .if.if-eye {
      opacity: 0; }
    .mat-form-field .show-password .if.if-no-eye {
      opacity: 1; }

.mat-form-field input[type="password"] ~ .show-password .if-eye {
  opacity: 1; }

.mat-form-field input[type="password"] ~ .show-password .if-no-eye {
  opacity: 0; }

mat-label i.danger {
  color: #fe4543;
  font-style: normal; }

/*
	Application
*/
body {
  font-family: "Gotham Pro", sans-serif;
  font-weight: 400;
  color: #404040; }

* {
  outline: none !important; }

ul {
  list-style: none;
  padding: 0;
  margin: 0; }
  ul li {
    padding: 0;
    margin: 0; }

a {
  color: #ea6728;
  transition: color .5s ease;
  cursor: pointer; }
  a:hover {
    color: #fe4543; }

h1, h2, h3, h4, h5, h6 {
  color: #404040;
  font-family: "Gotham Pro", sans-serif;
  font-weight: 400; }

h1 {
  font-size: 30px;
  margin-bottom: 50px; }

h2 {
  font-size: 26px;
  margin-bottom: 35px; }

h3 {
  font-size: 24px;
  margin-bottom: 30px; }

h4 {
  font-size: 20px;
  margin-bottom: 25px; }

h5 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px; }

h6 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px; }

button {
  padding: 0;
  background: none;
  border: none;
  cursor: pointer; }

hr {
  margin: 60px 0;
  border: none;
  height: 1px;
  background-color: #cbcbcb; }
  hr + .last-buttons {
    margin-top: -30px; }

.form-group .mat-icon {
  font-size: 12px;
  height: 12px; }

.icon-gradient:before {
  background-image: linear-gradient(8deg, #fe4543, #ea6728);
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.content-background {
  background-color: #f1f1f1;
  padding-top: 80px;
  position: relative;
  min-height: 100vh;
  padding-left: 345px;
  transition: all .3s ease; }
  .content-background.no-sidebar {
    padding-left: 0; }
  .content-background.sidebar-collapsed {
    padding-left: 80px; }
  @media (max-width: 991px) {
    .content-background {
      padding-left: 0; } }

.content-wrapper {
  padding: 50px 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between; }
  @media (max-width: 767px) {
    .content-wrapper {
      padding: 30px 15px; } }

.main-title {
  font-size: 30px;
  line-height: 1;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  width: 100%; }
  @media (max-width: 991px) {
    .main-title {
      flex-wrap: wrap; } }
  .main-title .title-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: -40px; }
    @media (max-width: 991px) {
      .main-title .title-controls {
        width: 100%;
        margin-top: 15px; } }
    .main-title .title-controls button {
      margin-left: 40px; }

.card-list {
  margin-bottom: -30px;
  width: 100%; }
  @media (max-width: 767px) {
    .card-list {
      width: calc(100% + 30px); } }
  .card-list > div {
    margin-bottom: 30px; }

.card-add {
  border-radius: 10px;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .7s ease;
  min-height: 250px; }
  .card-add .centered {
    text-align: center; }
  .card-add .if {
    display: block;
    font-size: 50px;
    line-height: 1;
    margin-bottom: 20px; }
  .card-add:hover {
    box-shadow: 0 0 30px 1px rgba(58, 68, 86, 0.3); }

.main-container {
  border-radius: 10px;
  box-shadow: 1px 1px 7px 1px rgba(58, 68, 86, 0.3);
  background-color: #ffffff;
  padding: 60px 70px;
  width: 100%;
  position: relative; }
  @media (max-width: 1439px) {
    .main-container {
      padding: 30px; } }
  .main-container.no-padding-bottom {
    padding-bottom: 0;
    border-radius: 10px 10px 0 0; }
  .main-container h2 {
    font-size: 24px;
    font-weight: 300;
    line-height: 1;
    margin-top: 60px;
    margin-bottom: 35px; }
    .main-container h2:first-child {
      margin-top: 0; }
  .main-container .last-buttons {
    margin-left: -40px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start; }
    .main-container .last-buttons button {
      margin-left: 40px;
      margin-top: 30px; }
  .main-container.has-filters {
    display: flex;
    justify-content: space-between;
    padding: 40px 30px; }
  @media (max-width: 767px) {
    .main-container {
      padding: 30px 20px; } }

.disabled-link {
  opacity: .4;
  cursor: not-allowed !important; }

.router-wrapper {
  width: 100%; }

/*
	Themes
*/
/*
	Animations
*/
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.35); }
  70% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0); } }

@keyframes showSubNav {
  from {
    opacity: 0;
    transform: translateY(-10px); }
  to {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes loadingSpinner {
  from {
    transform: rotate(0); }
  to {
    transform: rotate(350deg); } }
