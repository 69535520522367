.pet-photo-slider-wrapper {
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.18), 0 0 3px 1px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  margin: 0 auto 60px;
  position: relative;

  .item {
	display: flex;
	position: relative;

	img {
	  width: 100%;
	  height: 100%;
	  object-fit: cover;
	}
  }

  .nav-next,
  .nav-prev {
	width: 30px;
	height: 30px;
	box-shadow: 0 3px 7px 1px rgba(0, 0, 0, 0.25);
	background-color: #ffffff;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	margin-top: -15px;
	cursor: pointer;
	transition: all .3s ease;

	.if {
	  font-size: 16px;
	  line-height: 1;
	}

	&:hover {
	  background-color: $color-orange;
	  color: white;
	}
  }

  .nav-next {
	right: 30px;

	@media (max-width: 575px) {
	  right: 10px;
	}
  }

  .nav-prev {
	left: 30px;

	@media (max-width: 575px) {
	  left: 10px;
	}
  }

  .dots {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 25px;

	@media (max-width: 575px) {
	  bottom: 10px;
	}

	.dot {
	  width: 8px;
	  height: 8px;
	  box-shadow: 0 3px 7px 1px rgba(0, 0, 0, 0.25);
	  background-color: #ffffff;
	  border-radius: 50%;
	  margin-left: 8px;
	  transition: all .3s ease;
	  cursor: pointer;

	  &:first-child {
		margin-left: 0;
	  }

	  &.active,
	  &:hover {
		color: $color-orange;
	  }
	}
  }
}
