.filters-sidebar {
  width: 190px;
  position: relative;

  h4 {
	line-height: 1;
	margin-bottom: 20px;
  }

  label {
	color: $color-gray-dark;
	font-family: $gothamPro;
	font-size: 14px;
	line-height: 16px;
  }

  .filters-wrapper {
	margin-bottom: 20px;
  }

  &:after {
	content: '';
	display: block;
	width: 1px;
	height: calc(100% + 30px);
	background-color: #dfdfdf;
	position: absolute;
	right: 0;
	top: -15px;

	@media (max-width: 1100px) {
	  content: none;
	}
  }

  @media (max-width: 1100px) {
	width: 100%;
	margin-bottom: 40px;
	display: none;
  }
}

.filters-result {
  width: calc(100% - 220px);

  @media (max-width: 1100px) {
	width: 100%;
  }
}

.filters-result-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
  align-items: flex-end;

  h4 {
	font-weight: 500;
	line-height: 1;
	margin-bottom: 0;
  }

  .left {
	display: flex;
	align-items: flex-end;

	@media (max-width: 1200px) {
	  display: block;
	}
  }

  .info-results {
	margin-left: 10px;
	line-height: 1;
	margin-bottom: -1px;

	@media (max-width: 1200px) {
	  margin-left: 0;
	  margin-top: 10px;
	}

	span {
	  font-weight: 500;
	}
  }

  .right {
	color: $color-gray-dark;
	font-family: $gothamPro;
	font-size: 16px;
	line-height: 1;
  }

  @media (max-width: 1400px) {
	flex-wrap: wrap;

	.left,
	.right {
	  width: 100%;
	}

	.left {
	  margin-bottom: 30px;
	}
  }
}
