.buttons-list {
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  .button {
    border: solid 1px $color-gray-lighter;
    border-left: 0;
    background-color: $color-white;
    font-size: 16px;
    line-height: 1;
    padding: 12px 5px;
    width: 100%;
    transition: all .3s ease;
    font-weight: 300;

    &:first-child {
      border-left: solid 1px $color-gray-lighter;
      border-radius: 8px 0 0 8px;
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
    }

    &:hover {
      background-color: $color-gray-lighter;
    }

    &.active {
      background-image: linear-gradient(18deg, $color-coral, $color-orange);
      color: $color-white;
      border: none;
    }
  }

  &.buttons-small .button {
    padding: 7px 5px;
    font-size: 14px;

    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-radius: 0 6px 6px 0;
    }
  }
}
