.app-header {
  height: 80px;
  box-shadow: 0 5px 10px 1px rgba(58, 68, 86, 0.25);
  background-color: #ffffff;
  width: 100%;
  position: fixed;
  z-index: 100;
  display: flex;
  align-items: center;

  @media (max-width: 991px) {
    justify-content: space-between;
    height: 60px;
    padding: 0 20px;
  }
}

.header-under-sidebar {
  width: 345px;
  padding-left: 35px;
  padding-right: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all .3s ease;

  .sidebar-collapsed & {
    width: 80px;
    padding: 0;
    justify-content: center;
  }

  @media (max-width: 991px) {
    width: auto;
    padding: 0;
  }
}

.header-logo {
  width: 119px;
  overflow: hidden;
  transition: all .3s ease;
  cursor: pointer;

  .sidebar-collapsed & {
    width: 0;
  }
}

.header-burger {
  cursor: pointer;

  .if {
    font-size: 26px;
    line-height: 1;
    color: $color-coral;
    display: block;
  }

  @media (max-width: 991px) {
    display: none;
  }
}

.header-bars {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(100% - 345px);
  padding: 0 35px 0 30px;
  transition: all .3s ease;

  .sidebar-collapsed & {
    width: calc(100% - 80px);
  }

  @media (max-width: 991px) {
    width: auto;
    padding: 0;
  }
}

.header-navigation {
  .close-mobile {
    display: none;
  }

  a {
    font-size: 18px;
    line-height: 1;
    color: #404040;
    transition: all .3s ease;
    display: block;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      color: $color-orange;
    }
  }

  .if-angle-down {
    font-size: 6px;
    margin-left: 10px;
    cursor: pointer;
  }

  .parent-navigation {
    display: flex;

    > li {
      position: relative;
      display: flex;
      align-items: center;
      margin-left: 40px;

      &:first-child {
        margin-left: 0;
      }

      &:hover .sub-navigation {
        display: block;
      }
    }
  }

  .sub-navigation {
    position: absolute;
    top: calc(100% + 13px);
    left: 0;
    border-radius: 10px;
    box-shadow: 0 0 11px 1px rgba(58, 68, 86, 0.3);
    background-color: #ffffff;
    padding: 25px 30px;
    display: none;
    animation: showSubNav .5s ease 1 forwards;

    &:before {
      content: $if-popover-arrow;
      @extend %if;
      position: absolute;
      left: 36px;
      top: -7px;
      font-size: 7px;
      color: white;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: -13px;
      width: 100%;
      height: 13px;
    }

    a {
      font-size: 16px;
    }

    > li {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 991px) {
    display: block;
    position: fixed;
    right: -280px;
    //right: 0;
    top: 0;
    z-index: 220;
    box-shadow: 0 1px 15px 1px rgba(78, 69, 65, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(3deg, #ff4444, #ea6728 56%, #ea6728);
    width: 280px;
    height: 100%;
    padding: 50px 0;
    transition: all .3s ease;
    overflow: auto;

    &.show {
      right: 0;
    }

    .close-mobile {
      display: flex;
      position: absolute;
      right: 10px;
      top: 15px;
      background-color: white;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      align-items: center;
      justify-content: center;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
      border: 1px solid white;

      .if {
        font-size: 15px;
        line-height: 1;
        color: $color-orange;
      }
    }

    .parent-navigation {
      display: block;

      > li {
        margin-left: 0;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      a,
      .if {
        color: white;
      }

      a {
        padding: 15px 20px;
        width: calc(100% - 43px);
      }

      .if {
        margin: 0;
        padding: 16px;
        transition: all .3s ease;

        &.active {
          transform: rotate(180deg);

          & + .sub-navigation {
            max-height: 10000px;
            transition: max-height 1s ease-in-out;
          }
        }
      }
    }

    .sub-navigation {
      overflow: hidden;
      max-height: 0;
      display: block;
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      background: none;
      border-radius: 0;
      box-shadow: none;
      padding: 0;
      transition: max-height .5s cubic-bezier(0, 1, 0, 1);

      &:before {
        content: '';
        box-shadow: inset 0 5px 5px 0 rgba(0, 0, 0, 0.15);
        background-color: #d55c22;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 1;
        transition: all .5s ease;
      }

      li {
        margin-bottom: 10px;

        &:first-child {
          margin-top: 15px;
        }

        &:last-child {
          margin-bottom: 15px;
        }
      }

      a {
        display: block;
        width: 100%;
        padding: 5px 0 5px 40px;
      }
    }
  }
}

.header-controls {
  display: flex;
  align-items: center;

  > div {
    margin-left: 60px;

    &:first-child {
      margin-left: 0;
    }
  }

  @media (max-width: 991px) {
    display: none;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: white;
    justify-content: space-between;
    padding: 10px 30px;
    box-shadow: 0 5px 10px 1px rgba(58, 68, 86, 0.25), inset 0 1px 4px 1px rgba(58, 68, 86, 0.25);
    animation: showSubNav .5s ease 1 forwards;
    z-index: 180;

    &.show {
      display: flex;
    }
  }
}

.header-chat,
.header-notify {
  cursor: pointer;

  .if {
    display: block;
    font-size: 20px;
    line-height: 1;
  }
}

.header-notify {
  position: relative;

  .notify {
    width: 3px;
    height: 3px;
    background-color: $color-green-light;
    position: absolute;
    left: 50%;
    margin-left: -1.5px;
    bottom: -5px;
  }
}

.header-user {
  position: relative;

  .small-avatar {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    cursor: pointer;

    img {
      width: 100%;
    }
  }

  &:hover .header-user-info {
    display: block;
  }
}

.header-user-info {
  position: absolute;
  width: 242px;
  border-radius: 10px;
  box-shadow: 0 1px 12px 2px rgba(58, 68, 86, 0.25);
  background-color: #ffffff;
  right: -20px;
  top: calc(100% + 7px);
  display: none;
  animation: showSubNav .5s ease 1 forwards;

  .load-local-spinner {
    position: static;
    width: 30px;
    margin: 20px auto;
    transform: none;

    img {
      width: 100%;
    }
  }

  &:before {
    content: $if-popover-arrow;
    @extend %if;
    position: absolute;
    right: 25px;
    top: -7px;
    font-size: 7px;
    color: $color-orange;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: -7px;
    width: 100%;
    height: 7px;
  }

  .head {
    padding: 11px 13px;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    background-image: linear-gradient(24deg, #fc4a4a, #ea6728);

    .info {
      width: calc(100% - 68px);
    }
  }

  .large-avatar {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 18px;
    flex: 0 0 50px;
    position: relative;

    img {
      width: 100%;
    }
  }

  .user-name,
  .user-email {
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .user-name {
    font-size: 14px;
    font-weight: 500;
  }

  .user-email {
    font-size: 12px;
  }
}

.header-user-navigation {
  padding: 25px 20px;

  li {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  button {
    font-size: 14px;
    font-weight: 300;
    color: $color-gray-dark;
    display: flex;
    align-items: center;
    transition: all .3s ease;

    &:hover {
      text-decoration: none;
      color: $color-orange;
    }
  }

  .if {
    font-size: 15px;
    display: block;
    line-height: 1;
    margin-right: 15px;
    width: 15px;
    text-align: center;
    flex: 0 0 15px;
  }
}

.header-controls-mobile {
  display: none;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 991px) {
    display: flex;

    button {
      background: none;
      border: none;
      padding: 0;
      margin: 0 20px;
      line-height: 1;

      @media (max-width: 400px) {
        margin: 0 10px;
      }

      .if {
        font-size: 20px;
        line-height: 1;
        display: block;
      }
    }
  }
}

.header-overlay-mobile {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 190;
  animation: fadeIn .5s ease 1 forwards;

  &.show {
    display: block;
  }
}
