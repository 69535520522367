.owner-pets-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .owner-pet-item {
    width: calc(33.33333% - 10px);
    border: 1px solid #ced4da;
    padding: 30px;
    background-color: #e9ecef;
    margin-bottom: 20px;
  }

  .owner-pet-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 30px;
    border: 2px solid #ced4da;

    img {
      width: 100%;
    }
  }

  .owner-pet-name {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 20px;
  }
}
