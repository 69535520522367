.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  @media (max-width: 574px) {
    flex-wrap: wrap;

	.count-on-page {
	  order: 1;
	  width: 100%;
	  margin-bottom: 10px;
	}
  }
}

.pagination-navigation {
  display: flex;
  justify-content: space-between;
  margin-left: -15px;

  button {
	color: $color-gray-dark;
	font-family: $gothamPro;
	font-size: 16px;
	line-height: 1;
	width: 30px;
	height: 30px;
	box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.16);
	border-radius: 5px;
	background-color: $color-white;
	margin-left: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all .3s ease;

	&:hover,
	&.active {
	  background-color: $color-orange;
	  color: $color-white;
	}
  }

  @media (max-width: 574px) {
	order: 2;
  }
}
