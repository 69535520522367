.dashboard-card {
  border-radius: 10px;
  box-shadow: 1px 1px 7px 1px rgba(58, 68, 86, 0.3);
  background-color: $color-white;
  min-height: 250px;
  position: relative;
  height: 100%;

  .head {
	height: 54px;
	border-radius: 10px 10px 0 0;
	background-image: linear-gradient(38deg, #fc4a4a, #ea6728);
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 2px 22px;
	width: 100%;
	position: relative;
	z-index: 10;

	.text {
	  font-size: 16px;
	  color: $color-white;
	  line-height: 18px;
	}

	.info {
	  width: 20px;
	  flex: 0 0 20px;
	  margin-left: 20px;
	  line-height: 1;

	  .if {
		font-size: 20px;
		color: white;
		cursor: pointer;
	  }

	  .info-tooltip {
		position: absolute;
		right: -5px;
		top: 45px;
		border-radius: 10px;
		box-shadow: 0 0 11px 1px rgba(58, 68, 86, 0.3);
		background-color: $color-white;
		padding: 20px;
		display: none;
		animation: showSubNav .5s ease 1 forwards;
		width: calc(100% - 30px);
		font-size: 14px;
		line-height: 18px;

		&:before {
		  content: $if-popover-arrow;
		  @extend %if;
		  position: absolute;
		  right: 23px;
		  top: -7px;
		  font-size: 7px;
		  color: white;
		}

		&:after {
		  content: '';
		  position: absolute;
		  left: 0;
		  top: -7px;
		  width: 100%;
		  height: 7px;
		}
	  }

	  &:hover .info-tooltip {
		display: block;
	  }
	}
  }

  .full-center {
	position: relative;
	height: calc(100% - 54px);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	z-index: 5;
	padding: 20px;
  }

  .content {
	padding: 25px 20px;

	> *:last-child {
	  margin-bottom: 0;
	}
  }
}

.dashboard-large-info {
  text-align: center;

  .icon {
	line-height: 1;
	margin-bottom: 15px;

	.if {
	  font-size: 60px;
	  line-height: 65px;
	}
  }

  .value {
	font-size: 18px;
	margin-bottom: 10px;
	line-height: 1;
  }

  .label {
	font-size: 14px;
	line-height: 1;
	font-weight: 300;
  }
}

.dashboard-line-info {
  .line {
	display: flex;
	align-items: center;
	margin-bottom: 25px;

	&:last-child {
	  margin-bottom: 0;
	}
  }

  .value {
	width: 50px;
	flex: 0 0 50px;
	overflow: hidden;
	margin-right: 10px;
	font-size: 18px;
	line-height: 1;
  }

  .icon {
	width: 32px;
	flex: 0 0 32px;
	text-align: center;
	margin-right: 15px;
	line-height: 1;

	.if {
	  font-size: 32px;
	  line-height: 1;
	}
  }

  .label {
	width: 100%;
	font-size: 14px;
	line-height: 1;
	font-weight: 300;
  }

  &.large {
	.value {
	  width: 60px;
	  flex: 0 0 60px;
	}

	.icon {
	  width: 50px;
	  flex: 0 0 50px;

	  .if {
		font-size: 50px;
		line-height: 1;
	  }
	}
  }
}

.dashboard-chart-views {
  .line {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 25px;

	.label {
	  font-size: 14px;
	  line-height: 1;
	  font-weight: 300;
	  margin-top: -4px;
	}

	.value {
	  font-size: 18px;
	  line-height: 1;
	  margin-left: 8px;
	}
  }

  .chart img {
	width: 100%;
  }
}

.dashboard-controls-top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .buttons-list {
	width: 45%;

	@media (max-width: 767px) {
	  width: 100%;
	}
  }
}

.dashboard-specialist-profiles {
  height: 200px;
  overflow: auto;
  padding-right: 10px;
  margin-bottom: 25px;

  &::-webkit-scrollbar {
	width: 6px;
  }

  &::-webkit-scrollbar-track {
	border-radius: 4px;
	background-color: #e5e5e5;
  }

  &::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-image: linear-gradient(to top, #ff4444, #ea6728 45%, #ea6728);
  }

  &::-webkit-scrollbar-thumb:hover {
	background-image: linear-gradient(to top, #ff4444, #ea6728 45%, #ea6728);
  }

  .line {
	padding: 10px 20px;
	border-bottom: 1px solid #d8d8d8;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 574px) {
	  justify-content: center;
	  flex-wrap: wrap;
	}
  }

  .profile {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 574px) {
	  justify-content: center;
	  margin-bottom: 15px;
	}

	.image {
	  width: 40px;
	  height: 40px;
	  border-radius: 50%;
	  overflow: hidden;
	  margin-right: 15px;
	  flex: 0 0 40px;

	  @media (max-width: 574px) {
		margin-right: 0;
		margin-bottom: 15px;
	  }

	  img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	  }
	}

	.name {
	  font-size: 14px;
	  line-height: 16px;

	  @media (max-width: 574px) {
		text-align: center;
		width: 100%;
	  }
	}
  }

  .specialization {
	font-size: 14px;
	color: #9d9d9d;
	line-height: 1;

	@media (max-width: 574px) {
	  width: 100%;
	  text-align: center;
	  margin-bottom: 15px;
	}
  }

  .value {
	font-size: 18px;
	color: #000000;
	line-height: 1;

	@media (max-width: 574px) {
	  width: 100%;
	  text-align: center;
	}
  }
}

.dashboard-total-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .label {
	font-size: 14px;
	line-height: 1;
	font-weight: 300;
  }

  .value {
	font-size: 18px;
	line-height: 1;
	color: #000000;
	margin-left: 10px;
  }
}

.dashboard-see-profiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .item {
	width: 45%;

	@media (max-width: 574px) {
	  width: 100%;
	  margin-bottom: 30px;

	  &:last-child {
		margin-bottom: 0;
	  }
	}
  }

  .total-count {
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: 10px;

	.if {
	  font-size: 45px;
	  line-height: 1;
	}

	.value {
	  font-size: 18px;
	  margin-left: 70px;
	  line-height: 1;
	  position: relative;
	  color: black;

	  &:before {
		content: '';
		display: block;
		width: 40px;
		height: 1px;
		background-color: #d8d8d8;
		position: absolute;
		top: 50%;
		left: -55px;
	  }
	}
  }

  .line {
	padding: 10px 0;
	border-bottom: 1px solid #d8d8d8;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.label {
	  font-size: 12px;
	  line-height: 1;
	}

	.value {
	  font-size: 16px;
	  color: black;
	  line-height: 1;
	}
  }
}

.dashboard-large-chart {

  .chart-controls {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px;

	.left,
	.right {
	  width: 45%;

	  @media (max-width: 767px) {
		width: 100%;
	  }
	}

	.right {
	  display: flex;
	  align-items: center;

	  .item {
		display: flex;
		align-items: center;
		margin-left: 40px;

		&:first-child {
		  margin-left: 0;
		}
	  }

	  .if {
		font-size: 30px;
		line-height: 1;

		&.if-woman-detail {
		  color: #e74cd3;
		}

		&.if-man-detail {
		  color: #0096ff;
		}
	  }

	  .value {
		font-size: 18px;
		margin-left: 70px;
		line-height: 1;
		position: relative;
		color: black;

		&:before {
		  content: '';
		  display: block;
		  width: 40px;
		  height: 1px;
		  background-color: #d8d8d8;
		  position: absolute;
		  top: 50%;
		  left: -55px;
		}

		@media (max-width: 1200px) and (min-width: 992px) {
		  margin-left: 40px;

		  &:before {
			width: 20px;
			left: -30px;
		  }
		}

		@media (max-width: 767px) {
		  margin-left: 40px;

		  &:before {
			width: 20px;
			left: -30px;
		  }
		}
	  }
	}
  }

  .chart img {
	width: 100%;
  }
}

.dashboard-rating {
  .total {
	text-align: center;
	margin-bottom: 50px;
  }

  .label {
	font-size: 14px;
	line-height: 16px;
  }

  .value {
	font-size: 18px;
	line-height: 1;
	color: black;
  }

  .rating-cols-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 50px;

	.rating-col {
	  width: 30%;
	  text-align: center;

	  @media (max-width: 767px) {
		width: 100%;
		margin-bottom: 30px;

		&:last-child {
		  margin-bottom: 0;
		}
	  }

	  .stars {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 15px;

		.if {
		  margin: 0 3px;
		  line-height: 1;
		  font-size: 15px;
		}
	  }
	}
  }

  .rating-name {
	margin-bottom: 25px;
	font-size: 16px;
	line-height: 1;
  }

  .rating-line {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 25px;

	.text {
	  font-size: 16px;
	  line-height: 1;
	  width: 45px;
	  flex: 0 0 45px;
	}

	.value {
	  width: 60px;
	  flex: 0 0 60px;
	}

	.line {
	  margin: 0 10px;
	  height: 16px;
	  border-radius: 8px;
	  background-color: #e6e6e6;
	  width: 100%;
	  position: relative;

	  .fill {
		position: absolute;
		width: 0;
		height: 100%;
		left: 0;
		top: 0;
		border-radius: 8px;
		background-image: linear-gradient(to right, #fc4a4a, #ea6728);
		transition: all .3s ease;
	  }
	}
  }
}
