.price-direction-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .if {
    font-size: 20px;
    line-height: 1;
    color: $color-orange;
    margin-left: 15px;
    transition: all .3s ease;

    &:hover {
      color: $color-coral;
    }
  }
}
