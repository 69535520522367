.specialist-in-clinic-list > [class*="col-"] {
  margin-bottom: 30px;
}

.card-specialist-in-clinic {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  background-color: $color-white;
  padding: 30px 30px 90px;
  text-align: center;
  transition: all .7s ease;
  height: 100%;
  position: relative;

  &:hover {
    box-shadow: 0 0 30px 1px rgba(58, 68, 86, 0.3);
  }

  .image {
    border: solid 1px #ea6728;
    background-color: #d8d8d8;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 30px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .name {
    color: $color-gray-dark;
    font-family: $gothamPro;
    font-size: 20px;
    line-height: 1;
    margin-bottom: 10px;
  }

  .post {
    color: $color-gray-dark;
    font-size: 16px;
    line-height: 1;
  }

  .btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
  }

  .status {
    color: $color-orange;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    margin-top: 25px;

    &-dismiss {
      color: $color-coral;
    }
  }

  .more-popup {
    display: inline-block;
  }
}
