%if {
  font-family: "svgfont";
  font-style: normal;
  font-weight: normal;
  text-rendering: auto;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

%round-small-image {
  border: solid 1px #ea6728;
  background-color: #d8d8d8;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 25px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
