label {
  display: block;
}

.form-group {
  .mat-form-field {
    width: 100%;
  }
}

.mat-form-field {
  width: 100%;
}

textarea.mat-input-element.mat-autosize {
  padding: 0;
}

.checkbox-list {
  >div {
    margin-bottom: 20px;
  }

  .mat-checkbox-layout .mat-checkbox-label {
    white-space: normal;
    line-height: 1;
  }
}

.mat-form-field {
  .show-password {
    position: absolute;
    top: 8px;
    right: 5px;

    .if {
      font-size: 20px;
      line-height: 1;
      color: $color-gray-dark;
      cursor: pointer;
      position: absolute;
      z-index: 1;
      opacity: 0;
      right: 0;
      transition: all .3s ease;

      &.if-eye {
        opacity: 0;
      }

      &.if-no-eye {
        opacity: 1;
      }
    }
  }

  input[type="password"] ~ .show-password {
    .if-eye {
      opacity: 1;
    }

    .if-no-eye {
      opacity: 0;
    }
  }
}

mat-label i.danger {
  color: $color-coral;
  font-style: normal;
}
