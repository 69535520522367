.account-avatar {
  .image {
    width: 170px;
    height: 170px;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 1px rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 20px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &.account-clinic .image {
    width: 200px;
    height: 200px;

    @media (max-width: 767px) {
      width: 170px;
      height: 170px;
    }
  }
}
