.card-clinic {
  border-radius: 10px;
  box-shadow: 1px 1px 7px 1px rgba(58, 68, 86, 0.3);
  background-color: #ffffff;
  position: relative;
  height: 100%;
  cursor: pointer;
  transition: all .5s ease;

  .image {
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    height: 400px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .caption {
    padding: 20px 25px;
  }

  .clinic-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
  }

  &:hover {
    box-shadow: 0 0 30px 1px rgba(58, 68, 86, 0.3);
  }
}
