.schedule-info {
  .item {
	display: flex;
	margin-bottom: 25px;

	.left,
	.right {
	  font-size: 16px;
	  line-height: 1;
	  font-weight: 300;
	}

	.left {
	  width: 165px;
	  font-weight: 500;
	}
  }
}
