.clinic-small-info {
  display: flex;
  align-items: center;

  @media (max-width: 1649px) {
    margin: 15px 0;
  }

  .mat-dialog-container & {
    text-align: left;
  }

  .image {
    @extend %round-small-image;
    flex: 0 0 50px;

    @media (max-width: 1649px) {
      margin-right: 10px;
    }
  }

  .content {
    .name {
      font-size: 16px;
      line-height: 1;
      color: #404040;
      margin-bottom: 7px;

      @media (max-width: 1649px) {
        font-size: 14px;
      }

      &:last-child {
        margin-bottom: 0;
      }

    }

    .post {
      font-size: 16px;
      line-height: 1;
      color: #000000;
      opacity: .5;

      @media (max-width: 1649px) {
        font-size: 14px;
      }
    }
  }
}
