.load-content-spinner {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 50;
  padding-top: 80px;
  padding-left: 300px;

  @media (max-width: 991px) {
    padding-top: 60px;
    padding-left: 0;
  }

  img {
    transform-origin: 50% 50%;
    animation: loadingSpinner 1s linear infinite;
    margin-top: 80px;
    margin-left: 300px;

    @media (max-width: 991px) {
      margin-top: 60px;
      margin-left: 0;
    }
  }
}

.load-local-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 50;

  &.spinner-static {
    position: static;
    transform: none;
    margin: 30px;
    text-align: center;
  }

  img {
    transform-origin: 50% 50%;
    animation: loadingSpinner 1s linear infinite;
  }
}
