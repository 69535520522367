.more-popup {
  position: relative;

  &:hover {
    z-index: 100;
  }

  .dots-link:hover + .hidden-more-popup {
    display: block;
  }

  .hidden-more-popup {
    position: absolute;
    left: 50%;
    bottom: calc(100% + 10px);
    margin-left: -100px;
    width: 200px;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    background-color: $color-white;
    padding: 20px;
    display: none;
    animation: fadeIn .3s ease 1 forwards;

    &:before {
      content: $if-popover-arrow;
      display: block;
      @extend %if;
      position: absolute;
      left: 50%;
      bottom: -8px;
      margin-left: -15px;
      font-size: 8px;
      color: $color-white;
      transform: rotate(180deg);
      text-shadow: 0 -2px 2px rgba(0, 0, 0, .16);
    }

    div {
      color: $color-gray-dark;
      font-family: $gothamPro;
      font-size: 16px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
