@font-face {
	font-family: "svgfont";
	src: url('/assets/fonts/svgfont.eot');
	src: url('/assets/fonts/svgfont.eot?#iefix') format('eot'), url('/assets/fonts/svgfont.woff') format('woff'), url('/assets/fonts/svgfont.woff2') format('woff2'), url('/assets/fonts/svgfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal; }

$if-add-round: "\EA01";
$if-address: "\EA02";
$if-alarm: "\EA03";
$if-angle-down: "\EA04";
$if-angle-left: "\EA05";
$if-angle-right: "\EA06";
$if-angry-dog: "\EA07";
$if-burger-02: "\EA08";
$if-burger: "\EA09";
$if-calendar: "\EA0A";
$if-call-in: "\EA0B";
$if-call-out: "\EA0C";
$if-change-password: "\EA0D";
$if-check: "\EA0E";
$if-clinic: "\EA0F";
$if-clock: "\EA10";
$if-close: "\EA11";
$if-dashboard: "\EA12";
$if-edit-02: "\EA13";
$if-edit: "\EA14";
$if-empty-box: "\EA15";
$if-exit: "\EA16";
$if-eye: "\EA17";
$if-facebook: "\EA18";
$if-garbage: "\EA19";
$if-google: "\EA1A";
$if-happy-cat: "\EA1B";
$if-hearth: "\EA1C";
$if-info: "\EA1D";
$if-instagram: "\EA1E";
$if-internet: "\EA1F";
$if-light-clinic: "\EA20";
$if-light-envelope: "\EA21";
$if-light-locked: "\EA22";
$if-light-phone: "\EA23";
$if-light-point: "\EA24";
$if-light-user: "\EA25";
$if-list: "\EA26";
$if-long-arrow-right: "\EA27";
$if-man-detail: "\EA28";
$if-more-button-dots: "\EA29";
$if-more: "\EA2A";
$if-no-eye: "\EA2B";
$if-notepad: "\EA2C";
$if-paws: "\EA2D";
$if-photo-camera: "\EA2E";
$if-point: "\EA2F";
$if-popover-arrow: "\EA30";
$if-price: "\EA31";
$if-return: "\EA32";
$if-security: "\EA33";
$if-settings: "\EA34";
$if-star-filled: "\EA35";
$if-star: "\EA36";
$if-support: "\EA37";
$if-user-man: "\EA38";
$if-user-woman: "\EA39";
$if-vkontakte: "\EA3A";
$if-woman-detail: "\EA3B";


%if {
	font-family: "svgfont";
	font-style: normal;
	font-weight: normal;
	text-rendering: auto;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale; }

.if, [class^="i-icon-"], [class*=" i-icon-"] {
	@extend %if;
	display: inline-block; }

.if-add-round:before {
	content: $if-add-round; }
.if-address:before {
	content: $if-address; }
.if-alarm:before {
	content: $if-alarm; }
.if-angle-down:before {
	content: $if-angle-down; }
.if-angle-left:before {
	content: $if-angle-left; }
.if-angle-right:before {
	content: $if-angle-right; }
.if-angry-dog:before {
	content: $if-angry-dog; }
.if-burger-02:before {
	content: $if-burger-02; }
.if-burger:before {
	content: $if-burger; }
.if-calendar:before {
	content: $if-calendar; }
.if-call-in:before {
	content: $if-call-in; }
.if-call-out:before {
	content: $if-call-out; }
.if-change-password:before {
	content: $if-change-password; }
.if-check:before {
	content: $if-check; }
.if-clinic:before {
	content: $if-clinic; }
.if-clock:before {
	content: $if-clock; }
.if-close:before {
	content: $if-close; }
.if-dashboard:before {
	content: $if-dashboard; }
.if-edit-02:before {
	content: $if-edit-02; }
.if-edit:before {
	content: $if-edit; }
.if-empty-box:before {
	content: $if-empty-box; }
.if-exit:before {
	content: $if-exit; }
.if-eye:before {
	content: $if-eye; }
.if-facebook:before {
	content: $if-facebook; }
.if-garbage:before {
	content: $if-garbage; }
.if-google:before {
	content: $if-google; }
.if-happy-cat:before {
	content: $if-happy-cat; }
.if-hearth:before {
	content: $if-hearth; }
.if-info:before {
	content: $if-info; }
.if-instagram:before {
	content: $if-instagram; }
.if-internet:before {
	content: $if-internet; }
.if-light-clinic:before {
	content: $if-light-clinic; }
.if-light-envelope:before {
	content: $if-light-envelope; }
.if-light-locked:before {
	content: $if-light-locked; }
.if-light-phone:before {
	content: $if-light-phone; }
.if-light-point:before {
	content: $if-light-point; }
.if-light-user:before {
	content: $if-light-user; }
.if-list:before {
	content: $if-list; }
.if-long-arrow-right:before {
	content: $if-long-arrow-right; }
.if-man-detail:before {
	content: $if-man-detail; }
.if-more-button-dots:before {
	content: $if-more-button-dots; }
.if-more:before {
	content: $if-more; }
.if-no-eye:before {
	content: $if-no-eye; }
.if-notepad:before {
	content: $if-notepad; }
.if-paws:before {
	content: $if-paws; }
.if-photo-camera:before {
	content: $if-photo-camera; }
.if-point:before {
	content: $if-point; }
.if-popover-arrow:before {
	content: $if-popover-arrow; }
.if-price:before {
	content: $if-price; }
.if-return:before {
	content: $if-return; }
.if-security:before {
	content: $if-security; }
.if-settings:before {
	content: $if-settings; }
.if-star-filled:before {
	content: $if-star-filled; }
.if-star:before {
	content: $if-star; }
.if-support:before {
	content: $if-support; }
.if-user-man:before {
	content: $if-user-man; }
.if-user-woman:before {
	content: $if-user-woman; }
.if-vkontakte:before {
	content: $if-vkontakte; }
.if-woman-detail:before {
	content: $if-woman-detail; }
