.custom-tabs-nav {
  position: relative;
  z-index: 10;

  ul {
	display: flex;
  }

  button {
	padding: 12px 25px;
	line-height: 1;
	font-size: 16px;
	color: $color-gray-dark;
	transition: all .3s ease;

	&.active {
	  background-color: $color-white;
	}
  }

  & + .router-wrapper .main-container,
  & + .main-container {
	border-radius: 0 10px 10px 10px;
  }
}
