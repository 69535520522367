@keyframes pulse {
  0% {
	box-shadow: 0 0 0 0 rgba(255,255,255, 0.35);
  }
  70% {
	box-shadow: 0 0 0 15px rgba(255,255,255, 0);
  }
  100% {
	box-shadow: 0 0 0 0 rgba(255,255,255, 0);
  }
}

@keyframes showSubNav {
  from {
	opacity: 0;
	transform: translateY(-10px);
  }
  to {
	opacity: 1;
	transform: translateY(0);
  }
}
@keyframes fadeIn {
  from {
	opacity: 0;
  }
  to {
	opacity: 1;
  }
}

@keyframes loadingSpinner {
  from {
	transform: rotate(0);
  }
  to {
	transform: rotate(350deg);
  }
}
