.rating-stars {
  display: flex;
  align-items: center;
  margin-left: -7px;

  .if {
	line-height: 1;
	font-size: 16px;
	margin-left: 7px;
  }
}
